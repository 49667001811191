import { Platform, ActivityIndicator as RNActivityIndicator } from 'react-native'
import { useTheme } from 'styled-components'
import PropTypes from 'prop-types'

const calcActivityIndicatorSize = (theme, scale = 1) => scale * theme.fontSizes.xxlarge

const resolvePlatformSize = (theme, scale) =>
  Platform.OS === 'ios' ? (scale > 1 ? 'large' : 'small') : calcActivityIndicatorSize(theme, scale)

const resolvePlatformColor = (theme, color) =>
  theme.colors[Platform.OS === 'ios' ? 'gray' : color] || color

export const ActivityIndicator = ({ color = 'primary', scale = 1, ...props }) => {
  const theme = useTheme()
  return (
    <RNActivityIndicator
      {...props}
      size={resolvePlatformSize(theme, scale)}
      color={resolvePlatformColor(theme, color)}
    />
  )
}

ActivityIndicator.propTypes = {
  /** A scaling factor to resize the spinner from the default icon size */
  scale: PropTypes.number,
  color: PropTypes.string,
}
