"use strict";

var _excluded = ["baseUrl", "apiCdnDomain", "headers", "onError", "sessionId", "tokenStore", "nftTokenStore", "initialRegionAndLocale", "getRegionAndLocale"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import camelCase from 'lodash/camelCase';
import { regionAndLocaleStore } from './regionAndLocaleStore'; // import { concatPagination } from '@apollo/client/utilities'

var get = function get(key) {
  return function (object) {
    return object && object.__typename + ':' + object[key];
  };
};

var getSlug = get('slug');

var getNFTActivity = function getNFTActivity(item) {
  var _item$hash;

  return item.__typename + item.type + ((_item$hash = item.hash) !== null && _item$hash !== void 0 ? _item$hash : item.id);
};

var dataIdFromObjectRegistry = {
  Film: getSlug,
  Actor: getSlug,
  Article: getSlug,
  MailingList: getSlug,
  Locale: get('code'),
  RecommendationSender: get('recommendationId'),
  NFTItemMetadata: get('image'),
  NFTItemHistory: getNFTActivity,
  Web3UserActivity: getNFTActivity,
  Web3UserSale: get('hash'),
  Web3Profile: get('username')
};

var dataIdFromObject = function dataIdFromObject(data) {
  return dataIdFromObjectRegistry[data.__typename] ? dataIdFromObjectRegistry[data.__typename](data) : undefined;
};

var typePolicies = {
  AuthStatus: {
    keyArgs: false // Always merge to the same cached instance regardless of args

  },
  AppConfig: {
    keyArgs: false
  } // todo Pagination with field policies return undefined for the existing array, due to link REST?
  // SearchResults: {
  //   fields: {
  //     items: concatPagination(['query', 'type']),
  //   },
  // },

};
export var flixApiConfiguration = function flixApiConfiguration() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var _ref$baseUrl = _ref.baseUrl,
      baseUrl = _ref$baseUrl === void 0 ? '' : _ref$baseUrl,
      apiCdnDomain = _ref.apiCdnDomain,
      headers = _ref.headers,
      onError = _ref.onError,
      _ref$sessionId = _ref.sessionId,
      sessionId = _ref$sessionId === void 0 ? '' : _ref$sessionId,
      tokenStore = _ref.tokenStore,
      nftTokenStore = _ref.nftTokenStore,
      _ref$initialRegionAnd = _ref.initialRegionAndLocale,
      initialRegionAndLocale = _ref$initialRegionAnd === void 0 ? {
    region: 'US',
    locale: 'en-US'
  } : _ref$initialRegionAnd,
      getRegionAndLocale = _ref.getRegionAndLocale,
      rest = _objectWithoutProperties(_ref, _excluded);

  var flixEndpointV1 = "".concat(baseUrl, "/v1/");
  var flixEndpointV2 = "".concat(baseUrl, "/v2/");
  var regionAndLocale = regionAndLocaleStore(initialRegionAndLocale);
  return _objectSpread(_objectSpread({}, rest), {}, {
    regionAndLocale: regionAndLocale,
    errorLinkConfig: {
      errorHandler: onError
    },
    authLinkConfig: {
      tokenStore: tokenStore,
      nftTokenStore: nftTokenStore,
      getLocaleHeaders: regionAndLocale.getLocaleHeaders
    },
    restLinkConfig: {
      headers: _objectSpread(_objectSpread({}, headers), {}, {
        'X-Flix-Session': sessionId
      }),
      uri: flixEndpointV1,
      endpoints: {
        v1: flixEndpointV1,
        v2: flixEndpointV2,
        v2CDN: apiCdnDomain ? "".concat(apiCdnDomain, "/v2/") : flixEndpointV2
      },
      credentials: 'include',
      // Flix API uses cookies for guest session tracking
      fieldNameNormalizer: camelCase // For API v1 responses snake to camel case

    },
    cacheConfig: {
      dataIdFromObject: dataIdFromObject,
      typePolicies: typePolicies
    }
  });
};