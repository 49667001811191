"use strict";

export var LEAD = 'lead';
export var REGISTERED = 'registered';
export var SIGNED_IN = 'signedIn';
export var SIGNED_OUT = 'signedOut';
export var SIGN_IN_RESTORED = 'signInRestored';
export var CARD_ADDED = 'cardAdded';
export var CHECKOUT_INITIATED = 'initiatedCheckout';
export var SUBSCRIBED = 'subscribed';
export var TVOD_PURCHASED = 'tvodPurchased';
export var CONTENT_LIST_VIEWED = 'viewedContentList';
export var TITLE_DETAILS_VIEWED = 'viewedTitleDetails';
export var TITLE_REMINDER_SET = 'setTitleReminder';
export var TITLE_ADDED_TO_WATCHLIST = 'addToWatchlist';
export var TITLE_RATED = 'rated';
export var TRAILER_VIEWED = 'viewedTrailer';
export var FEATURE_VIEWED = 'viewedFeature';
export var RECOMMENDATION_SENT = 'recommendationSent';
export var NFT_ITEM_VIEWED = 'viewedNFTItem';
export var NFT_ADDED_TO_FAVORITES = 'addNFTToFavorites';