import { shouldPolyfill as shouldGetCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill'
import { shouldPolyfill as shouldLocale } from '@formatjs/intl-locale/should-polyfill'
import { shouldPolyfill as shouldPluralRules } from '@formatjs/intl-pluralrules/should-polyfill'
import { shouldPolyfill as shouldNumberFormat } from '@formatjs/intl-numberformat/should-polyfill'
import { shouldPolyfill as shouldDateTimeFormat } from '@formatjs/intl-datetimeformat/should-polyfill'
import { shouldPolyfill as shouldRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill'

/**
 * Polyfill older browsers and Smart TVs as necessary with code splitting.
 */
export async function polyfillIntlAsync() {
  if (shouldGetCanonicalLocales()) {
    await import('@formatjs/intl-getcanonicallocales/polyfill')
  }
  if (shouldLocale()) {
    await import('@formatjs/intl-locale/polyfill')
  }
  if (shouldPluralRules()) {
    await import('@formatjs/intl-pluralrules/polyfill')
  }
  if (shouldNumberFormat()) {
    await import('@formatjs/intl-numberformat/polyfill')
  }
  if (shouldDateTimeFormat()) {
    await import('@formatjs/intl-datetimeformat/polyfill')
  }
  if (shouldRelativeTimeFormat()) {
    await import('@formatjs/intl-relativetimeformat/polyfill')
  }
}
