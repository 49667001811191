import castArray from 'lodash/castArray'
import messages from './validationMessages'

// Accepts a tuple of [messageId, values]
// or a string messageId or pre-formatted error message
export default (intl) => (error) => {
  const errorArray = castArray(error)
  return messages[errorArray[0]]
    ? intl.formatMessage(messages[errorArray[0]], errorArray[1])
    : errorArray[0]
}
