import { Component } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'
import PropTypes from 'prop-types'
import translationFetcher, { localeMessagesOr } from './translationFetcher'

const createIntlProvider = (options) => {
  const { defaultLocale } = options

  return class IntlProvider extends Component {
    static propTypes = {
      messages: PropTypes.object,
      locale: PropTypes.string,
      fallback: PropTypes.node,
      onError: PropTypes.func,
      notifyError: PropTypes.func.isRequired,
    }

    constructor(props) {
      super(props)
      const { messages = {}, locale = defaultLocale } = props

      this.fetchTranslation = translationFetcher(options, messages)
      this.messagesCache = { ...messages } // Clone, messagesCache is mutated later
      if (locale !== defaultLocale && messages[locale]) {
        this.messagesCache[locale] = localeMessagesOr(messages[defaultLocale])(messages[locale])
      }

      this.state = {
        error: null,
        loading: !this.messagesCache[locale],
        messages: this.messagesCache[locale] || null,
      }

      this.notifyError =
        process.env.NODE_ENV === 'production'
          ? this.props.notifyError
          : (error) => console.log(error) // eslint-disable-line no-console
    }

    componentDidMount() {
      if (!this.state.messages) {
        this.fetchLocale(this.props.locale || defaultLocale)
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.locale !== prevProps.locale) {
        this.switchLocales(this.props.locale)
      }
    }

    switchLocales = (locale) => {
      if (!this.messagesCache[locale]) {
        return this.fetchLocale(locale)
      }
      this.setState({
        error: null,
        loading: false,
        messages: this.messagesCache[locale],
      })
    }

    fetchLocale = (locale) => {
      this.setState({ loading: true, error: null })
      this.fetchTranslation(locale)
        .then((messages) => {
          this.messagesCache[locale] = messages
          if (locale === this.props.locale) {
            this.setState({ messages, loading: false })
          }
        })
        .catch((error) => {
          if (locale === this.props.locale) {
            this.setState({ error, loading: false })
            this.props.onError?.(error)
          }
        })
    }

    render() {
      const { locale, fallback = null, children } = this.props
      const { loading, messages } = this.state
      return (
        <>
          {messages && (
            <ReactIntlProvider
              messages={messages}
              defaultLocale={defaultLocale}
              locale={locale || defaultLocale}
              onError={this.notifyError}
            >
              {children}
            </ReactIntlProvider>
          )}
          {loading && fallback}
        </>
      )
    }
  }
}

export default createIntlProvider
