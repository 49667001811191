import { forwardRef } from 'react'
import { useIntl } from 'react-intl'
import { Platform, View } from 'react-native'
import styled, { useTheme } from 'styled-components'
import { formatLabelPlaceholder } from '../../forms/formatLabelPlaceholder'
import { InputError } from '../InputError/InputError'
import { InputHint } from '../InputHint/InputHint'
import { InputLabel } from '../InputLabel/InputLabel'
import { errorFromMeta } from '../utils'
import { StyledInput } from './StyledInput'

const isAndroid = Platform.OS === 'android'

// Exported solely for reuse with the web card input
export { styledInputStyles } from './StyledInput'
export { StyledInput }

export const Container = styled(View)`
  margin-bottom: ${(props) => props.theme.spacing[props.mb || props.marginBottom || 'small']}px;
`

export const ErrorMessage = styled(InputError)`
  margin-bottom: ${(props) => props.theme.spacing.xsmall}px;
`

export const TextInput = forwardRef(
  (
    {
      input,
      meta,
      style,
      label,
      placeholder,
      hint,
      numberOfLines,
      marginBottom,
      mb = marginBottom,
      returnKeyType,
      ...props
    },
    ref,
  ) => {
    const theme = useTheme()
    const intl = useIntl()
    return (
      <Container style={style} mb={mb}>
        {Boolean(label) && <InputLabel>{formatLabelPlaceholder(intl, label)}</InputLabel>}
        <StyledInput
          {...props}
          {...input}
          ref={ref}
          error={errorFromMeta(meta)}
          placeholder={formatLabelPlaceholder(intl, placeholder)}
          placeholderTextColor={theme.colors.placeholder}
          selectionColor={theme.colors.grayLight} // Highlight and cursor color
          underlineColorAndroid="transparent"
          numberOfLines={numberOfLines}
          multiline={numberOfLines > 1}
          textAlignVertical={numberOfLines > 1 ? 'top' : undefined}
          // Other return key types crash the app, this must be a React Native bug
          returnKeyType={isAndroid ? 'go' : returnKeyType}
        />
        {Boolean(hint) && <InputHint>{hint}</InputHint>}
        <ErrorMessage name={input.name} />
      </Container>
    )
  },
)

export default TextInput
