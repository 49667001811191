import { PureComponent, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { utmTrackingData, utmTrackingKeys } from '../../../utils/utmTracking'

export const AppState = createContext({})

export const AppStateConsumer = AppState.Consumer

const setWindowScrollable = (enabled) => {
  const [html] = document.getElementsByTagName('html')
  const [body] = document.getElementsByTagName('body')
  html.style.overflow = enabled ? '' : 'hidden'
  body.style.overflow = enabled ? '' : 'hidden'
}

export class AppStateProvider extends PureComponent {
  componentDidMount() {
    const { history, location } = this.props
    const query = new URLSearchParams(location.search)

    const recommendationId = query.get('r') ?? query.get('recommendationId')
    if (recommendationId) {
      this.setState({ recommendationId })
    }

    const utmKeys = utmTrackingKeys(query)
    if (utmKeys.length) {
      this.setState({ utmTracking: utmTrackingData(utmKeys, query) })
    }

    ;[...query.keys()].forEach((key) => {
      if (key.startsWith('t_') || key.startsWith('utm_')) {
        query.delete(key)
      }
    })

    if (query.get('subscriptionSuccess')) {
      this.state.openSubsSuccessModal()
      query.delete('subscriptionSuccess')
    }

    const search = query.toString()
    if (search !== location.search) {
      history.replace({ search })
    }

    this.historyUnlisten = history.listen(this.onLocationChange)
  }

  componentWillUnmount() {
    this.historyUnlisten()
  }

  onLocationChange = (location) => {
    if (location.search) {
      const query = new URLSearchParams(location.search)
      if (query.get('subscriptionSuccess')) {
        this.state.openSubsSuccessModal()
        query.delete('subscriptionSuccess')
        this.props.history.replace({ search: query.toString() })
      }
    }
  }

  authSuccessPath = null

  state = {
    showFullHeaderFooter: true,
    setFullHeaderFooter: (showFullHeaderFooter) => this.setState({ showFullHeaderFooter }),

    searchPhrase: '',
    updateSearchPhrase: (searchPhrase) => this.setState({ searchPhrase }),

    utmTracking: null,
    recommendationId: '',

    // useWizardState stores the values here, so they are not lost when navigating away
    chosenPlan: null,
    setChosenPlan: (chosenPlan) => this.setState({ chosenPlan }),

    subsSuccessModal: false,
    openSubsSuccessModal: () => {
      this.setState({ subsSuccessModal: true })
      setWindowScrollable(false)
    },
    closeSubsSuccessModal: () => {
      this.setState({ subsSuccessModal: false })
      setWindowScrollable(true)
    },

    // for displaying full screen loading spinner
    loading: false,
    setLoading: (loading) => this.setState({ loading }),

    // auth success URL
    setAuthSuccessPath: (path) => (this.authSuccessPath = path),
    getAuthSuccessPath: () => this.authSuccessPath,
    clearAuthSuccessPath: () => (this.authSuccessPath = null),
  }

  render() {
    return <AppState.Provider value={this.state}>{this.props.children}</AppState.Provider>
  }
}

export const useAppState = () => useContext(AppState)

AppStateProvider.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}
