"use strict";

import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import { convertObjectKeys } from './utils';
/**
 * Initialized in much the same way as apollo-link-rest, the only additional
 * options are the tokenStore and onError (similar to the apollo-link-error
 * handler) which are both required.
 *
 * The returned function has a convenient signature for calls like:
 *
 *   flixApi('post', 'api/endpoint', {key: 'value'})
 */

export var flixApiFetch = function flixApiFetch(_ref) {
  var uri = _ref.uri,
      headers = _ref.headers,
      _ref$fieldNameNormali = _ref.fieldNameNormalizer,
      fieldNameNormalizer = _ref$fieldNameNormali === void 0 ? camelCase : _ref$fieldNameNormali,
      _ref$fieldNameDenorma = _ref.fieldNameDenormalizer,
      fieldNameDenormalizer = _ref$fieldNameDenorma === void 0 ? snakeCase : _ref$fieldNameDenorma,
      credentials = _ref.credentials,
      customFetch = _ref.customFetch,
      tokenStore = _ref.tokenStore,
      onError = _ref.onError;
  return function (method, path, data) {
    var options = {
      credentials: credentials,
      method: method.toUpperCase(),
      headers: new Headers(headers)
    };
    options.headers.set('Authorization', tokenStore.get() || '');

    if (data) {
      options.headers.set('Content-Type', 'application/json');
      options.body = JSON.stringify(fieldNameDenormalizer ? convertObjectKeys(data, fieldNameDenormalizer) : data);
    }

    return (customFetch || fetch)("".concat(uri).concat(path), options).then(function (response) {
      if (!response.ok) {
        // Use the same network error format as apollo-link-rest
        return response.text().then(function (result) {
          var error;

          try {
            var parsedResult = JSON.parse(result);
            error = new Error(parsedResult.message);
            error.result = parsedResult;
          } catch (e) {
            error = new Error(response.statusText);
          }

          error.statusCode = response.status;
          error.response = response;
          return Promise.reject(error);
        });
      }

      return response;
    }).then(function (response) {
      if (response.headers.has('Authorization')) {
        tokenStore.set(response.headers.get('Authorization'));
      }

      return (response.headers.get('Content-Type') || '').includes('application/json') ? response.json() : response.text();
    }).then(function (body) {
      return fieldNameNormalizer ? convertObjectKeys(body, fieldNameNormalizer) : body;
    }).catch(function (networkError) {
      onError && onError({
        networkError: networkError
      });
      throw networkError;
    });
  };
};