import { useState } from 'react'
import { View } from 'react-native'
import styled from 'styled-components'
import Popover from '../../../components/layout/Popover/Popover'
import { UserNavMenu } from './UserNavMenu/UserNavMenu'

const Caret = styled(View)`
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  text-vertical-align: middle;
  border: 4px dashed;
  border-top-color: white;
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
`

export const LoggedIn = ({ displayName, hasSubscription }) => {
  const [userMenuOpen, setUserMenuOpen] = useState(false)
  const toggleUserMenu = () => setUserMenuOpen((userMenuOpen) => !userMenuOpen)

  return (
    <View>
      <a onClick={toggleUserMenu} style={{ paddingRight: 0 }}>
        <span>{displayName}</span> <Caret />
      </a>

      {userMenuOpen && (
        <View>
          <Popover right style={{ minWidth: '192px', marginTop: 10 }}>
            <UserNavMenu hasSubscription={hasSubscription} onHide={toggleUserMenu} />
          </Popover>
        </View>
      )}
    </View>
  )
}
