import { createContext, useCallback, useContext, useState } from 'react'

const ListAdInsertionContext = createContext({
  skippedAds: {},
  skipAd: () => undefined,
})

const empty = {}

export const useSkipListAd = () => useContext(ListAdInsertionContext).skipAd

export const useSkippedListAds = (zone, placement) => {
  const context = useContext(ListAdInsertionContext)
  return (zone && placement && context.skippedAds[zone]?.[placement]) ?? empty
}

export const ListAdInsertionProvider = ({ children }) => {
  const [skippedAds, setSkippedAds] = useState({})

  const skipAd = useCallback((zone, placement, index, listIndex) => {
    setSkippedAds((skippedAds) => ({
      ...skippedAds,
      [zone]: {
        ...skippedAds[zone],
        [placement]: {
          ...skippedAds[zone]?.[placement],
          [listIndex ?? index]: !listIndex
            ? true
            : {
                ...skippedAds[zone]?.[placement]?.[listIndex],
                [index]: true,
              },
        },
      },
    }))
  }, [])

  const value = {
    skippedAds,
    skipAd,
  }

  return <ListAdInsertionContext.Provider value={value}>{children}</ListAdInsertionContext.Provider>
}
