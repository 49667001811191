import { Helmet } from 'react-helmet-async'
import { Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ListAdInsertionProvider } from '@flix/common/domain/ads/listAdInsertion/ListAdInsertionContext'
import { FullScreenLoader } from '@flix/common/elements/status/FullScreenLoader/FullScreenLoader'
import { MediaThemeProvider } from '@flix/common/style/MediaThemeProvider'
import { getTheme } from '@flix/common/style/theme'
import config from '../../../config'
import '../../../css/index.js'
import Routes from '../../../pages/Routes'
import { imageOgTags } from '../../../utils/htmlHead'
import { ButtonHandlersProvider } from '../../buttonPressHandlers/ButtonHandlersProvider'
import { loadable } from '../../loadable/loadable'
import TopNavBar from '../../navbar/TopNavBar'
import { SubsSuccessModal } from '../../subscription/SuccessModal/SubsSuccessModal'
import { AppStateConsumer, AppStateProvider } from '../AppState/AppState'
import { LocationChangeTracker } from '../EngagementTracker/LocationChangeTracker'
import Footer from '../Footer/Footer'
import { ScrollRestorationProvider } from '../ScrollRestoration/ScrollRestorationProvider'
import Snackbar from '../Snackbar/Snackbar'
import { SnackbarConsumer } from '../Snackbar/SnackbarContext'

const PlayerRoutes = loadable(() => import('../../../pages/player/PlayerRoutes'))
const FestivalRoutes = loadable(() => import('../../../pages/festival/FestivalRoutes'))
const MobileStaticRoutes = loadable(() => import('../../../pages/MobileStaticRoutes'))

const defaultImageTags = imageOgTags({ cloudinaryId: 'flix-logo-square', width: 600, height: 600 })

const App = ({ deviceWidth }) => (
  <ScrollRestorationProvider>
    <Helmet defaultTitle="Flix Premiere">
      <meta property="og:title" content="Flix Premiere" />
      <meta property="og:site_name" content="Flix Premiere" />
      <meta name="twitter:site" content="@FlixPremiere" />
      <meta name="twitter:card" content="summary" />
      {defaultImageTags}
    </Helmet>

    <MediaThemeProvider width={deviceWidth} theme={getTheme()}>
      <div className="relative">
        <Switch>
          {/* Page for mobile web views */}
          <Route path="/mobile" component={MobileStaticRoutes} />
          {/* The web app */}
          <Route
            render={(props) => (
              <AppStateProvider {...props}>
                <ListAdInsertionProvider>
                  <ButtonHandlersProvider>
                    <LocationChangeTracker />

                    <Switch>
                      {/* Full screen player */}
                      <Route
                        path={[
                          '/video',
                          '/film/:filmSlug/play',
                          '/group-watch/player',
                          '/festival/player',
                        ]}
                      >
                        <div className="page-body">
                          <PlayerRoutes />
                        </div>
                      </Route>
                      {/* Festival */}
                      <Route
                        path={['/festival/*']}
                        render={() => (
                          <div className="page-body">
                            <FestivalRoutes />
                            <Footer />
                          </div>
                        )}
                      />
                      {/* All other routes with header and footer */}
                      <Route
                        render={() => (
                          <>
                            <TopNavBar />
                            <div className="page-body">
                              <Routes />
                              <Footer />
                            </div>
                            <div id={config.reactPortalAnchorId} />
                          </>
                        )}
                      />
                    </Switch>

                    <SubsSuccessModal />
                    <AppStateConsumer>
                      {({ loading }) => <FullScreenLoader shown={loading} />}
                    </AppStateConsumer>
                  </ButtonHandlersProvider>
                </ListAdInsertionProvider>
              </AppStateProvider>
            )}
          />
        </Switch>

        <SnackbarConsumer>
          {({ snackbar, closeSnackbar }) => <Snackbar {...snackbar} onClose={closeSnackbar} />}
        </SnackbarConsumer>
      </div>
    </MediaThemeProvider>
  </ScrollRestorationProvider>
)

App.propTypes = {
  /**
   * The device width must be set on the server AND client when SSR is used. The rehydration
   * does not rerender the app if the server-side guess at the device width was wrong. By
   * setting the SSR width first, and then updating to the client width once the JS is ready
   * the app re-renders properly if the client width is different.
   */
  deviceWidth: PropTypes.number,
}

export default App
