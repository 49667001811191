import { View } from 'react-native'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useSignOutMutation } from '@mwxltd/flix-api-client'
import groupWatchMsgs from '@flix/common/domain/group-watch/messages'
import navMsgs from '@flix/common/domain/nav/messages'
import config from '../../../config'
import authMsgs from '../../auth/messages'
import MainNav from '../MainNav/MainNav'
import NavLink from '../NavLink/NavLink'
import { SideDrawer } from './SideDrawer'

const MobileNavView = styled(View)`
  margin: 9px 0;
  gap: ${(props) => props.theme.spacing.medium}px;
`

const Divider = styled(View)`
  border: 1px solid ${(props) => props.theme.colors.grayLighte};
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  margin: 8px 0;
  width: 100%;
`

const MobileNav = ({ open, hasSubscription, onRequestClose }) => {
  const history = useHistory()

  const [signOut] = useSignOutMutation({
    onCompleted: () => {
      history.replace('/')
      onRequestClose && onRequestClose()
    },
  })

  return (
    <SideDrawer open={open} onRequestClose={onRequestClose}>
      <MobileNavView>
        <MainNav hasSubscription={hasSubscription} />

        <Divider />

        <NavLink to="/profile/my-movies" labelMsg={navMsgs.myMovies} />
        <NavLink to="/group-watch/list" labelMsg={groupWatchMsgs.navMenu} />
        {!!config.nftMarketplace.enabled && (
          <NavLink to="/nft-marketplace/profile" labelMsg={navMsgs.nftProfile} />
        )}
        <NavLink to="/profile" labelMsg={navMsgs.profile} />

        <NavLink onClick={signOut} labelMsg={authMsgs.signOut} />
      </MobileNavView>
    </SideDrawer>
  )
}

MobileNav.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  hasSubscription: PropTypes.bool,
}

export default MobileNav
