import { View } from 'react-native'
import styled from 'styled-components'
import { media } from '@flix/common/style/media'
import partnersMsgs from '../../../pages/static/FilmPartnersPage/messages'
import MainNav from '../MainNav/MainNav'
import NavLink from '../NavLink/NavLink'
import { LoggedIn } from './LoggedIn'
import { LoggedOut } from './LoggedOut'

const MainNavContainer = styled(View)`
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;

  ${media.lg`
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    gap: ${(props) => props.theme.spacing.small}px;
    height: 60px;
  `}
`

const LoggedBox = styled(View)`
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacing.small}px;
`

const DesktopNav = ({
  authStatus: { displayName, hasSignedIn, supportedRegion, hasSubscription },
}) => (
  <>
    <MainNavContainer>
      {supportedRegion ? (
        <MainNav hasSubscription={hasSubscription} />
      ) : (
        <NavLink to="/film-partners" labelMsg={partnersMsgs.partnersNav} />
      )}
    </MainNavContainer>
    <LoggedBox>
      {!supportedRegion ? null : hasSignedIn ? (
        <LoggedIn displayName={displayName} hasSubscription={hasSubscription} />
      ) : (
        <LoggedOut />
      )}
    </LoggedBox>
  </>
)

export default DesktopNav
