import { Platform } from 'react-native'
import { svgComponent } from '../../SVG'
import alarmSVG from './alarm.svg'
import alarmOnSVG from './alarmOn.svg'
import arrowClockwiseSVG from './arrowClockwise.svg'
import arrowCounterClockwiseSVG from './arrowCounterClockwise.svg'
import arrowDownSVG from './arrowDown.svg'
import arrowLeftSVG from './arrowLeft.svg'
import arrowRightSVG from './arrowRight.svg'
import arrowUpSVG from './arrowUp.svg'
import backSVG from './back.svg'
import backIOSSVG from './backIOS.svg'
import cameraSVG from './camera.svg'
import catchUpSVG from './catchUp.svg'
import chartSVG from './chart.svg'
import chatSVG from './chat.svg'
import checkSVG from './check.svg'
import chevronDownSVG from './chevronDown.svg'
import chevronLeftSVG from './chevronLeft.svg'
import chevronRightSVG from './chevronRight.svg'
import chevronUpSVG from './chevronUp.svg'
import choosePlanSVG from './choosePlan.svg'
import closeSVG from './close.svg'
import closedCaptionSVG from './closedCaption.svg'
import downloadSVG from './download.svg'
import editSVG from './edit.svg'
import exclamationSVG from './exclamation.svg'
import eyeSVG from './eye.svg'
import facebookSVG from './facebook.svg'
import fastForwardSVG from './fastForward.svg'
import fastRewindSVG from './fastRewind.svg'
import fbMessengerSVG from './fbMessenger.svg'
import fileSVG from './file.svg'
import focusSVG from './focus.svg'
import forward10SVG from './forward10.svg'
import fullscreenSVG from './fullscreen.svg'
import fullscreenExitSVG from './fullscreenExit.svg'
import gearSVG from './gear.svg'
import giftSVG from './gift.svg'
import heartSVG from './heart.svg'
import heartOutlineSVG from './heartOutline.svg'
import helpSVG from './help.svg'
import homeSVG from './home.svg'
import hourglassEmptySVG from './hourglassEmpty.svg'
import hourglassFinishedSVG from './hourglassFinished.svg'
import imageSVG from './image.svg'
import infoSVG from './info.svg'
import instagramSVG from './instagram.svg'
import laurelSVG from './laurel.svg'
import linkSVG from './link.svg'
import liveSVG from './live.svg'
import longArrowLeftSVG from './longArrowLeft.svg'
import loopSVG from './loop.svg'
import mailSVG from './mail.svg'
import menuSVG from './menu.svg'
import moreVerticalSVG from './moreVertical.svg'
import moviesSVG from './movies.svg'
import multipleStopSVG from './multipleStop.svg'
import nftSVG from './nft.svg'
import offerSVG from './offer.svg'
import pauseSVG from './pause.svg'
import personSVG from './person.svg'
import phoneTickSVG from './phoneTick.svg'
import playSVG from './play.svg'
import playCircleSVG from './playCircle.svg'
import playClapboardSVG from './playClapboard.svg'
import plusSVG from './plus.svg'
import printSVG from './print.svg'
import replay10SVG from './replay10.svg'
import searchSVG from './search.svg'
import sendSVG from './send.svg'
import shareSVG from './share.svg'
import signInSVG from './signIn.svg'
import signOutSVG from './signOut.svg'
import skipNextSVG from './skipNext.svg'
import skipPreviousSVG from './skipPrevious.svg'
import speedSVG from './speed.svg'
import starSVG from './star.svg'
import ticketAddSVG from './ticketAdd.svg'
import ticketCheckedSVG from './ticketChecked.svg'
import ticketFilledSVG from './ticketFilled.svg'
import ticketStarSVG from './ticketStar.svg'
import timeUpdateSVG from './timeUpdate.svg'
import trashSVG from './trash.svg'
import tumblrSVG from './tumblr.svg'
import twitterSVG from './twitter.svg'
import usersSVG from './users.svg'
import videoCameraSVG from './videoCamera.svg'
import volumeDownSVG from './volumeDown.svg'
import volumeMutedSVG from './volumeMute.svg'
import volumeOffSVG from './volumeOff.svg'
import volumeUpSVG from './volumeUp.svg'
import walletSVG from './wallet.svg'
import whatsappSVG from './whatsapp.svg'
import wifiOffSVG from './wifiOff.svg'

export const back = Platform.select({
  ios: svgComponent({
    SVG: backIOSSVG,
    padding: 0.2,
    rtlFlip: true,
  }),
  default: svgComponent({
    SVG: backSVG,
    rtlFlip: true,
  }),
})

export const play = svgComponent({ SVG: playSVG, padding: 0.3 })
export const pause = svgComponent({ SVG: pauseSVG })
export const fastRewind = svgComponent({ SVG: fastRewindSVG })
export const fastForward = svgComponent({ SVG: fastForwardSVG })
export const skipPrevious = svgComponent({ SVG: skipPreviousSVG })
export const skipNext = svgComponent({ SVG: skipNextSVG })
export const replay10 = svgComponent({ SVG: replay10SVG })
export const forward10 = svgComponent({ SVG: forward10SVG })
export const closedCaption = svgComponent({ SVG: closedCaptionSVG, padding: 0.3 })
export const volumeUp = svgComponent({ SVG: volumeUpSVG })
export const volumeDown = svgComponent({ SVG: volumeDownSVG })
export const volumeMuted = svgComponent({ SVG: volumeMutedSVG })
export const volumeOff = svgComponent({ SVG: volumeOffSVG })

export const alarm = svgComponent({ SVG: alarmSVG })
export const alarmOn = svgComponent({ SVG: alarmOnSVG })
export const arrowCounterClockwise = svgComponent({ SVG: arrowCounterClockwiseSVG })
export const arrowClockwise = svgComponent({ SVG: arrowClockwiseSVG })
export const arrowDown = svgComponent({ SVG: arrowDownSVG, rtlFlip: true })
export const arrowLeft = svgComponent({ SVG: arrowLeftSVG, rtlFlip: true })
export const arrowRight = svgComponent({ SVG: arrowRightSVG, rtlFlip: true })
export const arrowUp = svgComponent({ SVG: arrowUpSVG, rtlFlip: true })
export const longArrowLeft = svgComponent({ SVG: longArrowLeftSVG, rtlFlip: true })
export const camera = svgComponent({ SVG: cameraSVG })
export const chart = svgComponent({ SVG: chartSVG })
export const chat = svgComponent({ SVG: chatSVG })
export const check = svgComponent({ SVG: checkSVG })
export const chevronLeft = svgComponent({
  SVG: chevronLeftSVG,
  rtlFlip: true,
})
export const chevronRight = svgComponent({
  SVG: chevronRightSVG,
  rtlFlip: true,
})
export const chevronDown = svgComponent({ SVG: chevronDownSVG })
export const chevronUp = svgComponent({ SVG: chevronUpSVG })
export const choosePlan = svgComponent({ SVG: choosePlanSVG, padding: 0.1 })
export const close = svgComponent({ SVG: closeSVG })
export const download = svgComponent({ SVG: downloadSVG })
export const edit = svgComponent({ SVG: editSVG, padding: 0.1 })
export const exclamation = svgComponent({ SVG: exclamationSVG })
export const eye = svgComponent({ SVG: eyeSVG })
export const file = svgComponent({ SVG: fileSVG, padding: 0.1 })
export const focus = svgComponent({ SVG: focusSVG })
export const fullscreen = svgComponent({ SVG: fullscreenSVG })
export const fullscreenExit = svgComponent({ SVG: fullscreenExitSVG })
export const gear = svgComponent({ SVG: gearSVG })
export const gift = svgComponent({ SVG: giftSVG, padding: 0.2 })
export const heart = svgComponent({ SVG: heartSVG })
export const heartOutline = svgComponent({ SVG: heartOutlineSVG })
export const help = svgComponent({ SVG: helpSVG })
export const home = svgComponent({ SVG: homeSVG })
export const hourglassEmpty = svgComponent({ SVG: hourglassEmptySVG })
export const hourglassFinished = svgComponent({ SVG: hourglassFinishedSVG })
export const image = svgComponent({ SVG: imageSVG })
export const info = svgComponent({ SVG: infoSVG })
export const laurel = svgComponent({ SVG: laurelSVG })
export const link = svgComponent({ SVG: linkSVG, padding: 0.25 })
export const loop = svgComponent({ SVG: loopSVG })
export const mail = svgComponent({ SVG: mailSVG, padding: 0.2 })
export const menu = svgComponent({ SVG: menuSVG })
export const moreVertical = svgComponent({ SVG: moreVerticalSVG })
export const movies = svgComponent({ SVG: moviesSVG })
export const multipleStop = svgComponent({ SVG: multipleStopSVG })
export const nft = svgComponent({ SVG: nftSVG })
export const offer = svgComponent({ SVG: offerSVG, padding: 0.1 })
export const person = svgComponent({ SVG: personSVG })
export const phoneTick = svgComponent({ SVG: phoneTickSVG, padding: 0.1 })
export const playCircle = svgComponent({ SVG: playCircleSVG })
export const playClapboard = svgComponent({ SVG: playClapboardSVG })
export const plus = svgComponent({ SVG: plusSVG })
export const print = svgComponent({ SVG: printSVG, padding: 0.1 })
export const search = svgComponent({ SVG: searchSVG })
export const send = svgComponent({ SVG: sendSVG })
export const share = svgComponent({ SVG: shareSVG, padding: 0.1 })
export const signIn = svgComponent({ SVG: signInSVG })
export const signOut = svgComponent({ SVG: signOutSVG, rtlFlip: true })
export const speed = svgComponent({ SVG: speedSVG })
export const star = svgComponent({ SVG: starSVG })
export const theaters = movies
export const ticketStar = svgComponent({ SVG: ticketStarSVG })
export const ticketAdd = svgComponent({ SVG: ticketAddSVG })
export const ticketChecked = svgComponent({ SVG: ticketCheckedSVG })
export const ticketFilled = svgComponent({ SVG: ticketFilledSVG })
export const times = close
export const timeUpdate = svgComponent({ SVG: timeUpdateSVG })
export const trash = svgComponent({ SVG: trashSVG })
export const users = svgComponent({ SVG: usersSVG })
export const videoCamera = svgComponent({ SVG: videoCameraSVG, padding: 0.2 })
export const wallet = svgComponent({ SVG: walletSVG, padding: 0.15 })
export const wifiOff = svgComponent({ SVG: wifiOffSVG })
export const live = svgComponent({ SVG: liveSVG })
export const catchUp = svgComponent({ SVG: catchUpSVG })

export const facebook = svgComponent({ SVG: facebookSVG, padding: 0.2 })
export const twitter = svgComponent({ SVG: twitterSVG, padding: 0.2 })
export const whatsapp = svgComponent({ SVG: whatsappSVG, padding: 0.2 })
export const fbMessenger = svgComponent({ SVG: fbMessengerSVG, padding: 0.2 })
export const instagram = svgComponent({ SVG: instagramSVG, padding: 0.2 })
export const tumblr = svgComponent({ SVG: tumblrSVG, padding: 0.2 })
