import { defineMessages } from 'react-intl'

export default defineMessages({
  privacyPolicy: {
    id: 'app.common.privacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  terms: {
    id: 'app.common.terms',
    defaultMessage: 'Terms of Use',
  },
})
