import { defineMessages } from 'react-intl'

export default defineMessages({
  contactNav: {
    id: 'app.staticPages.contactNav',
    defaultMessage: 'Contact Us',
  },

  tech: {
    id: 'app.staticPages.ContactPage.tech',
    defaultMessage: 'Technical support',
  },
  billing: {
    id: 'app.staticPages.ContactPage.billing',
    defaultMessage: 'Billing Inquiries',
  },
  feedback: {
    id: 'app.staticPages.ContactPage.feedback',
    defaultMessage: 'Customer Feedback',
  },
  other: {
    id: 'app.staticPages.ContactPage.other',
    defaultMessage: 'Other info / partnerships',
  },

  nyAddress: {
    id: 'app.staticPages.ContactPage.nyAddress',
    defaultMessage: 'USA New York Office',
  },
  usAddress: {
    id: 'app.staticPages.ContactPage.usAddress',
    defaultMessage: 'USA California Office',
  },
  ukAddress: {
    id: 'app.staticPages.ContactPage.ukAddress',
    defaultMessage: 'UK London Office',
  },
  pleaseEmail: {
    id: 'app.staticPages.ContactPage.pleaseEmail',
    defaultMessage: 'Please email',
  },
})
