import { defineMessages } from 'react-intl'

export default defineMessages({
  ltdCo: {
    id: 'app.footer.ltdCo',
    defaultMessage: 'Flix Premiere Limited is a company registered in England and Wales.',
  },
  coVatNo: {
    id: 'app.footer.coVatNo',
    defaultMessage: 'Company no. 9455299; VAT registration no. 236000458.',
  },
  copyright: {
    id: 'app.footer.copyright',
    defaultMessage: 'All rights reserved. © Copyright 2015-{year}.',
  },
  cookiePolicy: {
    id: 'app.footer.cookiePolicy',
    defaultMessage:
      'This website uses cookies, and also collects some information using Google Analytics. By continuing to use this website, you agree to our {privacyPolicy} and {termsOfUse}.',
  },
})
