/**
 * cookieStorage is useful Safari private browser mode, where localStorage
 * doesn't work but cookies do. This implementation is adopted from
 * https://developer.mozilla.org/en-US/docs/Web/API/Storage/LocalStorage
 */
export function createCookieStorage({ sameSite, domain } = { sameSite: 'Lax' }) {
  const { document } = typeof window !== 'undefined' ? window : global
  const _sameSite = sameSite ? `; SameSite=${sameSite}` : ''
  const _domain = domain ? `; domain=${domain}` : ''

  function _has(key) {
    return new RegExp(
      '(?:^|;\\s*)' + encodeURIComponent(key).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=',
    ).test(document.cookie)
  }

  return {
    get(key) {
      if (!key || !_has(key)) {
        return null
      }
      const regexpStr =
        '(?:^|.*;\\s*)' +
        encodeURIComponent(key).replace(/[\-\.\+\*]/g, '\\$&') +
        '\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*'
      return decodeURIComponent(document.cookie.replace(new RegExp(regexpStr), '$1'))
    },

    set(key, data) {
      if (!key) {
        return
      }
      document.cookie =
        encodeURIComponent(key) +
        '=' +
        encodeURIComponent(data) +
        '; expires=Tue, 19 Jan 2038 03:14:07 GMT; path=/' +
        _domain +
        _sameSite
    },

    delete(key) {
      if (!key || !_has(key)) {
        return
      }
      document.cookie =
        encodeURIComponent(key) +
        '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/' +
        _domain +
        _sameSite
    },
  }
}
