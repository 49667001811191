import { FormattedMessage } from 'react-intl'
import { View } from 'react-native'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useAuthStatus } from '@mwxltd/flix-api-client'
import { appStores, socialAccounts } from '@flix/common/config/3rdPartyLinks'
import commonMsgs from '@flix/common/domain/common/messages'
import contactMsgs from '@flix/common/domain/legal/ContactDetails/messages'
import navMsgs from '@flix/common/domain/nav/messages'
import { Image } from '@flix/common/elements/images/Image/Image'
import { MaxWidthView } from '@flix/common/elements/layout'
import config from '../../../config/index.js'
import partnersMsgs from '../../../pages/static/FilmPartnersPage/messages'
import nftCreatorsMsgs from '../../../pages/static/NFTCreatorAppPage/messages'
import { useAppState } from '../AppState/AppState'
import styles from './Footer.scss'
import messages from './messages'

const StoreLogo = styled(Image).attrs({
  width: 100,
  height: 30,
  crop: 'scale',
  fetchFormat: null,
})`
  margin: 0 ${(props) => props.theme.spacing.xsmall}px ${(props) => props.theme.spacing.medium}px;
`

const SocialIcons = styled(View)`
  flex-direction: row;
  justify-content: center;
`

const SocialIcon = styled(Image).attrs({
  height: 36,
  width: 36,
})`
  margin: 0 ${(props) => props.theme.spacing.xsmall}px;
`

const Footer = () => {
  const { hasSubscription, supportedRegion } = useAuthStatus()
  const { showFullHeaderFooter: fullNav } = useAppState()
  return (
    <footer className={styles.footer}>
      <MaxWidthView minHeight="none">
        {fullNav && (
          <nav className={styles.footerNav}>
            {supportedRegion && hasSubscription && (
              <Link to="/profile/subscription/update">
                <FormattedMessage {...navMsgs.pricing} />
              </Link>
            )}
            <Link to="/about">
              <FormattedMessage {...navMsgs.about} />
            </Link>
            <Link to="/film-partners">
              <FormattedMessage {...partnersMsgs.partnersNav} />
            </Link>
            {!!config.nftMarketplace.enabled && (
              <Link to="/nft-creators">
                <FormattedMessage {...nftCreatorsMsgs.nftCreatorsNav} />
              </Link>
            )}
            <Link to="/contact">
              <FormattedMessage {...contactMsgs.contactNav} />
            </Link>
            <Link to="/careers">Careers</Link>
            <Link to="/faq">
              <FormattedMessage {...navMsgs.help} />
            </Link>
          </nav>
        )}

        {fullNav && (
          <div className={styles.storeButtons}>
            <a
              href={appStores.android}
              title="Android Play Store"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StoreLogo
                publicId="google-play-store-icon_x45yto"
                alt="Flix Premiere on Google Play"
              />
            </a>
            <a
              href={appStores.ios}
              title="App Store - iOS"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StoreLogo publicId="App_store_icon_funmbe" alt="Flix Premiere on the App Store" />
            </a>
            <a
              href={appStores.ios}
              title="App Store - Apple TV"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StoreLogo
                publicId="available-on-appletv_uktnat"
                alt="Flix Premiere on the App Store"
              />
            </a>
            {/*{region !== 'US' ? (*/}
            {/*  <StoreLogo publicId="roku-badge_dae48c" alt="Flix Premiere on Roku" />*/}
            {/*) : (*/}
            {/*  <a href={appStores.roku} target="_blank" rel="noopener noreferrer">*/}
            {/*    <StoreLogo publicId="roku-badge_dae48c" alt="Flix Premiere on Roku" />*/}
            {/*  </a>*/}
            {/*)}*/}
            <a href={appStores.fireTV} title="Fire TV" target="_blank" rel="noopener noreferrer">
              <StoreLogo
                publicId="amazon-fire-tv-badge_g6keg0"
                alt="Flix Premiere on Amazon Fire TV"
              />
            </a>
            <StoreLogo publicId="sony_n3l4t4" alt="Flix Premiere on Sony TVs" />
            <StoreLogo publicId="samsung_wrbigx" alt="Flix Premiere on Samsung TVs" />
            <StoreLogo publicId="lg_bhmd0f" alt="Flix Premiere on LG TVs" />
            <StoreLogo publicId="vewd_kukc2u" alt="Flix Premiere on Vewd" />
            <a
              href={appStores.androidTV}
              title="Android TV"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StoreLogo publicId="androidtv-badge_n7egl9" alt="Flix Premiere on Android TV" />
            </a>
            <StoreLogo publicId="chromecast-badge_hl" alt="Flix Premiere on Google Chromecast" />
            <a href={appStores.microsoft} title="Xbox" target="_blank" rel="noopener noreferrer">
              <StoreLogo publicId="xbox-badge_yn4bez" alt="Flix Premiere on Xbox" />
            </a>
          </div>
        )}

        <p className={styles.copyright}>
          <span className={styles.fpTag}>FP</span>
          &nbsp;
          <FormattedMessage {...messages.ltdCo} />
          <br />
          <FormattedMessage {...messages.coVatNo} />
          <br />
          <br />
          <FormattedMessage {...messages.copyright} values={{ year: new Date().getFullYear() }} />
          <br />
          <br />
          <FormattedMessage
            {...messages.cookiePolicy}
            values={{
              privacyPolicy: (
                <Link to="/privacy" className="underline">
                  <FormattedMessage {...commonMsgs.privacyPolicy} />
                </Link>
              ),
              termsOfUse: (
                <Link to="/terms" className="underline">
                  <FormattedMessage {...commonMsgs.terms} />
                </Link>
              ),
            }}
          />
        </p>

        <SocialIcons>
          <a
            href={socialAccounts.facebook.page}
            title="Facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialIcon publicId="about-page-2.0/icon_facebook" />
          </a>
          <a
            href={socialAccounts.twitter.page}
            title="Twitter"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialIcon publicId="about-page-2.0/icon_twitter" />
          </a>
          <a
            href={socialAccounts.instagram.page}
            title="Instagram"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialIcon publicId="about-page-2.0/icon_instagram" />
          </a>
          <a
            href={socialAccounts.youtube.page}
            title="YouTube"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialIcon publicId="about-page-2.0/icon_youtube" />
          </a>
        </SocialIcons>
      </MaxWidthView>
    </footer>
  )
}

export default Footer
