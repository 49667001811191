import { defineMessages } from 'react-intl'

export default defineMessages({
  partnersNav: {
    id: 'app.staticPages.FilmPartners.partnersNav',
    defaultMessage: 'Film Partners',
  },
  heading: {
    id: 'app.staticPages.FilmPartners.heading',
    defaultMessage: 'Become a Film Partner',
  },

  description: {
    id: 'app.staticPages.FilmPartners.description',
    defaultMessage:
      'Join the next revolution in cinema. At Flix Premiere we\'ve created the world\'s first online theatre and P&A model for the film business. Pioneering our "theatre-on-demand" delivery approach, giving movie-goers the same release model for film premieres just as if the consumer was in the theater. To do this, we offer "Exclusive Only" movies in each territory and market them to the consumer using our revolutionary online full life cycle P&A model.',
  },
  lifecycle: {
    id: 'app.staticPages.FilmPartners.lifecycle',
    defaultMessage: 'P&A Lifecycle',
  },
  enlarge: {
    id: 'app.staticPages.FilmPartners.enlarge',
    defaultMessage: 'click to enlarge',
  },
  moreInfo: {
    id: 'app.staticPages.FilmPartners.moreInfo',
    defaultMessage: 'For more information you can call, email or fill in the form below:',
  },

  scrollRight: {
    id: 'app.staticPages.FilmPartners.scrollRight',
    defaultMessage: 'Scroll right for more or view ',
  },
  fullScreen: {
    id: 'app.staticPages.FilmPartners.fullScreen',
    defaultMessage: 'full screen',
  },

  usaOffice: {
    id: 'app.staticPages.FilmPartners.usaOffice',
    defaultMessage: 'USA Office',
  },
  ukOffice: {
    id: 'app.staticPages.FilmPartners.ukOffice',
    defaultMessage: 'UK Office',
  },
})
