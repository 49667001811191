import { FormattedMessage } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useButtonHandlers } from '@flix/common/elements/controls'
import { Button } from '@flix/common/elements/controls/Button/Button'
import { Row } from '@flix/common/elements/layout'
import { Text } from '@flix/common/elements/typography'
import authMsgs from '../../auth/messages'

const SignIn = styled(Text)`
  font-family: ${(props) => props.theme.fontFamilies.headline};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${(props) => props.theme.fontSizes.medium}px;
  white-space: nowrap;
  text-align: right;
`

export const LoggedOut = () => {
  const history = useHistory()
  const { registerCTAText } = useButtonHandlers()
  return (
    <Row alignItems="baseline" gap="small">
      <Link to="/sign-in">
        <SignIn>
          <FormattedMessage {...authMsgs.signIn} />
        </SignIn>
      </Link>
      <Button
        onPress={() => history.push('/sign-up')}
        title={registerCTAText}
        variant="primary"
        mb="none"
      />
    </Row>
  )
}
