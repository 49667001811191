import { useEffect, useState } from 'react'
import { Dimensions, Platform } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { breakPoints } from '../../style/breakPoints'
import { formatDimensions } from './formatDimensions'

// On the server a width is guessed based on the User-Agent; it is expanded
// to the information available on a client.
const formatSSRWidth = (width) => {
  const height =
    width < breakPoints.md
      ? width * 1.78 // Mobile sizes, assume 16:9 portrait orientation
      : width * 0.56 // Desktop & iPad Pro sizes, assume 16:9 landscape orientation
  return {
    window: { width, height },
    screen: { width, height },
  }
}

// This initial value derived on the client may be incorrect later, e.g. the device
// might have rotated, but will then be corrected when the Effect mounts and kept up to
// date thereafter. A default value avoids an initial empty look-up and subsequent re-render.
const initialDimensions = {
  window: Dimensions.get('window'),
  screen: Dimensions.get('screen'),
}

export function useDimensionsListener(ssrWidth) {
  const insets = useSafeAreaInsets()

  const [dimensions, updateDimensions] = useState(
    formatDimensions(ssrWidth ? formatSSRWidth(ssrWidth) : initialDimensions, insets),
  )

  useEffect(() => {
    function handleDimensionChange(dimensions) {
      updateDimensions(formatDimensions(dimensions, insets))
    }

    // The current dimensions and the initial value from outside the hook may
    // be different, so the state is updated with the latest values on mount.
    handleDimensionChange({
      window: Dimensions.get('window'),
      screen: Dimensions.get('screen'),
    })

    const subscription = Dimensions.addEventListener('change', handleDimensionChange)

    // Maintained for RNW@0.15, switch to sub.remove() once upgraded
    return () =>
      Platform.OS === 'web'
        ? Dimensions.removeEventListener('change', handleDimensionChange)
        : subscription.remove()
  }, [updateDimensions, insets])

  return dimensions
}
