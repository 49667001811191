import { StyleSheet } from 'react-native'
import { PaddedSVG } from './PaddedSVG'

export const svgComponentNative =
  ({ SVG, padding = 0, rtlFlip = false }) =>
  ({ size, color = 'white', isRTL = false, style, ...props }) =>
    (
      <PaddedSVG
        {...props}
        size={size}
        padding={padding}
        style={StyleSheet.compose(
          style,
          isRTL && rtlFlip ? { transform: [{ scaleX: -1 }] } : false,
        )}
      >
        <SVG fill={color} width={size * (1 - padding)} height={size * (1 - padding)} />
      </PaddedSVG>
    )
