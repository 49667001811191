"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
var USER_FILM_SLUGS = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "UserFilmSlugsQuery"
    },
    "variableDefinitions": [],
    "directives": [],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "userFilmSlugs"
        },
        "arguments": [],
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "rest"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "type"
            },
            "value": {
              "kind": "StringValue",
              "value": "FilmSlugGroup",
              "block": false
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "endpoint"
            },
            "value": {
              "kind": "StringValue",
              "value": "v2",
              "block": false
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "path"
            },
            "value": {
              "kind": "StringValue",
              "value": "member/film-slugs",
              "block": false
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "slug"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "arguments": [],
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "type"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "name"
                },
                "value": {
                  "kind": "StringValue",
                  "value": "FilmSlugList",
                  "block": false
                }
              }]
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "slug"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "arguments": [],
                "directives": []
              }]
            }
          }]
        }
      }]
    }
  }],
  "loc": {
    "start": 0,
    "end": 220,
    "source": {
      "body": "\n  query UserFilmSlugsQuery {\n    userFilmSlugs @rest(type: \"FilmSlugGroup\", endpoint: \"v2\", path: \"member/film-slugs\") {\n      slug\n      items @type(name: \"FilmSlugList\") {\n        slug\n        items\n      }\n    }\n  }\n",
      "name": "GraphQL request",
      "locationOffset": {
        "line": 1,
        "column": 1
      }
    }
  }
};

var useUserFilmSlugsQuery = function useUserFilmSlugsQuery() {
  return useQuery(USER_FILM_SLUGS);
};

var useUserFilmSlugs = function useUserFilmSlugs(listSlug) {
  var _useUserFilmSlugsQuer = useUserFilmSlugsQuery(),
      data = _useUserFilmSlugsQuer.data;

  return useMemo(function () {
    var _data$userFilmSlugs$i, _data$userFilmSlugs, _data$userFilmSlugs$i2;

    var list = (_data$userFilmSlugs$i = data === null || data === void 0 ? void 0 : (_data$userFilmSlugs = data.userFilmSlugs) === null || _data$userFilmSlugs === void 0 ? void 0 : (_data$userFilmSlugs$i2 = _data$userFilmSlugs.items.find(function (list) {
      return list.slug === listSlug;
    })) === null || _data$userFilmSlugs$i2 === void 0 ? void 0 : _data$userFilmSlugs$i2.items) !== null && _data$userFilmSlugs$i !== void 0 ? _data$userFilmSlugs$i : [];
    return list.reduce(function (result, slug) {
      result[slug] = true;
      return result;
    }, {});
  }, [listSlug, data]);
};
/**
 * Get the list of film slugs the user has set reminders for.
 * While loading an empty list will be returned.
 */


export var useUserReminderSlugs = function useUserReminderSlugs() {
  return useUserFilmSlugs('reminders');
};
/**
 * Get the list of film slugs on the user's watch list.
 * While loading an empty list will be returned.
 */

export var useUserWatchListSlugs = function useUserWatchListSlugs() {
  return useUserFilmSlugs('watch-list');
};
/**
 * Get the list of film slugs on the user's rented film.
 * While loading an empty list will be returned.
 */

export var useUserRentedFilmSlugs = function useUserRentedFilmSlugs() {
  return useUserFilmSlugs('rented');
};

var readUserFilmSlugsSet = function readUserFilmSlugsSet(client, listSlug) {
  var _result$userFilmSlugs;

  var result = client.readQuery({
    query: USER_FILM_SLUGS
  });
  var items = result === null || result === void 0 ? void 0 : (_result$userFilmSlugs = result.userFilmSlugs.items.find(function (l) {
    return l.slug === listSlug;
  })) === null || _result$userFilmSlugs === void 0 ? void 0 : _result$userFilmSlugs.items;
  return items && new Set(items);
};

var writeUserFilmSlugsSet = function writeUserFilmSlugsSet(client, listSlug, listSet) {
  var _client$readQuery;

  var userFilmSlugs = (_client$readQuery = client.readQuery({
    query: USER_FILM_SLUGS
  })) === null || _client$readQuery === void 0 ? void 0 : _client$readQuery.userFilmSlugs;
  client.writeQuery({
    query: USER_FILM_SLUGS,
    data: {
      userFilmSlugs: _objectSpread(_objectSpread({}, userFilmSlugs), {}, {
        items: userFilmSlugs.items.map(function (list) {
          return list.slug !== listSlug ? list : _objectSpread(_objectSpread({}, list), {}, {
            items: _toConsumableArray(listSet)
          });
        })
      })
    }
  });
};

export var addToUserFilmSlugsQuery = function addToUserFilmSlugsQuery(client, listSlug, filmSlug) {
  var filmSlugsSet = readUserFilmSlugsSet(client, listSlug);

  if (filmSlugsSet) {
    filmSlugsSet.add(filmSlug);
    writeUserFilmSlugsSet(client, listSlug, filmSlugsSet);
  }
};
export var removeFromUserFilmSlugsQuery = function removeFromUserFilmSlugsQuery(client, listSlug, filmSlug) {
  var filmSlugsSet = readUserFilmSlugsSet(client, listSlug);

  if (filmSlugsSet) {
    filmSlugsSet.delete(filmSlug);
    writeUserFilmSlugsSet(client, listSlug, filmSlugsSet);
  }
};