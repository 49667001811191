import { defineMessages } from 'react-intl'

export default defineMessages({
  // Before party created
  pageDescription: {
    id: 'app.groupWatch.pageDescription',
    defaultMessage:
      'Get the cinema experience at home with your favourite people. Stream any title virtually and chat with your friends.',
  },
  welcome: {
    id: 'app.groupWatch.welcome',
    defaultMessage: 'Welcome to the foyer!',
  },
  inviteGuests: {
    id: 'app.groupWatch.invite',
    defaultMessage:
      'Invite guests to a screening party and start your stream or schedule a screening party for later.',
  },
  create: {
    id: 'app.groupWatch.create',
    defaultMessage: 'Create Screening Party',
  },
  schedule: {
    id: 'app.groupWatch.schedule',
    defaultMessage: 'Schedule Screening Party',
  },
  reschedule: {
    id: 'app.groupWatch.reschedule',
    defaultMessage: 'Reschedule Screening Party',
  },

  // After party created
  playMovie: {
    id: 'app.groupWatch.playMovie',
    defaultMessage: 'Play Movie',
  },
  cancel: {
    id: 'app.groupWatch.cancel',
    defaultMessage: 'Cancel Screening Party',
  },
  familyAndFriends: {
    id: 'app.groupWatch.familyAndFriends',
    defaultMessage:
      'Screening Parties are for close family and friends. Flix Premiere subscription required.',
  },
  openChat: {
    id: 'app.groupWatch.openChat',
    defaultMessage: 'Open Live Stream',
  },
  viewAllParties: {
    id: 'app.groupWatch.viewAllParties',
    defaultMessage: 'View All Parties',
  },
  inviteButton: {
    id: 'app.groupWatch.inviteButton',
    defaultMessage: 'Get Invite Link',
  },
  inviteButtonMobile: {
    id: 'app.groupWatch.inviteButtonMobile',
    defaultMessage: 'Share',
  },
  linkCopied: {
    id: 'app.groupWatch.linkCopied',
    defaultMessage: 'Invite link copied to clipboard!',
  },
  scheduleTextLink: {
    id: 'app.groupWatch.scheduleTextLink',
    defaultMessage: 'Schedule for a future date',
  },
  onMobile: {
    id: 'app.groupWatch.onMobile',
    defaultMessage: 'On mobile? Watch on a bigger screen',
  },
  scheduledFor: {
    id: 'app.groupWatch.scheduledFor',
    defaultMessage: 'Screening scheduled for',
  },
  chooseShowtime: {
    id: 'app.groupWatch.chooseShowtime',
    defaultMessage: 'Choose the Showtime for Your Screening Party',
  },
  streamShowtime: {
    id: 'app.groupWatch.streamShowtime',
    defaultMessage: 'Stream Showtime',
  },
  startStreaming: {
    id: 'app.groupWatch.startStreaming',
    defaultMessage: 'Start Streaming',
  },
  premiereFrom: {
    id: 'app.groupWatch.premiereFrom',
    defaultMessage: 'Film will premiere',
  },
  ended: {
    id: 'app.groupWatch.ended',
    defaultMessage: 'This screening party is no longer live.',
  },

  // Group Watch List Screen

  scheduledShort: {
    id: 'app.groupWatch.scheduledShort',
    defaultMessage: 'Scheduled',
  },
  live: {
    id: 'app.groupWatch.live',
    defaultMessage: 'Live!',
  },
  host: {
    id: 'app.groupWatch.host',
    defaultMessage: 'Host',
  },
  groupWatchListEmptyMain: {
    id: 'app.groupWatch.groupWatchListEmptyMain',
    defaultMessage: 'You have no Screening Parties right now',
  },
  groupWatchListEmptySecondary: {
    id: 'app.groupWatch.groupWatchListEmptySecondary',
    defaultMessage: 'Head over to Now Showing and find a movie to watch with others!',
  },
  groupWatchListEmptyButton: {
    id: 'app.groupWatch.groupWatchListEmptyButton',
    defaultMessage: 'Browse Now Showing',
  },

  // Group Watch Guest Screen
  invited: {
    id: 'app.groupWatch.invited',
    defaultMessage: 'You are invited to a',
  },
  flixPremiere: {
    id: 'app.groupWatch.flixPremiere',
    defaultMessage: 'Flix Premiere',
  },
  groupWatchEvent: {
    id: 'app.groupWatch.groupWatchEvent',
    defaultMessage: 'Screening Party',
  },
  logIn: {
    id: 'app.groupWatch.logIn',
    defaultMessage: 'Sign In',
  },
  join: {
    id: 'app.groupWatch.join',
    defaultMessage: 'Accept Invite',
  },
  leave: {
    id: 'app.groupWatch.leave',
    defaultMessage: 'Leave Screening Party',
  },
  go: {
    id: 'app.groupWatch.go',
    defaultMessage: 'Go to Screening Party',
  },
  cancelled: {
    id: 'app.groupWatch.cancelled',
    defaultMessage: 'This Screening Party has been cancelled',
  },
  finished: {
    id: 'app.groupWatch.finished',
    defaultMessage: 'This Screening Party has finished',
  },
  noScheduledDate: {
    id: 'app.groupWatch.noScheduledDate',
    defaultMessage: 'Not Scheduled',
  },
  waitingForHost: {
    id: 'app.groupWatch.waitingForHost',
    defaultMessage: 'Waiting for host',
  },

  // Group watch player/chat
  chatPlaceholder: {
    id: 'app.groupWatch.chatPlaceholder',
    defaultMessage: 'Type a message...',
  },
  chatHeader: {
    id: 'app.groupWatch.chatHeader',
    defaultMessage: 'Chat',
  },
  groupDisconnected: {
    id: 'app.groupWatch.groupDisconnected',
    defaultMessage: 'You lost connection to the group. Please try rejoining.',
  },
  groupNotLive: {
    id: 'app.groupWatch.groupNotLive',
    defaultMessage: 'This screening party is no longer live',
  },
  watchingTVHeader: {
    id: 'app.groupWatch.watchingTVHeader',
    defaultMessage: 'You are watching on the big screen',
  },
  watchingTVSubheader: {
    id: 'app.groupWatch.watchingTVSubheader',
    defaultMessage: 'Close the TV video player to resume watching here',
  },
  closeChat: {
    id: 'app.groupWatch.closeChat',
    defaultMessage: 'Exit chat',
  },

  // Other
  navMenu: {
    id: 'app.groupWatch.navMenu',
    defaultMessage: 'Screening Parties',
  },
})
