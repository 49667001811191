// based on bootstrap and http://www.websitedimensions.com/
// these are the maximum values for the device type so <= phone is a phone,
// > phone && <= tablet is a tablet, and > tablet is a desktop
export const breakPoints = {
  xs: 361,
  sm: 768,
  md: 992,
  lg: 1200,
  xlg: 1400,
  xxl: 1800,
}

// based on bootstrap grid/container widths. While container widths are set to
// the sizes below plus the gutter width, they also receive total padding of
// one gutter width and thus the actual content widths are as given below.
// While within one of the breakPoints above the given width will apply.
// On xs devices the full screen width is used.
export const containerWidths = {
  sm: 720,
  md: 940,
  lg: 1140,
  xlg: 1140,
}
