import { polyfillIntlAsync } from '../polyfill/polyfill'

function translationFetcher(
  {
    locales: localeConfigs,
    defaultLocale,
    fetchOptions = { mode: 'cors' },
    importPolyfills = polyfillIntlAsync,
  },
  preFetchedMessages = {},
) {
  // Whenever a new locale is requested the promise of its translation messages is cached.
  // On future requests this promise will be returned which will resolve immediately.
  const promiseCache = {}

  // Fetches the translation JSON strings and the necessary react-intl polyfills.
  const fetchNewLocale = (locale, { translationsUrl, reactIntlPolyfills }) => (defaultMessages) => {
    const fetchedMessages = Promise.resolve(
      preFetchedMessages[locale] || fetchTranslationMessages(translationsUrl, fetchOptions),
    )
    return Promise.all([
      fetchedMessages.then(localeMessagesOr(defaultMessages)),
      reactIntlPolyfills(),
    ])
      .then(([messages]) => messages)
      .catch((error) => {
        delete promiseCache[locale]
        throw error
      })
  }

  promiseCache[defaultLocale] = importPolyfills().then(() =>
    fetchNewLocale(defaultLocale, localeConfigs[defaultLocale])(),
  )

  // This function is used to pull a promise that will resolve to the translation
  // messages of the requested locale, if supported, or the default messages.
  return (locale) => {
    if (!promiseCache[locale]) {
      if (!localeConfigs[locale]) {
        // Unsupported locale
        return promiseCache[defaultLocale]
      }
      promiseCache[locale] = promiseCache[defaultLocale].then(
        fetchNewLocale(locale, localeConfigs[locale]),
      )
    }
    return promiseCache[locale]
  }
}

export default translationFetcher

// If a translation is missing a message it is replaced with the default
// message. This is useful as a fail-safe and for leaving similar messages
// as empty strings in related languages, such as en-US and en-GB.
export const fetchTranslationMessages = (url, fetchOptions) =>
  fetch(url, fetchOptions).then((response) => {
    if (response.ok) {
      return response.json()
    }
    const error = new Error(response.statusText)
    error.statusCode = response.status
    error.response = response
    throw error
  })

export const localeMessagesOr = (defaultMessages = {}) => (messages) =>
  Object.keys(messages).reduce((msgs, id) => {
    msgs[id] = messages[id] || defaultMessages[id]
    return msgs
  }, {})
