import { Platform } from 'react-native'

export const baseFontSize = 16

export const stripeElementFonts = [
  {
    // Supply the source of fontFamilies.base
    cssSrc: 'https://fonts.googleapis.com/css?family=Roboto:300,400',
  },
]

export const webFontLoaderFonts = {
  google: {
    families: ['Montserrat:800', 'Roboto+Condensed:300,300i,400,400i', 'Roboto:300,300i,400,400i'],
  },
}

export const getGoogleFontsUrl = () =>
  `https://fonts.googleapis.com/css?family=${webFontLoaderFonts.google.families.join('%7C')}`

export const getTheme = (scalingFactor = 1, fontScalingFactor = 1) => {
  const fontFamilies = Platform.select({
    // Android does not support light font-weight, the font must be used directly
    android: {
      base: 'Roboto-Light',
      baseBold: 'Roboto-Regular',
      headline: 'RobotoCondensed-Light',
      headlineBold: 'RobotoCondensed-Regular',
      marketingHeadlineBold: 'Montserrat-ExtraBold',
    },
    // iOS supports font-weights, and the name must match the font's internal name
    ios: {
      base: 'Roboto-Regular',
      baseBold: 'Roboto-Regular',
      headline: 'RobotoCondensed-Regular',
      headlineBold: 'RobotoCondensed-Regular',
      marketingHeadlineBold: 'Montserrat-ExtraBold',
    },
    // Web font names should match those in the Google Fonts stylesheet
    web: {
      base: 'Roboto',
      baseBold: 'Roboto',
      headline: 'Roboto Condensed',
      headlineBold: 'Roboto Condensed',
      marketingHeadlineBold: 'Montserrat',
    },
    // Windows - custom fonts are not supported, this is a placeholder
    windows: {
      base: 'Roboto',
      baseBold: 'Roboto',
      headline: 'Roboto Condensed',
      headlineBold: 'Roboto Condensed',
      marketingHeadlineBold: 'Montserrat',
    },
  })

  const fontScaleFactor = fontScalingFactor * scalingFactor
  const fontSize = baseFontSize * fontScaleFactor

  return {
    scalingFactor,
    fontScalingFactor: fontScaleFactor,

    fontFamilies,

    fontWeights: {
      normal: 300,
      bold: 400,
    },

    fontSizes: {
      xxxsmall: (fontSize * 10) / baseFontSize, // 10
      xxsmall: (fontSize * 12) / baseFontSize, // 12
      xsmall: (fontSize * 13) / baseFontSize, // 13
      small: (fontSize * 14) / baseFontSize, // 14
      medium: fontSize, // 16
      large: (fontSize * 18) / baseFontSize, // 18
      larger: (fontSize * 20) / baseFontSize, // 20
      xlarge: (fontSize * 26) / baseFontSize, // 26
      xxlarge: (fontSize * 32) / baseFontSize, // 32
      xxxlarge: (fontSize * 48) / baseFontSize, // 48
    },

    spacing: {
      none: 0,
      xxs: 2 * scalingFactor,
      xxsmall: 2 * scalingFactor,
      xs: 4 * scalingFactor,
      xsmall: 4 * scalingFactor,
      sm: 8 * scalingFactor,
      small: 8 * scalingFactor,
      smedium: 12 * scalingFactor,
      md: 16 * scalingFactor,
      medium: 16 * scalingFactor,
      larger: 24 * scalingFactor,
      lg: 32 * scalingFactor,
      large: 32 * scalingFactor,
      xlg: 48 * scalingFactor,
      xlarge: 48 * scalingFactor,
      xxlarge: 64 * scalingFactor,
      xxxlarge: 96 * scalingFactor,
    },

    snackbarMaxWidth: 500 * scalingFactor,

    // Note: Android colors should match in android/app/src/main/res/values/styles.xml
    colors: {
      transparent: 'transparent',
      black: '#000000',
      gray: '#555',
      grayDark: '#333',
      grayDarker: '#222',
      grayLight: '#777',
      grayLighte: '#999',
      whiteFaded: '#aaa',
      grayLighter: '#ddd',
      white: '#fff',

      background: '#121212',
      backgroundTransparent: 'rgba(18, 18, 18, 0.7)', // #121212
      foreground: '#fff',
      darkInput: '#1e1e1e',
      level1: '#222',
      body: '#fff',
      menu: '#000',
      text: '#fff',
      textInverted: '#000',
      wellDark: '#2a1f1f',
      well: '#392e2e',
      wellLight: '#594444',

      placeholder: '#888888', // Input placeholder text
      primary: '#bb222b',
      primaryDark: '#911a1f',
      primaryDarker: '#5b1114',
      primaryText: '#ff000f',
      accent: '#c5a511',
      ratedStars: '#ffb400',
      info: '#1444c6',
      infoLight: '#97ace8',
      green: '#1b5e20',
      greenLight: '#74B96A',
      warning: '#be762a',
      error: '#be3036',
      errorDark: '#A2212A',

      gradientPrimaryDark: '#512c2d',
      gradientPrimaryLight: '#bb222b',
      gradientAccentDark: '#534628',
      gradientAccentLight: '#c5a511',
      gradientGreenDark: '#31382b',
      gradientGreenLight: '#1b5e20',

      // opacity
      accentOpacity: (opacity) => `rgba(195, 165, 17, ${opacity})`,
      grayOpacity: (opacity) => `rgba(192, 192, 192, ${opacity})`,
    },

    borderRadius: 4,

    zIndex: {
      fullScreenLoader: 100,
      modals: 500,
    },

    components: {
      button: {
        borderRadius: 4,
      },

      navbar: Platform.select({
        android: {
          desktopHeight: 56,
          mobileHeight: 56,
        },
        ios: {
          desktopHeight: 44,
          mobileHeight: 44,
        },
        web: {
          desktopHeight: 75,
          mobileHeight: 52,
        },
      }),
    },
  }
}

export const getInvertedTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    background: '#fff',
    foreground: '#000',
    text: '#000',
  },
})
