"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * Initialized in much the same way as apollo-link-rest, the only additional
 * options are the tokenStore and onError (similar to the apollo-link-error
 * handler) which are both required.
 *
 * The returned function has a convenient signature for calls like:
 *
 *   flixApiV2('post', 'api/endpoint', {key: 'value'})
 */
export var flixApiV2Fetch = function flixApiV2Fetch(_ref) {
  var endpoints = _ref.endpoints,
      headers = _ref.headers,
      credentials = _ref.credentials,
      tokenStore = _ref.tokenStore,
      nftTokenStore = _ref.nftTokenStore,
      getLocaleHeaders = _ref.getLocaleHeaders,
      onError = _ref.onError;
  var uri = endpoints.v2;
  return function (method, path, data, optionsOverride) {
    var uriOverride = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : undefined;

    var options = _objectSpread(_objectSpread({}, optionsOverride), {}, {
      credentials: credentials,
      method: method.toUpperCase(),
      headers: new Headers(_objectSpread(_objectSpread({}, headers), getLocaleHeaders()))
    });

    options.headers.set('Authorization', tokenStore.get() || '');
    options.headers.set('Authorization-NFT', nftTokenStore.get() || '');

    if (data instanceof FormData) {
      // Don't set Content-Type, fetch does so automatically to include the separator
      options.body = data;
    } else if (data) {
      options.headers.set('Content-Type', 'application/json');
      options.body = JSON.stringify(data);
    }

    return fetch("".concat(uriOverride || uri).concat(path), options).then(function (response) {
      if (!response.ok) {
        // Use the same network error format as apollo-link-rest
        return response.text().then(function (result) {
          var error;

          try {
            var parsedResult = JSON.parse(result);
            error = new Error(parsedResult.message || parsedResult.error);
            error.result = parsedResult;
          } catch (e) {
            error = new Error(response.statusText);
            error.result = result;
          }

          error.statusCode = response.status;
          error.response = response;
          return Promise.reject(error);
        });
      }

      return response;
    }).then(function (response) {
      if (response.headers.has('Authorization')) {
        tokenStore.set(response.headers.get('Authorization'));
      }

      if (response.headers.has('Authorization-NFT')) {
        nftTokenStore.set(response.headers.get('Authorization-NFT'));
      }

      return (response.headers.get('Content-Type') || '').includes('application/json') ? response.json() : response.text();
    }).catch(function (networkError) {
      onError({
        networkError: networkError
      }); // Mutates networkError

      throw networkError;
    });
  };
};