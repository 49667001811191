import ReactGA from 'react-ga4'

export const googleAnalyticsTracker = {
  name: 'googleAnalytics',

  config({ trackingId }) {
    ReactGA.initialize(trackingId)
    return this
  },

  logPageView(path, search) {
    ReactGA.set({ page: search ? path + search : path })
    ReactGA.send('pageview')
  },

  logVideoEvent(eventType, video, seekTime, duration, otherParams) {},

  sessionEnded() {},

  sessionResumed() {},

  newLead(type = 'registration') {
    ReactGA.event({ action: 'Lead', category: type })
  },

  registered() {
    ReactGA.event({ action: 'CompletedRegistration', category: 'user' })
  },

  signedIn() {},

  signedOut() {},

  cardAdded() {
    ReactGA.event({ action: 'AddedPaymentInfo', category: 'card' })
  },

  initiatedCheckout(planType, currency, price) {
    ReactGA.event({
      action: 'InitiatedCheckout',
      category: 'subscription',
      label: planType,
      value: price,
    })
  },

  purchasedSubscription(planType, currency, price, trial) {
    ReactGA.event({ action: 'Purchased', category: 'subscription', label: planType, value: price })
  },

  purchasedTVOD(tierSku, currency, price) {
    ReactGA.event({ action: 'Purchased', category: 'tvod', label: tierSku, value: price })
  },

  viewedContentList(list) {
    ReactGA.event({ action: 'ViewedContent', category: 'filmList', label: list })
  },

  viewedTitleDetails(titleSlug) {
    ReactGA.event({ action: 'ViewedContent', category: 'title', label: titleSlug })
  },

  setReminder(titleSlug) {
    ReactGA.event({ action: 'AddedToWishlist', category: 'title', label: titleSlug })
  },

  addedToWatchlist(titleSlug) {
    ReactGA.event({ action: 'AddedToWishlist', category: 'title', label: titleSlug })
  },

  watchedTrailer(titleSlug) {
    ReactGA.event({ action: 'ViewedContent', category: 'trailer', label: titleSlug })
  },

  watchedFeature(titleSlug) {
    ReactGA.event({ action: 'ViewedContent', category: 'feature', label: titleSlug })
  },

  sentRecommendation(titleSlug) {
    ReactGA.event({ action: 'Lead', category: 'recommendation', label: titleSlug })
  },

  ratedTitle(titleSlug, rating) {
    ReactGA.event({ action: 'Rated', category: 'title', label: titleSlug, value: rating })
  },

  searched(searchString) {
    ReactGA.event({ action: 'Searched', category: 'title', label: searchString })
  },
}
