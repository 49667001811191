import styled from 'styled-components'
import { heading } from '../H2/H2'
import { Text } from '../Text/Text'

export const H3 = styled(Text).attrs({
  accessibilityRole: 'header',
  'aria-level': 3,
})`
  ${heading};
  font-size: ${(props) => props.theme.fontSizes.larger}px;
  margin-bottom: ${(props) => props.theme.spacing[props.mb || props.margin || 'small']}px;
`
