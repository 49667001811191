import cs from 'classnames'
import PropTypes from 'prop-types'
import styles from './MobileNavToggle.scss'

const MobileNavToggle = ({ open, onClick, className }) => (
  <button
    type="button"
    onClick={onClick}
    className={cs(className, styles.button, open && styles.active)}
  >
    <span className={styles.srOnly}>Toggle navigation</span>
    <span className={styles.iconBar} />
    <span className={styles.iconBar} />
    <span className={styles.iconBar} />
  </button>
)

MobileNavToggle.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default MobileNavToggle
