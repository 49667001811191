/**
 * Converts `?a=1&b=2` to { a: 1, b: 2 }
 */
export const getParamsObjFromString = (paramsStr = '') => {
  return Object.fromEntries(new URLSearchParams(paramsStr).entries())
}

/**
 * Converts  { a: 1, b: 2 } to `a=1&b=2`
 *
 * Ignores undefined values
 */
export const queryParamsFromObject = (paramsObj) => {
  const params = new URLSearchParams()
  Object.entries(paramsObj)
    .filter(([, value]) => Boolean(value))
    .forEach(([name, value]) => params.set(name, value))
  return params.toString()
}
