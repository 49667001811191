import { createContext, useEffect, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'

export const ScrollContext = createContext({})

export const ScrollRestorationProvider = ({ children }) => {
  const isEnabled = useRef(true)
  const { pathname, hash } = useLocation()

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const element = document.querySelector(hash)
        if (element) {
          element.scrollIntoView()
        }
      }, 1000)
    } else {
      if (isEnabled.current) {
        window.scrollTo(0, 0)
      }
    }
  }, [pathname, hash])

  const value = useMemo(
    () => ({
      setScrollRestorationEnabled: (enabled) => (isEnabled.current = enabled),
    }),
    [],
  )

  return <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>
}
