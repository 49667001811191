import { Route } from 'react-router-dom'
import { useAuthStatus } from '@mwxltd/flix-api-client'
import SignInPage from '../auth/SignInPage/SignInPage'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { hasSignedIn } = useAuthStatus()
  return (
    <Route
      {...rest}
      render={(props) => (hasSignedIn ? <Component {...props} /> : <SignInPage {...props} />)}
    />
  )
}

export default PrivateRoute
