import cs from 'classnames'
import PropTypes from 'prop-types'
import styles from './ModalPage.scss'

const ModalPage = ({ vOffset, overflowVisible, children }) => (
  <div
    className={cs(styles.modalPage, vOffset && styles.vOffset, overflowVisible && styles.overflow)}
  >
    {children}
  </div>
)

ModalPage.propTypes = {
  vOffset: PropTypes.bool,
  overflowVisible: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

ModalPage.defaultProps = {
  vOffset: true,
}

export default ModalPage
