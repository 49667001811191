import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { useTimeout } from 'usehooks-ts'
import { ActivityIndicator } from '../ActivityIndicator/ActivityIndicator'

const styles = StyleSheet.create({
  flex: { flex: 1 },
  top: { justifyContent: 'flex-start' },
  middle: { justifyContent: 'center' },
  bottom: { justifyContent: 'flex-end' },
  left: { alignItems: 'flex-start' },
  center: { alignItems: 'center' },
  right: { alignItems: 'flex-end' },
})

export const ComponentPlaceholder = ({
  flex = true,
  width,
  height,
  activityIndicator,
  activityIndicatorDelayed,
  horizontal = 'center',
  vertical = 'middle',
  background,
  style,
  children,
  ...props
}) => {
  const theme = useTheme()
  const backgroundColor = background && theme.colors[background]

  // Wait before showing the activity indicator, to avoid a flash in case the load completes quickly
  const [showIndicator, setShowIndicator] = useState(!activityIndicatorDelayed)
  useTimeout(() => setShowIndicator(true), activityIndicatorDelayed)

  return (
    <View
      style={[
        flex && !height && styles.flex,
        styles[horizontal],
        styles[vertical],
        { width, height, backgroundColor },
        style,
      ]}
    >
      {(activityIndicator || showIndicator) && <ActivityIndicator scale={1.5} {...props} />}
    </View>
  )
}

ComponentPlaceholder.propTypes = {
  scale: PropTypes.number,
  color: PropTypes.string,
  horizontal: PropTypes.oneOf(['left', 'center', 'right']),
  vertical: PropTypes.oneOf(['top', 'middle', 'bottom']),
}
