import { View } from 'react-native'
import styled from 'styled-components'
import { media } from '../../../style/media'

export const Col = styled(View)`
  flex: ${(props) => props.size || 0};
  ${media.sm`
    flex: ${(props) => props.smSize || props.size || 0};
  `};
  ${media.md`
    flex: ${(props) => props.mdSize || props.smSize || props.size || 0};
  `};
  ${media.lg`
    flex: ${(props) => props.lgSize || props.mdSize || props.smSize || props.size || 0};
  `};

  flex-shrink: ${(props) => props.shrink || 1};
  flex-basis: ${(props) => props.basis || '0px'};
  ${media.sm`
    flex-shrink: 1;
    flex-basis: 0px;
  `};

  padding: 0 ${(props) => (props.gutter ? props.theme.spacing[props.gutter] / 2 : 0)}px;
`

export const Row = styled(View)`
  flex-wrap: ${(props) => props.wrap || 'nowrap'};
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  align-items: ${(props) => props.alignItems || 'stretch'};
  gap: ${(props) => (props.gap ? props.theme.spacing[props.gap] + 'px' : 'normal')};
  width: 100%;
  margin: 0 ${(props) => (props.gutter ? -props.theme.spacing[props.gutter] / 2 : 0)}px;
  margin-bottom: ${(props) => props.theme.spacing[props.mb || 'none']}px;
`
