import { Platform, View } from 'react-native'
import styled, { css } from 'styled-components'

const isNative = Platform.OS !== 'web'

export const targetBlank = { target: 'blank', rel: 'noopener' }

export const outlineNone = isNative
  ? undefined
  : css`
      outline-style: none;
    `

export const whiteSpaceNoWrap = isNative
  ? undefined
  : css`
      white-space: nowrap;
    `

export const cursorNotAllowed = isNative
  ? undefined
  : css`
      cursor: not-allowed;
    `

export const cursorPointer = isNative
  ? undefined
  : css`
      cursor: pointer;
    `

export const cursorText = isNative
  ? undefined
  : css`
      cursor: text;
    `

export const userSelectNone = isNative
  ? undefined
  : css`
      user-select: none;
    `

export const CursorPointerView = styled(View)`
  ${cursorPointer};
`
