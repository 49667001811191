import { defineMessages } from 'react-intl'

export default defineMessages({
  nftCreatorsNav: {
    id: 'app.staticPages.NFTCreator.creatorsNav',
    defaultMessage: 'NFT Creators',
  },
  heading: {
    id: 'app.staticPages.NFTCreator.heading',
    defaultMessage: 'Become an NFT Creator',
  },
})
