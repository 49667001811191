"use strict";

// A very simple event emitter; the current use case does not require
// unsubscribing unless the app is unloaded, so even that is not supported.
export function eventEmitter() {
  var listeners = {};

  function on(event, listener) {
    if (!listeners[event]) {
      listeners[event] = [];
    }

    listeners[event].push(listener);
    return {
      remove: function remove() {
        listeners[event] = listeners[event].filter(function (thisListener) {
          return thisListener !== listener;
        });
      }
    };
  }

  function emit(event) {
    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    if (listeners[event]) {
      listeners[event].forEach(function (listener) {
        return listener.apply(void 0, args);
      });
    }
  }

  return {
    on: on,
    emit: emit
  };
}