export const errorSubscription = {
  data: true, // Custom validators working via mutators.setFieldData sets data.error
  touched: true,
  error: true,
  dirtySinceLastSubmit: true,
  submitError: true,
}

export const errorFromMeta = (meta = {}) =>
  (meta.touched && (meta.error || (meta.data && meta.data.error))) ||
  (!meta.dirtySinceLastSubmit && meta.submitError)
