/**
 * Create a new store by passing an array of storages in order of preference.
 * The first store that works in the current environment will be used.
 */
export function createStore(storages, name) {
  const storage = storages.find(viableStorage)

  if (!storage) {
    throw new Error('No viable storage found')
  }

  return {
    // get returns the value of the given key. If that value
    // is undefined, it returns optionalDefaultValue instead.
    get(key, optionalDefaultValue) {
      var data = storage.get(key)
      return deserialize(data, optionalDefaultValue)
    },

    // set will store the given value at key and returns value.
    // Calling set with value === undefined is equivalent to calling remove.
    set(key, value) {
      if (value === undefined) {
        return this.remove(key)
      }
      storage.set(key, serialize(value))
      return value
    },

    // remove deletes the key and value stored at the given key.
    remove(key) {
      storage.delete(key)
    },
  }
}

export function serialize(obj) {
  return JSON.stringify(obj)
}

export function deserialize(strVal, defaultVal) {
  if (!strVal) {
    return defaultVal
  }
  // It is possible that a raw string value has been previously stored
  // in a storage without using this store, meaning it will be a raw
  // string value instead of a JSON serialized string. By defaulting
  // to the raw string value in case of a JSON parse error, we allow
  // for past stored values to be forwards-compatible.
  try {
    return JSON.parse(strVal)
  } catch (e) {
    return strVal
  }
}

function viableStorage(storage) {
  try {
    const testStr = '__store_test__'
    storage.set(testStr, testStr)
    const ok = storage.get(testStr) === testStr
    storage.delete(testStr)
    return ok
  } catch (e) {
    return false
  }
}
