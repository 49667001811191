import { useMemo } from 'react'
import { ThemeProvider } from 'styled-components'
import PropTypes from 'prop-types'
import { useDimensionsListener } from '../hooks/useDimensions/useDimensionsListener'
import { useRTL } from './RtlProvider'

export const MediaThemeProvider = ({ theme, width: guessedWidth, children }) => {
  const dimensions = useDimensionsListener(guessedWidth)

  const { isRTL } = useRTL()

  const mediaTheme = useMemo(
    () => ({
      ...theme,
      dimensions,
      height: dimensions.height,
      width: dimensions.rotationSafeWidth,
      isRTL,
    }),
    [dimensions, theme, isRTL],
  )
  return <ThemeProvider theme={mediaTheme}>{children}</ThemeProvider>
}

MediaThemeProvider.propTypes = {
  children: PropTypes.element.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.number,
}
