import CSSTransitionReplace from 'react-css-transition-replace'
import './slide-bottom-animation.scss'

const AnimateSlideBottom = ({ children, ...props }) => (
  <CSSTransitionReplace
    transitionName="slide-bottom"
    transitionEnterTimeout={250}
    transitionLeaveTimeout={250}
    {...props}
  >
    {children}
  </CSSTransitionReplace>
)

export default AnimateSlideBottom
