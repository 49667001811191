/**
 * Use the browser localStorage
 */
export function createLocalStore() {
  const { localStorage } = typeof window !== 'undefined' ? window : global

  return {
    get(key) {
      return localStorage.getItem(key)
    },

    set(key, data) {
      return localStorage.setItem(key, data)
    },

    delete(key) {
      return localStorage.removeItem(key)
    },
  }
}
