import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { mapValues } from 'lodash/fp'
import {
  useReminderAddMutation,
  useReminderRemoveMutation,
  useSignOutMutation,
  useWatchListAddMutation,
  useWatchListRemoveMutation,
} from '@mwxltd/flix-api-client'
import messages from '@flix/common/domain/content/messages'
import { kebabCaseHandlers } from '@flix/common/elements/controls/ButtonHandlerContext/ButtonHandlerContext'
import { useMediaIs } from '@flix/common/style/media'
import { queryParamsFromObject } from '@flix/common/utils/uriParams'
import { useSnackbar } from '../app/Snackbar/SnackbarContext'

const encode = (text) => (text ? window.encodeURIComponent(text) : '')

export const useButtonClickHandlers = () => {
  const md = useMediaIs('md')
  const history = useHistory()
  const { snackbarMsg, snackbarError } = useSnackbar()

  const [signOut] = useSignOutMutation()

  const [setReminder] = useReminderAddMutation({
    onCompleted: useCallback(
      (film) => snackbarMsg(messages.reminderSuccess, { title: film?.title ?? 'this film' }),
      [snackbarMsg],
    ),
    onError: snackbarError,
  })
  const [cancelReminder] = useReminderRemoveMutation({
    onCompleted: useCallback(() => snackbarMsg(messages.reminderCancelled), [snackbarMsg]),
    onError: snackbarError,
  })

  const [addToWatchList] = useWatchListAddMutation({
    onCompleted: useCallback(
      (film) => snackbarMsg(messages.watchListAddSuccess, { title: film?.title ?? 'This title' }),
      [snackbarMsg],
    ),
    onError: snackbarError,
  })
  const [removeFromWatchList] = useWatchListRemoveMutation({
    onCompleted: useCallback(() => snackbarMsg(messages.watchListRemoveSuccess), [snackbarMsg]),
    onError: snackbarError,
  })

  return useMemo(() => {
    const handlers = {
      setReminder,
      cancelReminder,
      addToWatchList,
      removeFromWatchList,
      signUp: (routerState) => history.push('/sign-up', routerState),
      signIn: (routerState) => history.push('/sign-in', routerState),
      signOut,
      nowShowing: () => history.push('/now-showing'),
      recommendMovie: ({ slug, type } = {}, source) =>
        history.push(slug ? `/recommend/${type}/${slug}` : '/recommend', { source }),
      filmDetails: ({ filmSlug, slug = filmSlug, festivalId }) =>
        festivalId ? history.push(`/festival/film/${slug}`) : history.push(`/film/${slug}`),
      // TEMPORARY PROP CHANNEL
      viewDetails: (
        { slug, type, series, festivalId, channel, channelSourceSlug },
        navigationType = 'push',
      ) =>
        history[navigationType](
          type === 'person'
            ? `/actor/${slug}`
            : type === 'series'
            ? `/series/${slug}`
            : type === 'season'
            ? `/series/${series?.slug}/${slug}`
            : type === 'episode'
            ? `/series/${series?.slug}/${slug}`
            : type === 'live'
            ? `/live/${slug}`
            : type === 'channel'
            ? channelSourceSlug
              ? `/channel/${slug}/${channelSourceSlug}`
              : `/channel/${slug}`
            : festivalId
            ? `/festival/film/${slug}`
            : `/film/${slug}`,
          { channel },
        ),
      actors: () => history.push('/actors'),
      about: () => history.push('/about'),
      playVideo: ({ video: { title, url, mime } }) =>
        history.push({
          pathname: '/video',
          search: new URLSearchParams(mapValues(encode, { title, url, mime })).toString(),
        }),
      playTrailer: ({ slug }) => history.push(`/film/${slug}/play/trailer`),

      playFeature: ({ slug }, navigationType = 'push') =>
        history[navigationType](`/film/${slug}/play/feature`),

      purchaseTVOD: ({ slug }, navigationType = 'push') =>
        history[navigationType](`/film/${slug}`, { tvodPurchaseModalOpen: true }),

      playDownload: () => {}, // Not supported on web
      viewDownloads: () => {}, // Not supported on web
      viewArticle: (article, blogType = 'close-up') =>
        history.push(`/${blogType}/article/${article.slug}`),
      openUrl: ({ url }) => window.open(url, '_blank'),

      // Group watch
      groupWatchDetails: (gw) =>
        gw.userStatus === 'host'
          ? handlers.groupWatchHostScreen(gw)
          : handlers.groupWatchGuestScreen(gw),
      groupWatchHostScreen: ({
        id,
        filmSlug,
        slug,
        groupWatchId = id,
        festivalId,
        replaceScreen,
      }) => {
        // If the host has been directed away from guest screen, we don't want them to go back
        const open = replaceScreen ? history.replace : history.push
        open(
          `/group-watch/host?${queryParamsFromObject({
            filmSlug: filmSlug ?? slug,
            groupWatchId,
            festivalId,
          })}`,
        )
      },
      groupWatchGuestScreen: ({ id, filmSlug, groupWatchId = id, replaceScreen }) => {
        const open = replaceScreen ? history.replace : history.push
        open(`/group-watch/guest?${queryParamsFromObject({ filmSlug, groupWatchId })}`)
      },
      groupWatchPlayer: ({ filmSlug, groupWatchId }) =>
        history.push(`/group-watch/player?${queryParamsFromObject({ filmSlug, groupWatchId })}`),
      groupWatchList: () => history.push('/group-watch/list'),

      nftHelp: () => history.push('/nft-marketplace/help'),
      nftExplore: () => history.push('/nft-marketplace/explore'),
      nftViewTrending: () => history.push('/nft-marketplace/trending-films'),
      nftViewItem: ({ id }) => history.push(`/nft-marketplace/item/${id}`),
      nftViewFilmCollection: ({ slug }) => history.push(`/nft-marketplace/collection/${slug}`),
      nftBuy: ({ id }) =>
        md
          ? // Larger devices will open a modal on the item details page
            history.push(`/nft-marketplace/item/${id}?buyNow=1`)
          : // Smaller devices use a page workflow
            history.push(`/nft-marketplace/item/${id}/purchase`),
      nftFavorite: ({ id }) => history.push(`/nft-marketplace/item/${id}?signIn=1`),

      festivalAbout: () => history.push('/festival/about'),
      festivalBlog: () => history.push('/festival/blog'),
      festivalAwards: () => history.push('/festival/awards'),
      festivalVote: () => history.push('/festival/vote'),
    }

    // kebab case indexes for API response data and camel case for client code
    return kebabCaseHandlers(handlers)
  }, [md, signOut, setReminder, cancelReminder, addToWatchList, removeFromWatchList, history])
}
