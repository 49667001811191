"use strict";

export var regionAndLocaleStore = function regionAndLocaleStore(_ref) {
  var region = _ref.region,
      locale = _ref.locale;
  var store = {
    region: region,
    locale: locale
  };
  return {
    getLocaleHeaders: function getLocaleHeaders() {
      return {
        'X-Flix-Region': store.region,
        'Accept-Language': store.locale
      };
    },
    onLocaleChange: function onLocaleChange(locale) {
      return store.locale = locale;
    }
  };
};