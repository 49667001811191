import { forwardRef } from 'react'
import { Platform, StyleSheet } from 'react-native'
import { cloudinaryUrl } from '../../../services/cloudinary'
import { RNImage } from './RNImage'

const isWeb = Platform.OS === 'web' || undefined

const getTransformSize = (width, height, aspectRatio) => {
  if (aspectRatio) {
    if (width) {
      return {
        width: Math.ceil(width),
        height: Math.ceil(Math.ceil(width) / aspectRatio),
      }
    }
    if (height) {
      return {
        width: Math.ceil(Math.ceil(height) * aspectRatio),
        height: Math.ceil(height),
      }
    }
    return {
      width: 'iw',
      height: 'ih',
    }
  }
  return {
    width: width ? Math.ceil(width) : 'iw',
    height: height ? Math.ceil(height) : 'ih',
  }
}

export const Image = forwardRef(
  (
    {
      source,
      publicId,
      alt = '',
      width,
      height,
      aspectRatio,
      crop = 'fill',
      style,
      transforms,
      mb,
      ...props
    },
    ref,
  ) => {
    const transformSize = {
      aspectRatio,
      ...getTransformSize(width, height, aspectRatio),
    }
    const elSize = {
      width: transformSize.width,
      height: transformSize.height,
      marginBottom: mb,
    }

    const options = Array.isArray(transforms)
      ? [{ crop, ...transformSize, ...transforms[0] }, ...transforms.slice(1)]
      : { crop, ...transformSize, ...transforms }

    const nativeSource = source || { uri: cloudinaryUrl(publicId, options) }
    return (
      <RNImage
        {...props}
        ref={ref}
        alt={alt}
        style={StyleSheet.compose(elSize, style)}
        source={nativeSource}
        // RNW does not render the source because loading images might compete with loading
        // scripts and reduce time to interactive.
        // https://github.com/necolas/react-native-web/issues/1245#issuecomment-464439893
        // The work-around is to use defaultSource. Without it SSR output has no image URLs
        // and is entirely redundant. Delaying script downloads shouldn't be a problem because
        // these requests will be underway by the time the SSR result reaches the client.
        defaultSource={isWeb && nativeSource}
      />
    )
  },
)

Image.preload = RNImage.preload
Image.getSize = RNImage.getSize

export default Image
