import { createContext, useCallback, useContext, useRef } from 'react'

const CarouselContext = createContext({ indexes: { current: {} } })

export const CarouselSavedIndexes = ({ children }) => {
  const indexes = useRef({})
  return <CarouselContext.Provider value={{ indexes }}>{children}</CarouselContext.Provider>
}

export const useCarouselSavedIndex = (slug, initialIndex) => {
  const { indexes } = useContext(CarouselContext)
  const saveIndex = useCallback(
    (index) => {
      if (slug) {
        indexes.current[slug] = index
      }
    },
    [slug, indexes],
  )
  return {
    initialIndex: (slug && indexes && indexes.current[slug]) ?? initialIndex ?? 0,
    saveIndex,
  }
}
