import { Backdrop, Modal } from '@flix/common/elements/layout/Modal'
import { ActivityIndicator } from '../ActivityIndicator/ActivityIndicator'

export const FullScreenLoader = ({ shown = true }) => {
  if (!shown) return null

  return (
    <Modal transparent>
      <Backdrop dark>
        <ActivityIndicator scale={2} />
      </Backdrop>
    </Modal>
  )
}
