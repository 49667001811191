"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

/**
 * Recursively descends the provided object tree and converts all the keys
 * Borrowed from apollo-link-rest
 */
export var convertObjectKeys = function convertObjectKeys(object, converter) {
  var keypath = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

  if (object == null || _typeof(object) !== 'object') {
    // Object is a scalar or null / undefined => no keys to convert!
    return object;
  }

  if (Array.isArray(object)) {
    return object.map(function (o, index) {
      return convertObjectKeys(o, converter, [].concat(_toConsumableArray(keypath), [String(index)]));
    });
  }

  return Object.keys(object).reduce(function (acc, key) {
    var value = object[key];
    var nestedKeyPath = [].concat(_toConsumableArray(keypath), [key]);
    acc[converter(key, nestedKeyPath)] = convertObjectKeys(value, converter, nestedKeyPath);
    return acc;
  }, {});
};