"use strict";

import { useCallback } from 'react';
import { useFlixApiContext } from '../../FlixApiContext';
import { readFilmDetailsQuery } from '../../content/FilmDetails/useFilmDetailsQuery';
import { TITLE_REMINDER_SET } from '../../eventsNames';
import { addToUserFilmListsQuery } from './useUserFilmListsQuery';
import { addToUserFilmSlugsQuery } from './useUserFilmSlugsQuery';
export var useReminderAddMutation = function useReminderAddMutation() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      onCompleted = _ref.onCompleted,
      onError = _ref.onError;

  var _useFlixApiContext = useFlixApiContext(),
      events = _useFlixApiContext.events,
      client = _useFlixApiContext.client,
      flixApi = _useFlixApiContext.flixApi;

  return [useCallback(function (film) {
    return flixApi('post', "films/".concat(film.id, "/register-interest")).then(function () {
      var _readFilmDetailsQuery;

      var filmSlug = film.slug;
      addToUserFilmSlugsQuery(client, 'reminders', filmSlug);
      var filmData = (_readFilmDetailsQuery = readFilmDetailsQuery(client, filmSlug)) !== null && _readFilmDetailsQuery !== void 0 ? _readFilmDetailsQuery : film;

      if (film.title && film.posterImg) {
        addToUserFilmListsQuery(client, 'reminders', filmData);
      }

      events.emit(TITLE_REMINDER_SET, filmSlug);
      onCompleted && onCompleted(filmData);
    }).catch(function (error) {
      return onError ? onError(error) : Promise.reject(error);
    });
  }, [events, client, flixApi, onCompleted, onError])];
};