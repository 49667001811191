import { useTheme } from 'styled-components'
import { kebabCase, mapKeys } from 'lodash'
import * as svgIcons from './svgIcons'

const icons = {
  ...svgIcons,
  ...mapKeys(svgIcons, (_, key) => kebabCase(key)),
}

export const calcIconSize = (theme, scale = 1) => scale * theme.fontSizes.xxlarge

export const Icon = ({ name, size, fontSize, scale, color, ...props }) => {
  const theme = useTheme()
  const options = {
    name,
    size: (fontSize ? theme.fontSizes[fontSize] : size) || calcIconSize(theme, scale),
    color: color ? theme.colors[color] || color : theme.colors.text,
    isRTL: theme.isRTL,
  }

  const IconSVG = icons[options.name]
  if (process.env.NODE_ENV === 'development' && !IconSVG) {
    throw new Error(`Invalid Icon name specified: ${options.name}`)
  }

  return <IconSVG {...props} {...options} />
}

Icon.calcSize = calcIconSize
