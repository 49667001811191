import { Helmet } from 'react-helmet-async'
import { FormattedMessage, useIntl } from 'react-intl'
import { View } from 'react-native'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useSignInMutation } from '@mwxltd/flix-api-client'
import { Background } from '@flix/common/elements/layout/Modal'
import { B, H2, Text } from '@flix/common/elements/typography'
import ModalPage from '../../../components/modals/ModalPage'
import { useAppState } from '../../../containers/app/AppState/AppState'
import { LoginForm } from '../../../containers/auth/LoginForm'
import authMsgs from '../../../containers/auth/messages'
import { canonicalUrl } from '../../../utils/htmlHead'
import meta from '../../metaDescriptions'
import { SUBSCRIBE_WIZARD_PATH } from '../../subscriptions/constants'

const Row = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing.small}px;
`

const SmallText = styled(Text)`
  color: ${(props) => props.theme.colors.grayLighter};
  font-size: ${(props) => props.theme.fontSizes.xsmall}px;
`

const SwapToRegister = styled(SmallText)`
  padding-right: ${(props) => props.theme.spacing.large}px;
  margin-right: auto;
`

const SignInPage = ({ location, history }) => {
  const intl = useIntl()
  const { getAuthSuccessPath } = useAppState()

  const [signIn] = useSignInMutation({
    onCompleted: (authStatus) =>
      history.replace(
        authStatus.hasSubscription ? getAuthSuccessPath() || '/now-showing' : SUBSCRIBE_WIZARD_PATH,
      ),
  })
  return (
    <ModalPage>
      {location && (
        <Helmet>
          <title>{intl.formatMessage(meta.signInTitle)}</title>
          <meta property="og:title" content="Sign in to Flix Premiere" />
          <meta
            name="description"
            property="og:description"
            content={intl.formatMessage(meta.signIn)}
          />
          <meta property="og:url" content={canonicalUrl(location)} />
          <link rel="canonical" href={canonicalUrl(location)} />
        </Helmet>
      )}

      <Background padded style={{ marginTop: 96, marginBottom: 128 }}>
        <Row>
          <H2>
            <FormattedMessage {...authMsgs.signIn} />
          </H2>
          <Link to="/account/reset-password">
            <SmallText>
              <FormattedMessage {...authMsgs.forgotPwd} />
            </SmallText>
          </Link>
        </Row>

        <LoginForm onSubmit={signIn} onCancel={history.goBack}>
          <SwapToRegister>
            <FormattedMessage
              {...authMsgs.firstTime}
              values={{
                swapLink: (
                  <Link to="/sign-up">
                    <B>
                      <FormattedMessage {...authMsgs.registerHere} />
                    </B>
                  </Link>
                ),
              }}
            />
          </SwapToRegister>
        </LoginForm>
      </Background>
    </ModalPage>
  )
}

export default SignInPage
