import { useMemo } from 'react'
import { ThemeProvider, useTheme } from 'styled-components'
import PropTypes from 'prop-types'

export const MediaWidthOverride = ({ maxWidth, children }) => {
  const theme = useTheme()
  const { width: actualWidth, rotationSafeWidth: actualRSWidth } = theme.dimensions

  const updatedTheme = useMemo(
    () => ({
      ...theme,
      dimensions: {
        ...theme.dimensions,
        width: Math.min(actualWidth, maxWidth),
        rotationSafeWidth: Math.min(actualRSWidth, maxWidth),
      },
      width: Math.min(actualRSWidth, maxWidth),
    }),
    [theme, actualWidth, actualRSWidth, maxWidth],
  )

  return <ThemeProvider theme={updatedTheme}>{children}</ThemeProvider>
}

MediaWidthOverride.propTypes = {
  maxWidth: PropTypes.number.isRequired,
}
