import { useEffect } from 'react'
import { webFontLoaderFonts } from '@flix/common/style/theme'

export const useWebFontLoader = () => {
  useEffect(() => {
    import('webfontloader').then((WebFont) =>
      WebFont.load({
        ...webFontLoaderFonts,
        timeout: 3000,
      }),
    )
  }, [])
}
