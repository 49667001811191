import { Component } from 'react'
import cs from 'classnames'
import PropTypes from 'prop-types'
import { breakPoints } from '@flix/common/style/breakPoints'
import AnimateSlideBottom from '../../../components/layout/AnimateSlideBottom/AnimateSlideBottom'
import styles from './Snackbar.scss'

const isFunction = (maybeFunc) => typeof maybeFunc === 'function'

class Snackbar extends Component {
  componentDidMount() {
    if (this.props.open) {
      this.setTimeout()
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.open &&
      (!prevProps.open ||
        this.props.title !== prevProps.title ||
        this.props.body !== prevProps.body)
    ) {
      this.setTimeout()
    }
  }

  setTimeout() {
    const { duration } = this.props
    if (duration) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.handleClose, duration)
    }
  }

  handleClose = () => {
    this.props.onClose()
    clearTimeout(this.timeout)
  }

  renderSnackbar() {
    const { title, body, bsStyle } = this.props

    const titleContent = isFunction(title) ? title() : title

    return (
      <div key="open" className={cs(styles.Snackbar, styles[bsStyle])}>
        {titleContent && (
          <div className={styles.header}>
            <div style={{ maxWidth: breakPoints.lg, margin: '0 auto' }}>
              <div className={styles.title}>{titleContent}</div>

              <button type="button" className={`close ${styles.close}`} onClick={this.handleClose}>
                <span>×</span>
              </button>
            </div>
          </div>
        )}

        <div className={styles.body}>
          <div style={{ maxWidth: breakPoints.lg, margin: '0 auto' }}>
            {isFunction(body) ? body() : body}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return <AnimateSlideBottom>{this.props.open ? this.renderSnackbar() : null}</AnimateSlideBottom>
  }
}

Snackbar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  body: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  open: PropTypes.bool,
  bsStyle: PropTypes.string,
  duration: PropTypes.number,
  onClose: PropTypes.func,
}

export default Snackbar
