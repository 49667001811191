import { createContext, useContext, useMemo, useState } from 'react'
import { I18nManager } from 'react-native'

const RtlContext = createContext({
  isRTL: I18nManager.getConstants().isRTL,
  setRTL: () => {},
})

export const RtlProvider = ({ children }) => {
  const [isRTL, setIsRTL] = useState(I18nManager.getConstants().isRTL)

  const rtlContextValue = useMemo(
    () => ({
      isRTL,
      setRTL: (updatedRTL) => {
        I18nManager.forceRTL(updatedRTL)
        setIsRTL(updatedRTL)
      },
    }),
    [isRTL],
  )

  return <RtlContext.Provider value={rtlContextValue}>{children}</RtlContext.Provider>
}

export const useRTL = () => useContext(RtlContext)
