import { Redirect, Route, Switch } from 'react-router-dom'
import { useFeatureFlag } from '@mwxltd/flix-api-client'
import config from '../config'
import { loadable } from '../containers/loadable/loadable'
import GuestRoute from './routeGuards/GuestRoute'
import PrivateRoute from './routeGuards/PrivateRoute'
import PrivateRouteAllowSSR from './routeGuards/PrivateRouteAllowSSR'
import { RedirectFullLocation } from './routeGuards/RedirectFullLocation'
import SupportedRegionRoute from './routeGuards/SupportedRegionRoute'
import { SUBSCRIBE_WIZARD_PATH } from './subscriptions/constants'

const HomePage = loadable(() => import('./landing/HomePage/HomePage'))
const AboutPage = loadable(() => import('./static/AboutPage/AboutPage'))
const NowShowingPage = loadable(() => import('./films/NowShowingPage/NowShowingPage'))
const LiveRoutes = loadable(() => import('./live/LiveRoutes'))
const ChannelDetailsPage = loadable(() =>
  import('./contentDetails/ChannelDetailsPage/ChannelDetailsPage'),
)
const FilmDetailsPage = loadable(() => import('./contentDetails/FilmDetailsPage/FilmDetailsPage'))
const ComingSoonPage = loadable(() => import('./films/ComingSoonPage/ComingSoonPage'))
const PremieresPage = loadable(() => import('./films/PremieresPage/PremieresPage'))
const SearchPage = loadable(() => import('./films/SearchPage/SearchPage'))
const ActorsPage = loadable(() => import('./actors/ActorsPage/ActorsPage'))
const ActorPage = loadable(() => import('./actors/ActorPage/ActorPage'))
const PasswordResetPage = loadable(() => import('./auth/PasswordResetPage/PasswordResetPage'))
const PwdResetRequestPage = loadable(() => import('./auth/PwdResetRequestPage/PwdResetRequestPage'))
const RegisterInterestPage = loadable(() =>
  import('./auth/RegisterInterestPage/RegisterInterestPage'),
)
const SignInPage = loadable(() => import('./auth/SignInPage/SignInPage'))
const VerifyEmailPage = loadable(() => import('./auth/VerifyEmailPage/VerifyEmailPage'))
const CloseUpArticlePage = loadable(() => import('./blog/ArticlePage/CloseUpArticlePage'))
const PressArticlePage = loadable(() => import('./blog/ArticlePage/PressArticlePage'))
const PressRoomListPage = loadable(() => import('./blog/ListPage/PressRoomListPage'))
const CloseUpListPage = loadable(() => import('./blog/ListPage/CloseUpListPage'))
const FestivalBlogListPage = loadable(() =>
  import('./festival/FestivalBlogListPage/FestivalBlogListPage'),
)
const FestivalPlaceholderPage = loadable(() =>
  import('./festival/FestivalPlaceholderPage/FestivalPlaceholderPage'),
)
const GroupWatchGuestPage = loadable(() =>
  import('./groupWatch/GroupWatchGuestPage/GroupWatchGuestPage'),
)
const GroupWatchHostPage = loadable(() =>
  import('./groupWatch/GroupWatchHostPage/GroupWatchHostPage'),
)
const GroupWatchListPage = loadable(() =>
  import('./groupWatch/GroupWatchListPage/GroupWatchListPage'),
)
const NFTMarketplaceRoutes = loadable(() => import('./nftMarketplace/NFTMarketplaceRoutes'))
const MyMoviesPage = loadable(() => import('./profile/MyMoviesPage/MyMoviesPage'))
const ProfilePage = loadable(() => import('./profile/ProfilePage/ProfilePage'))
const LandingRecommendAFilm = loadable(() =>
  import('./recommendation/LandingRecommendAFilm/LandingRecommendAFilm'),
)
const RecommendAFilmPage = loadable(() =>
  import('./recommendation/RecommendAFilmPage/RecommendAFilmPage'),
)
const RecommendPage = loadable(() => import('./recommendation/RecommendPage/RecommendPage'))
const CareersPage = loadable(() => import('./static/CareersPage/CareersPage'))
const ContactUsPage = loadable(() => import('./static/ContactUsPage/ContactUsPage'))
const FAQPage = loadable(() => import('./static/FAQPage/FAQPage'))
const FilmPartnersPage = loadable(() => import('./static/FilmPartnersPage/FilmPartnersPage'))
const NFTCreatorAppPage = loadable(() => import('./static/NFTCreatorAppPage/NFTCreatorAppPage'))
const PrivacyPage = loadable(() => import('./static/PrivacyPage/PrivacyPage'))
const TermsPage = loadable(() => import('./static/TermsPage/TermsPage'))
const SeriesDetailsPage = loadable(() =>
  import('./contentDetails/SeriesDetailsPage/SeriesDetailsPage'),
)
const CancelAccountFeedback = loadable(() =>
  import('./subscriptions/CancelFeedback/CancelAccountFeedback'),
)
const CancelSubscriptionFeedback = loadable(() =>
  import('./subscriptions/CancelFeedback/CancelSubscriptionFeedback'),
)
const ChangePlanPage = loadable(() => import('./subscriptions/ChangePlanPage/ChangePlanPage'))
const SubscribeWizard = loadable(() => import('./subscriptions/SubscribeWizard/SubscribeWizard'))
const NotFoundPage = loadable(() => import('../containers/errors/NotFoundPage/NotFoundPage'))

const Routes = () => {
  const { getFlagValue } = useFeatureFlag()
  const channelsEnabled = getFlagValue('channelsEnabled')

  return (
    <Switch>
      <GuestRoute exact path="/" component={HomePage} />
      <GuestRoute path="/home/:sourceSlug?" component={HomePage} />

      <SupportedRegionRoute path="/sign-in" component={SignInPage} />
      <Route path="/register-interest" component={RegisterInterestPage} />

      <PrivateRouteAllowSSR path="/now-showing/:sourceSlug?" component={NowShowingPage} />

      {channelsEnabled && <PrivateRouteAllowSSR path="/live" component={LiveRoutes} />}

      <PrivateRouteAllowSSR path="/premieres/:sourceSlug?" component={PremieresPage} />
      <Redirect path="/red-carpet" to="/premieres" />
      <PrivateRouteAllowSSR path="/coming-soon/:sourceSlug?" component={ComingSoonPage} />
      <PrivateRouteAllowSSR path="/search" component={SearchPage} />

      <PrivateRouteAllowSSR path="/film/:filmSlug" children={<FilmDetailsPage />} />
      <PrivateRouteAllowSSR
        path="/series/:seriesSlug/:seasonSlug?"
        children={<SeriesDetailsPage />}
      />

      {channelsEnabled && (
        <PrivateRouteAllowSSR
          path="/channel/:channelSlug/:sourceSlug"
          children={<ChannelDetailsPage />}
        />
      )}
      {channelsEnabled && (
        <PrivateRouteAllowSSR
          path="/channel/:channelSlug"
          render={({ match }) => <Redirect to={`/channel/${match.params.channelSlug}/live`} />}
        />
      )}

      <PrivateRouteAllowSSR path="/actors" component={ActorsPage} />
      <PrivateRouteAllowSSR path="/actor/:actorSlug" component={ActorPage} />

      <PrivateRoute path="/recommend/:contentType/:filmSlug" component={RecommendAFilmPage} />
      <Redirect path="/recommend/:contentType" to="/recommend" />
      <PrivateRoute path="/recommend" component={RecommendPage} />

      <Route path="/recommended/:contentType/:contentSlug" component={LandingRecommendAFilm} />
      <Redirect path="/recommended/:contentType" to="/recommended" />

      <PrivateRoute path="/profile/my-movies" component={MyMoviesPage} />
      <PrivateRoute path="/profile/subscription/update" component={ChangePlanPage} />
      <PrivateRoute path="/profile/subscription/cancel" component={CancelSubscriptionFeedback} />
      <PrivateRoute path="/profile/account/cancel" component={CancelAccountFeedback} />
      <PrivateRoute path="/profile" component={ProfilePage} />

      <Route path="/close-up/article/:articleSlug" component={CloseUpArticlePage} />
      <Route path="/close-up" component={CloseUpListPage} />
      <Route path="/press-room/article/:articleSlug" component={PressArticlePage} />
      <Route path="/press-room" component={PressRoomListPage} />

      <Route path="/account/verify-email/:token" component={VerifyEmailPage} />
      <Route path="/account/reset-password/:token" component={PasswordResetPage} />
      <Route path="/account/reset-password" component={PwdResetRequestPage} />

      <Route path="/about" component={AboutPage} />
      <Route path="/film-partners" component={FilmPartnersPage} />
      {!!config.nftMarketplace.enabled && (
        <Route path="/nft-creators" component={NFTCreatorAppPage} />
      )}
      <Route path="/contact" component={ContactUsPage} />
      <Route path="/careers" component={CareersPage} />
      <Route path="/faq" component={FAQPage} />
      <Route path="/terms" component={TermsPage} />
      <Route path="/privacy" component={PrivacyPage} />

      <Route path="/group-watch/host" component={GroupWatchHostPage} />
      <Route path="/group-watch/guest" component={GroupWatchGuestPage} />
      <Route path="/group-watch/list" component={GroupWatchListPage} />

      <Route path={'/festival'} component={FestivalPlaceholderPage} />
      <Route path={'/festival-blog'} component={FestivalBlogListPage} />

      {!!config.nftMarketplace.enabled && (
        <PrivateRoute path={'/nft-marketplace'} component={NFTMarketplaceRoutes} />
      )}

      {/* Sign-up wizard variations */}
      <SupportedRegionRoute path={SUBSCRIBE_WIZARD_PATH} component={SubscribeWizard} />
      <Redirect path="/pricing" to={SUBSCRIBE_WIZARD_PATH} />

      <Redirect path="/subscribe/:sourceSlug?" to="/" />
      <Redirect path="/join-free/:sourceSlug?" to="/" />
      <Redirect path="/news" to="/close-up" />
      {/* Redirect old "free-trial-##" landing pages */}
      {/*<Redirect pathToRegexp="/free\-trial\-*" to="/free-trial" />*/}
      <Redirect path={['/free/:sourceSlug?', '/start-trial/:sourceSlug?']} to="/free-trial" />

      {/* Universal Links */}
      <RedirectFullLocation path="/links/group-watch" to="/group-watch/guest" />

      <Route component={NotFoundPage} />
    </Switch>
  )
}

export default Routes
