import isObjectLike from 'lodash/isObjectLike'

const startCase = (str = '') => (str.length ? str.charAt(0).toUpperCase() + str.substr(1) : str)

const isEmpty = (value) =>
  value === undefined || value === null || (value.trim && value.trim() === '')
const isEmptyObject = (value) => isObjectLike(value) && !value.length && !Object.keys(value).length

export const composeValidators =
  (...validators) =>
  (...args) =>
    validators.reduce((error, validator) => error || validator(...args), null)

export function required(value) {
  if (isEmpty(value) || (isEmptyObject(value) && !(value instanceof Date))) {
    return 'required'
  }
}

export function requiredBoolean(value) {
  if (!value) {
    return 'required'
  }
}

export function fullName(value) {
  if (!isEmpty(value) && value.trim().split(' ').length < 2) {
    return 'fullName'
  }
}

export function email(value) {
  if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'email'
  }
}

export function minLength(min) {
  return (value) => {
    if (!isEmpty(value) && value.length < min) {
      return ['minLength', { min }]
    }
  }
}

export const minLength6 = minLength(6)

export function maxLength(max) {
  return (value) => {
    if (!isEmpty(value) && value.length > max) {
      return ['maxLength', { max }]
    }
  }
}

export function lessEqual(max) {
  return (value) => {
    if (!isEmpty(value) && Number.parseFloat(value, 10) > max) {
      return ['lessEqual', { max }]
    }
  }
}

export function greaterEqual(min) {
  return (value) => {
    if (!isEmpty(value) && Number.parseFloat(value, 10) < min) {
      return ['greaterEqual', { min }]
    }
  }
}

export function integer(value) {
  if (!isEmpty(value) && Number.isNaN(Number.parseInt(value, 10))) {
    return 'integer'
  }
}

export function equalsField(field) {
  return (value, data) => {
    if (value && data[field] && value !== data[field]) {
      return ['equalsField', { field: startCase(field) }]
    }
  }
}

export function requiredIfSet(field, fieldValue) {
  return (value, data) => {
    if (!value && data[field] && !fieldValue) {
      return ['requiredIfSet', { field: startCase(field) }]
    }
    if (!value && data[field] && data[field] === fieldValue) {
      return ['requiredIfSetTo', { field: startCase(field), fieldValue }]
    }
  }
}

export const requiredFullName = composeValidators(required, fullName)
export const requiredEmail = composeValidators(required, email)
export const requiredPassword = composeValidators(required, minLength6)
export const requiredEqualsPassword = composeValidators(equalsField('password'), required)
export const requiredCardNo = composeValidators(required, minLength(16), maxLength(16))
export const requiredExpDate = composeValidators(required, minLength(4), maxLength(4))
export const requiredCvvNo = composeValidators(required, minLength(3), maxLength(4))

export const validateForm = (validationSpec) => {
  const fields = Object.keys(validationSpec)
  return (values) => {
    const errors = {}
    let invalid = false

    for (let k = 0; k < fields.length; ++k) {
      const field = fields[k]
      const fieldResult = validationSpec[field](values[field], values)
      if (fieldResult) {
        invalid = true
        errors[field] = fieldResult
      }
    }

    return invalid ? errors : undefined
  }
}
