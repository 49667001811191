export const production = {
  HOST_URL: 'https://flixpremiere.com',
  FLIX_API_ROOT: 'https://api.flixpremiere.com',
  FLIX_API_ROOT_SERVER: 'http://php-web.production',
  REACT_APP_COOKIE_DOMAIN: 'flixpremiere.com',

  REACT_APP_RELEASE_STAGE: 'production',

  DEEPLINK_URI_PREFIX: 'https://flixpremiere.com/links/',

  WEB_SOCKET_DOMAIN: 'flixpremiere.com',

  ANALYTICS_ENABLED: true,
  ANALYTICS_FP_TRACKING_ENABLED: true,
}
