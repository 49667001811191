import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useAuthStatus } from '@mwxltd/flix-api-client'
import messages from '@flix/common/domain/nav/messages'
import { ButtonHandlerContext } from '@flix/common/elements/controls'
import { useButtonClickHandlers } from './useButtonClickHandlers'

export const ButtonHandlersProvider = ({ children }) => {
  const intl = useIntl()
  const authStatus = useAuthStatus()

  const registerCTAText = intl.formatMessage(messages.registerCTAFallback)
  const handlers = useButtonClickHandlers()

  const state = useMemo(
    () => ({ handlers, authStatus, registerCTAText }),
    [authStatus, handlers, registerCTAText],
  )

  return <ButtonHandlerContext.Provider value={state}>{children}</ButtonHandlerContext.Provider>
}
