import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useSignOutMutation } from '@mwxltd/flix-api-client'
import groupWatchMsgs from '@flix/common/domain/group-watch/messages'
import navMsgs from '@flix/common/domain/nav/messages'
import { useOnClickOutside } from '@flix/common/hooks/useOnClickOutside/useOnClickOutside'
import config from '../../../../config'
import authMsgs from '../../../auth/messages'
import { Menu } from './Menu'
import { MenuItem } from './MenuItem'

export const UserNavMenu = ({ onHide, hasSubscription }) => {
  const ref = useOnClickOutside(onHide)
  const intl = useIntl()
  const history = useHistory()

  const [signOut] = useSignOutMutation({
    onCompleted: () => history.replace('/'),
  })

  return (
    <Menu ref={ref} onClick={onHide}>
      {hasSubscription && (
        <>
          <MenuItem label={intl.formatMessage(navMsgs.myMovies)} to="/profile/my-movies" />
          <MenuItem label={intl.formatMessage(groupWatchMsgs.navMenu)} to="/group-watch/list" />
        </>
      )}
      {!!config.nftMarketplace.enabled && hasSubscription && (
        <MenuItem label={intl.formatMessage(navMsgs.nftProfile)} to="/nft-marketplace/profile" />
      )}
      <MenuItem label={intl.formatMessage(navMsgs.profile)} to="/profile" />
      <MenuItem label={intl.formatMessage(authMsgs.signOut)} onClick={signOut} color="grayLighte" />
    </Menu>
  )
}
