export const appStores = {
  android: 'https://play.google.com/store/apps/details?id=com.flixpremiere',
  androidTV: 'https://play.google.com/store/apps/details?id=com.flixpremiere.stv',
  ios: 'https://itunes.apple.com/us/app/flix-premiere/id1091295300',
  fireTV: 'https://www.amazon.co.uk/Flix-Premiere-Limited/dp/B01N5H5NNJ/ref=sr_1_1',
  roku: 'https://channelstore.roku.com/details/122694/flix-premiere',
  microsoft:
    'https://www.microsoft.com/en-gb/p/flix-premiere/9nph5kvml0sq?activetab=pivot:overviewtab',
}

export const socialAccounts = {
  twitter: {
    account: 'FlixPremiere',
    page: 'https://twitter.com/flixpremiere',
  },
  facebook: {
    namespace: 'flixpremiere',
    page: 'https://www.facebook.com/flixpremiere',
  },
  instagram: {
    page: 'https://www.instagram.com/flixpremiere',
  },
  youtube: {
    page: 'https://www.youtube.com/channel/UCnYihKUuOYOKdK3Ewbq16_w',
  },
}
