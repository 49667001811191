import { defineMessages } from 'react-intl'

export default defineMessages({
  register: {
    id: 'app.auth.register',
    defaultMessage: 'Register',
  },
  registerNow: {
    id: 'app.auth.registerNow',
    defaultMessage: 'Register Now',
  },
  signUp: {
    id: 'app.auth.signUp',
    defaultMessage: 'Sign Up',
  },
  signIn: {
    id: 'app.auth.signIn',
    defaultMessage: 'Sign In',
  },
  signOut: {
    id: 'app.auth.signOut',
    defaultMessage: 'Sign Out',
  },

  signingIn: {
    id: 'app.auth.signingIn',
    defaultMessage: 'Signing in…',
  },
  signingUp: {
    id: 'app.auth.signingUp',
    defaultMessage: 'Signing up…',
  },
  registering: {
    id: 'app.auth.registering',
    defaultMessage: 'Registering…',
  },

  returning: {
    id: 'app.auth.LoginReg.returning',
    defaultMessage: 'Returning customers {swapLink}',
  },
  firstTime: {
    id: 'app.auth.LoginReg.firstTime',
    defaultMessage: 'First time customers can {swapLink}',
  },
  registerHere: {
    id: 'app.auth.LoginReg.registerHere',
    defaultMessage: 'register here',
  },
  forgotPwd: {
    id: 'app.auth.LoginReg.forgotPwd',
    defaultMessage: 'Forgotten password?',
  },

  currentPwd: {
    id: 'app.auth.currentPwd',
    defaultMessage: 'Current Password',
  },
  newPwd: {
    id: 'app.auth.newPwd',
    defaultMessage: 'New Password',
  },
  confirmPwd: {
    id: 'app.auth.confirmPwd',
    defaultMessage: 'Confirm Password',
  },
  confirmNewPwd: {
    id: 'app.auth.confirmNewPwd',
    defaultMessage: 'Confirm New Password',
  },
  changePwd: {
    id: 'app.auth.changePwd',
    defaultMessage: 'Change Password',
  },

  promoCode: {
    id: 'app.auth.RegisterForm.promoCode',
    defaultMessage: 'Promo Code - if applicable',
  },
  marketingOptIn: {
    id: 'app.auth.RegisterForm.marketingOptIn',
    defaultMessage: 'Receive weekly updates on our exclusive premieres and theatrical releases',
  },

  registerInterestTitle: {
    id: 'app.auth.registerInterest.title',
    defaultMessage: 'Register your interest',
  },
  registerInterestDescription: {
    id: 'app.auth.registerInterest.description',
    defaultMessage:
      'We will create your account now and then notify you once our service becomes available.',
  },
  registerInterestOptIn: {
    id: 'app.auth.registerInterest.optIn',
    defaultMessage: 'Send me updates when Flix Premiere expands to my region',
  },
  registerInterestThanks: {
    id: 'app.auth.registerInterest.thanks',
    defaultMessage: 'Thank you, we will keep you updated!',
  },
})
