import CSSTransitionReplace from 'react-css-transition-replace'
import './slide-left-animation.scss'

const AnimateSlideLeft = ({ children, ...props }) => (
  <CSSTransitionReplace
    transitionName="slide-left"
    transitionEnterTimeout={200}
    transitionLeaveTimeout={150}
    overflowHidden={false}
    {...props}
  >
    {children}
  </CSSTransitionReplace>
)

export default AnimateSlideLeft
