import '../config/polyfills'
// Import CSS reset and Bootstrap before CSS modules via App
import './bootstrap/_import.scss'
import * as React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { AppRegistry } from 'react-native-web'
import { BrowserRouter } from 'react-router-dom'
import { loadableReady } from '@loadable/component'
import { EnsureAuthStatusLoaded, FlixApiProvider } from '@mwxltd/flix-api-client'
import { createIntlProvider } from '@mwxltd/react-intl-provider'
import { CarouselSavedIndexes } from '@flix/common/elements/carousels/BaseCarousel/CarouselContext'
import { flixApiClientConfig, trackApiEvents } from '@flix/common/services/flix-api-client'
import cookieStore from '@flix/common/services/store/cookieStore'
import localStore from '@flix/common/services/store/localStore'
import { RtlProvider } from '@flix/common/style/RtlProvider'
import config from './config'
import App from './containers/app/App/App'
import { initializeEngagementTracker } from './containers/app/EngagementTracker/engagementTracker'
import { SnackbarProvider } from './containers/app/Snackbar/SnackbarContext'
import { LanguageProvider } from './containers/locale/LanguageProvider/LanguageProvider'
import bugsnag, { bugsnagStart } from './services/bugsnag'
import locales from './services/i18n/locales'
import { useWebFontLoader } from './services/webFontLoader/webFontLoader'

const tokenStore = cookieStore(config.flixApi.authTokenKey, config.cookieDomain)
const nftTokenStore = localStore(config.flixApi.nftAuthTokenKey)

const appConfig = window.__appConfig ?? {}
const initialRegionAndLocale = window.__regionAndLocale
const initialState = window.__apolloState ?? {}
const intlMessages = window.__intlMessages ?? {}

const BugsnagErrorBoundary = bugsnagStart().getPlugin('react').createErrorBoundary(React)

const onError = ({ originalError }) => {
  if (originalError && String(originalError).includes('ChunkLoadError')) {
    window.location.reload()
  }
}

const IntlProvider = createIntlProvider(locales)

const Root = () => {
  // Use WebFontLoader to manage CSS while loading and avoid FOUT
  useWebFontLoader()

  const flixApiConfig = flixApiClientConfig({
    bugsnag,
    tokenStore,
    nftTokenStore,
    initialState,
    initialRegionAndLocale,
    apiCdnDomain: appConfig.apiCdnDomain,
    apiRoot: config.flixApi.apiRoot,
    webSocketDomain: config.flixApi.webSocketDomain,
    webSocketUnsecured: config.flixApi.webSocketUnsecured,
    webSocketPollingDisabled: config.flixApi.webSocketPollingDisabled,
    clientInfo: config.clientInfo,
    appVersion: config.appVersion,
    paymentProvider: config.paymentProvider,
  })

  initializeEngagementTracker(flixApiConfig.flixApiV2)
  trackApiEvents(flixApiConfig.events, bugsnag)

  return (
    <BugsnagErrorBoundary onError={onError}>
      <SafeAreaProvider>
        <HelmetProvider>
          <FlixApiProvider {...flixApiConfig}>
            <EnsureAuthStatusLoaded>
              <RtlProvider>
                <LanguageProvider
                  messages={intlMessages}
                  bugsnag={bugsnag}
                  IntlProvider={IntlProvider}
                >
                  <BrowserRouter>
                    <CarouselSavedIndexes>
                      <SnackbarProvider>
                        <App deviceWidth={window.__deviceWidth} />
                      </SnackbarProvider>
                    </CarouselSavedIndexes>
                  </BrowserRouter>
                </LanguageProvider>
              </RtlProvider>
            </EnsureAuthStatusLoaded>
          </FlixApiProvider>
        </HelmetProvider>
      </SafeAreaProvider>
    </BugsnagErrorBoundary>
  )
}

const MOUNT_NODE = document.getElementById('root')

AppRegistry.registerComponent('App', () => Root)

const render = () =>
  MOUNT_NODE
    ? AppRegistry.runApplication('App', {
        hydrate: true,
        rootTag: MOUNT_NODE,
        initialProps: {},
      })
    : window.location.reload()

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./containers/app/App/App'], () => {
    AppRegistry.unmountApplicationComponentAtRootTag(MOUNT_NODE)
    render()
  })
}

loadableReady(render)
