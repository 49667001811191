import PropTypes from 'prop-types'
import { useFeatureFlag } from '@mwxltd/flix-api-client'
import navMsgs from '@flix/common/domain/nav/messages'
import config from '../../../config'
import { useAppState } from '../../app/AppState/AppState'
import NavLink from '../NavLink/NavLink'

const MainNav = ({ hasSubscription }) => {
  const { getFlagValue } = useFeatureFlag()
  const { showFullHeaderFooter: fullNav } = useAppState()
  const channelsEnabled = getFlagValue('channelsEnabled')
  const festivalEnabled = getFlagValue('festivalEnabled')

  return (
    fullNav &&
    hasSubscription && (
      <>
        <NavLink to="/now-showing" labelMsg={navMsgs.nowShowing} />
        {channelsEnabled && <NavLink to="/live" labelMsg={navMsgs.live} />}
        <NavLink to="/premieres" labelMsg={navMsgs.premieres} />
        <NavLink to="/coming-soon" labelMsg={navMsgs.comingSoon} />
        <NavLink to="/actors" labelMsg={navMsgs.actors} />
        <NavLink to="/close-up" labelMsg={navMsgs.blog} />
        <NavLink
          to={{ pathname: '/recommend', state: { source: 'mainMenu' } }}
          labelMsg={navMsgs.recommend}
        />
        {!!config.nftMarketplace.enabled && (
          <NavLink to="/nft-marketplace" labelMsg={navMsgs.nft} />
        )}
        <NavLink to="/search" labelMsg={navMsgs.search} />
        {festivalEnabled && <NavLink to="/festival/about" labelMsg={navMsgs.festival} />}
      </>
    )
  )
}

MainNav.propTypes = {
  hasSubscription: PropTypes.bool,
  showHome: PropTypes.bool,
}

export default MainNav
