import { createCookieStorage, createMemoryStore, createStore } from '../storages/index.js'
import syncStore from '../syncStore'

// Storing values on cookies instead of the HTML5 local store
// makes the values available to the server for SSR
const cookieStore = (key, domain) =>
  syncStore(createStore([createCookieStorage({ sameSite: 'Lax', domain }), createMemoryStore()]))(
    key,
  )

export default cookieStore
