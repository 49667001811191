import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

export const useMobileNavOpenState = () => {
  const history = useHistory()
  const [mobileNavOpen, setMobileNavOpen] = useState(false)

  useEffect(() => {
    return history.listen(() => setMobileNavOpen(false))
  }, [history])

  return {
    mobileNavOpen,
    setMobileNav: setMobileNavOpen,
    toggleMobileNav: () => setMobileNavOpen(!mobileNavOpen),
  }
}
