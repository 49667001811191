import { Redirect, Route } from 'react-router-dom'
import { useAuthStatus } from '@mwxltd/flix-api-client'

const SupportedRegionRoute = ({ component: Component, ...rest }) => {
  const { supportedRegion } = useAuthStatus()
  return (
    <Route
      {...rest}
      render={(props) => (supportedRegion ? <Component {...props} /> : <Redirect to="/" />)}
    />
  )
}

export default SupportedRegionRoute
