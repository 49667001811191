import { Platform, TouchableOpacity, View } from 'react-native'
import styled from 'styled-components'
import { appStores } from '../../../config/3rdPartyLinks'
import { Button } from '../../../elements/controls'
import { Icon } from '../../../elements/images/Icon'
import { Image } from '../../../elements/images/Image/Image'
import { Backdrop, Background, CloseButton, Content, Modal } from '../../../elements/layout/Modal'
import { H1, H2, H3, Text } from '../../../elements/typography'
import messages from '../messages'
import android from './images/android.png'
import ios from './images/ios.png'

const H1BoldRed = styled(H1)`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.xlarge}px;
  line-height: ${(props) => props.theme.fontSizes.xlarge * 1.3}px;
  text-transform: uppercase;
  text-align: center;
`

const SubTitle = styled(Text)`
  font-size: ${(props) => props.theme.fontSizes.large}px;
  margin-bottom: ${(props) => props.theme.spacing.xsmall}px;
`

const H2Bold = styled(H2)`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.xxlarge}px;
  line-height: ${(props) => props.theme.fontSizes.xxlarge * 1.1}px;
  color: ${(props) => props.theme.colors.primaryText};
  text-transform: uppercase;
  text-align: center;
`

const BulletList = styled(View)`
  margin: ${(props) => props.theme.spacing.small}px ${(props) => props.theme.spacing.xsmall}px
    ${(props) => props.theme.spacing.larger}px;
  align-items: center;
`

const BulletItem = styled(View)`
  margin: ${(props) => props.theme.spacing.xsmall}px 0;
  flex-direction: row;
  align-items: center;
`

const Bullet = styled(View)`
  margin-right: ${(props) => props.theme.spacing.smedium}px;
  align-items: center;
  flex: 0 0 28px;
`

const BulletText = styled(Text)`
  flex: 1 1 auto;
`

const MobileAppRow = styled(View)`
  margin-bottom: ${(props) => props.theme.spacing.medium}px;
  flex-direction: row;
  align-items: center;
  max-width: 330px;
  overflow: visible;
`

const MobileAppText = styled(H3)`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.larger}px;
  text-transform: uppercase;
  text-align: center;
  flex: 1 1 auto;
`

const GetMobileApp =
  Platform.OS !== 'web'
    ? () => null
    : () => (
        <MobileAppRow>
          <a href={appStores.ios} target="_blank" rel="noopener noreferrer">
            <Image source={ios} width={42} height={67} />
          </a>
          <MobileAppText>For the best experience download the app</MobileAppText>
          <a href={appStores.android} target="_blank" rel="noopener noreferrer">
            <Image source={android} width={42} height={67} />
          </a>
        </MobileAppRow>
      )

const MainButton = styled(Button)`
  background: ${(props) => props.theme.colors.primary};
`

export const SuccessModalContent = ({ onRequestClose }) => (
  <Backdrop>
    <Background>
      <Content>
        <H1BoldRed>Welcome to the world of Amazing Independent Film!</H1BoldRed>
        <SubTitle>Do not miss</SubTitle>
        <H2Bold>New Premieres{'\n'}Every Week</H2Bold>

        <BulletList>
          <BulletItem>
            <Bullet>
              <Icon name="alarm" size={30} />
            </Bullet>
            <BulletText>Click "Remind Me" on upcoming films for email notifications.</BulletText>
          </BulletItem>
          <BulletItem>
            <Bullet>
              <Icon name="video-camera" size={31} />
            </Bullet>
            <BulletText>
              Find out more about undiscovered gems with Close-Up - our online film magazine.
            </BulletText>
          </BulletItem>
          <BulletItem>
            <Bullet>
              <Icon name="share" size={32} />
            </Bullet>
            <BulletText>Recommend us and our movies as many times as you like.</BulletText>
          </BulletItem>
          <BulletItem>
            <Bullet>
              <Icon name="heart" size={32} />
            </Bullet>
            <BulletText>Follow us on social media for news, trailers and even more.</BulletText>
          </BulletItem>
        </BulletList>

        <GetMobileApp />

        <MainButton
          icon="ticket-filled"
          iconColor="white"
          intlMsg={messages.enter}
          onPress={onRequestClose}
        />
      </Content>

      <CloseButton scale={0.75}>
        <TouchableOpacity onPress={onRequestClose}>
          <Icon name="close" scale={0.75} />
        </TouchableOpacity>
      </CloseButton>
    </Background>
  </Backdrop>
)

export const SuccessModal = ({ visible, onRequestClose }) => (
  <Modal visible={visible} onRequestClose={onRequestClose} animationType="fade" transparent>
    <SuccessModalContent onRequestClose={onRequestClose} />
  </Modal>
)
