import { useEffect } from 'react'
import dateFnsLocalizer, { defaultFormats } from 'react-widgets-date-fns'
import { useAuthStatus } from '@mwxltd/flix-api-client'
import { useRTL } from '@flix/common/style/RtlProvider'

/**
 * Connects the current locale state to the IntlProvider component and i18n messages
 */
export const LanguageProvider = ({ messages, bugsnag, IntlProvider, children }) => {
  const { locale, localeRtl } = useAuthStatus()

  const { setRTL } = useRTL()

  useEffect(() => {
    setRTL(localeRtl)
  }, [setRTL, localeRtl])

  useEffect(() => {
    const dateFormat = locale === 'en-US' ? 'MM-dd-yyyy' : 'yyyy-MM-dd'
    const timeFormat = locale === 'en-US' ? 'hh:mm aaaa' : 'HH:mm'
    dateFnsLocalizer({
      formats: {
        ...defaultFormats,
        date: dateFormat,
        time: timeFormat,
        default: `${dateFormat} ${timeFormat}`,
      },
    })
  }, [locale])

  return (
    <IntlProvider messages={messages} locale={locale} notifyError={bugsnag.notify}>
      {children}
    </IntlProvider>
  )
}
