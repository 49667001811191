export const staging = {
  HOST_URL: 'https://staging.flixpremiere.com',
  FLIX_API_ROOT: 'https://api.staging.flixpremiere.com',
  FLIX_API_ROOT_SERVER: 'http://php-web.staging',
  REACT_APP_COOKIE_DOMAIN: 'staging.flixpremiere.com',

  REACT_APP_RELEASE_STAGE: 'staging',

  DEEPLINK_URI_PREFIX: 'https://staging.flixpremiere.com/links/',

  WEB_SOCKET_DOMAIN: 'staging.flixpremiere.com',

  ANALYTICS_ENABLED: false,
  ANALYTICS_FP_TRACKING_ENABLED: true,

  EXTENDED_LANGUAGES_ENABLED: true,

  REACT_APP_NFT_MARKETPLACE_ENABLED: true,
  REACT_APP_MORALIS_SERVER_URL: 'https://wpvfslvorvvs.usemoralis.com:2053/server',
  REACT_APP_MORALIS_APP_ID: 'goALvZVRi8y43ZWbpAr0HDOVs3PRy7U8EfsMlQ60',
  REACT_APP_ETHERSCAN_URL: 'https://ropsten.etherscan.io',
}
