import { Dimensions } from 'react-native'
import cloudinary from 'cloudinary-tiny-js'

export const cloudName = 'flixpremiere'

export const defaultTransforms = {
  crop: 'fill',
  width: 'auto',
  quality: 'auto',
  fetchFormat: 'auto',
  dpr: Dimensions.get('window').scale,
}

export const cloudinaryUrl = cloudinary({
  cloudName,
  imageTransformDefaults: defaultTransforms,
})
