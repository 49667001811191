import { View } from 'react-native'
import styled from 'styled-components'
import { outlineNone } from '../../../style/webStyles.js'

export const PaddedSVG = styled(View)`
  padding: ${(props) => ((props.padding ?? 0) * props.size) / 2}px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  justify-content: center;
  align-items: center;
  ${outlineNone};
`
