"use strict";

import { useCallback } from 'react';
import { useFlixApiContext } from '../../FlixApiContext';
import { removeFromUserFilmListsQuery } from './useUserFilmListsQuery';
import { removeFromUserFilmSlugsQuery } from './useUserFilmSlugsQuery';
export var useReminderRemoveMutation = function useReminderRemoveMutation() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      onCompleted = _ref.onCompleted,
      onError = _ref.onError;

  var _useFlixApiContext = useFlixApiContext(),
      client = _useFlixApiContext.client,
      flixApi = _useFlixApiContext.flixApi;

  return [useCallback(function (_ref2) {
    var filmId = _ref2.id,
        filmSlug = _ref2.slug;
    return flixApi('delete', "films/".concat(filmId, "/cancel-interest")).then(function () {
      removeFromUserFilmSlugsQuery(client, 'reminders', filmSlug);
      removeFromUserFilmListsQuery(client, 'reminders', filmSlug);
      onCompleted && onCompleted();
    }).catch(function (error) {
      return onError ? onError(error) : Promise.reject(error);
    });
  }, [client, flixApi, onCompleted, onError])];
};