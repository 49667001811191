import { defineMessages } from 'react-intl'

export default defineMessages({
  menu: {
    id: 'app.menu',
    defaultMessage: 'Menu',
  },

  title: {
    id: 'app.mainMenu.title',
    defaultMessage: 'Flix Premiere',
  },
  tagLine: {
    id: 'app.mainMenu.tagLine',
    defaultMessage: 'Discover great indie films',
  },

  home: {
    id: 'app.mainMenu.home',
    defaultMessage: 'Home',
  },
  search: {
    id: 'app.mainMenu.search',
    defaultMessage: 'Search',
  },
  festival: {
    id: 'app.mainMenu.festival',
    defaultMessage: 'Festival',
  },
  nowShowing: {
    id: 'app.mainMenu.nowShowing',
    defaultMessage: 'Now Showing',
  },
  live: {
    id: 'app.mainMenu.live',
    defaultMessage: 'Live',
  },
  premieres: {
    id: 'app.mainMenu.premieres',
    defaultMessage: 'Premieres',
  },
  comingSoon: {
    id: 'app.mainMenu.comingSoon',
    defaultMessage: 'Coming Soon',
  },
  actors: {
    id: 'app.mainMenu.actors',
    defaultMessage: 'Actors',
  },
  downloads: {
    id: 'app.mainMenu.downloads',
    defaultMessage: 'Downloads',
  },
  groupWatch: {
    id: 'app.mainMenu.groupWatch',
    defaultMessage: 'Screening Parties',
  },
  about: {
    id: 'app.mainMenu.about',
    defaultMessage: 'About',
  },
  help: {
    id: 'app.mainMenu.help',
    defaultMessage: 'Help',
  },
  cancel: {
    id: 'app.mainMenu.cancel',
    defaultMessage: 'Cancel Account',
  },
  signIn: {
    id: 'app.mainMenu.signIn',
    defaultMessage: 'Sign In',
  },
  signOut: {
    id: 'app.mainMenu.signOut',
    defaultMessage: 'Sign Out',
  },
  registerCTAFallback: {
    id: 'app.mainMenu.registerCTAFallback',
    defaultMessage: 'Try 30 days free',
  },
  pricing: {
    id: 'app.mainMenu.pricing',
    defaultMessage: 'Pricing',
  },
  profile: {
    id: 'app.mainMenu.profile',
    defaultMessage: 'Profile',
  },
  nftProfile: {
    id: 'app.mainMenu.nftProfile',
    defaultMessage: 'NFT Account',
  },
  choosePlan: {
    id: 'app.mainMenu.choosePlan',
    defaultMessage: 'Choose a Plan',
  },
  myMovies: {
    id: 'app.mainMenu.myMovies',
    defaultMessage: 'My Movies',
  },
  blog: {
    id: 'app.mainMenu.blog',
    defaultMessage: 'Close-Up',
  },
  recommend: {
    id: 'app.mainMenu.recommend',
    defaultMessage: 'Recommend',
  },
  nft: {
    id: 'app.mainMenu.nft',
    defaultMessage: 'NFT',
  },
  originals: {
    id: 'app.mainMenu.originals',
    defaultMessage: 'Originals',
  },
  inTheaters: {
    id: 'app.mainMenu.inTheaters',
    defaultMessage: 'In Theaters',
  },
  exit: {
    id: 'app.mainMenu.exit',
    defaultMessage: 'Exit',
  },

  // Festival Nav
  festivalHome: {
    id: 'app.festivalMenu.festivalHome',
    defaultMessage: 'Home',
  },
  festivalAbout: {
    id: 'app.festivalMenu.festivalAbout',
    defaultMessage: 'About',
  },
  festivalLineup: {
    id: 'app.festivalMenu.lineup',
    defaultMessage: 'LineUp',
  },
  festivalAwards: {
    id: 'app.festivalMenu.awards',
    defaultMessage: 'Awards',
  },
  myFestival: {
    id: 'app.festivalMenu.myFestival',
    defaultMessage: 'My Festival',
  },
  festivalBlog: {
    id: 'app.festivalMenu.festivalBlog',
    defaultMessage: 'News',
  },
})
