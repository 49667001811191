import { Redirect, Route } from 'react-router-dom'

export const RedirectFullLocation = ({ path, from, to, ...rest }) => (
  <Route
    path={path || from}
    render={({ location }) => (
      <Redirect
        {...rest}
        to={{
          ...location,
          ...(typeof to === 'string' ? { pathname: to } : to),
        }}
      />
    )}
  />
)
