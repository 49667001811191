import config from '../../config/index.js'

const locales = {
  defaultLocale: 'en-US',
  locales: {
    'en-US': {
      translationsUrl: '/translations/en-US.json',
      reactIntlPolyfills: () => Promise.all([
        Intl.PluralRules.polyfilled && import('@formatjs/intl-pluralrules/locale-data/en'),
        Intl.NumberFormat.polyfilled && import('@formatjs/intl-numberformat/locale-data/en'),
        Intl.DateTimeFormat.polyfilled && import('@formatjs/intl-datetimeformat/locale-data/en'),
        Intl.RelativeTimeFormat.polyfilled && import('@formatjs/intl-relativetimeformat/locale-data/en'),
      ]),
    },
    'en-GB': {
      translationsUrl: '/translations/en-GB.json',
      reactIntlPolyfills: () => Promise.all([
        Intl.PluralRules.polyfilled && import('@formatjs/intl-pluralrules/locale-data/en'),
        Intl.NumberFormat.polyfilled && import('@formatjs/intl-numberformat/locale-data/en-GB'),
        Intl.DateTimeFormat.polyfilled && import('@formatjs/intl-datetimeformat/locale-data/en-GB'),
        Intl.RelativeTimeFormat.polyfilled && import('@formatjs/intl-relativetimeformat/locale-data/en-GB'),
      ]),
    },
  },
}

if (config.EXTENDED_LANGUAGES_ENABLED) {
  locales.locales['fr-FR'] = {
    translationsUrl: '/translations/fr-FR.json',
    reactIntlPolyfills: () => Promise.all([
      Intl.PluralRules.polyfilled && import('@formatjs/intl-pluralrules/locale-data/fr'),
      Intl.NumberFormat.polyfilled && import('@formatjs/intl-numberformat/locale-data/fr'),
      Intl.DateTimeFormat.polyfilled && import('@formatjs/intl-datetimeformat/locale-data/fr'),
      Intl.RelativeTimeFormat.polyfilled && import('@formatjs/intl-relativetimeformat/locale-data/fr'),
    ]),
  }
  locales.locales['ru-RU'] = {
    translationsUrl: '/translations/ru-RU.json',
    reactIntlPolyfills: () => Promise.all([
      Intl.PluralRules.polyfilled && import('@formatjs/intl-pluralrules/locale-data/ru'),
      Intl.NumberFormat.polyfilled && import('@formatjs/intl-numberformat/locale-data/ru'),
      Intl.DateTimeFormat.polyfilled && import('@formatjs/intl-datetimeformat/locale-data/ru'),
      Intl.RelativeTimeFormat.polyfilled && import('@formatjs/intl-relativetimeformat/locale-data/ru'),
    ]),
  }
  locales.locales['ar-QA'] = {
    translationsUrl: '/translations/ar-QA.json',
    reactIntlPolyfills: () => Promise.all([
      Intl.PluralRules.polyfilled && import('@formatjs/intl-pluralrules/locale-data/ar'),
      Intl.NumberFormat.polyfilled && import('@formatjs/intl-numberformat/locale-data/ar'),
      Intl.DateTimeFormat.polyfilled && import('@formatjs/intl-datetimeformat/locale-data/ar'),
      Intl.RelativeTimeFormat.polyfilled && import('@formatjs/intl-relativetimeformat/locale-data/ar'),
    ]),
  }
  locales.locales['es-ES'] = {
    translationsUrl: '/translations/es-ES.json',
    reactIntlPolyfills: () => Promise.all([
      Intl.PluralRules.polyfilled && import('@formatjs/intl-pluralrules/locale-data/es'),
      Intl.NumberFormat.polyfilled && import('@formatjs/intl-numberformat/locale-data/es'),
      Intl.DateTimeFormat.polyfilled && import('@formatjs/intl-datetimeformat/locale-data/es'),
      Intl.RelativeTimeFormat.polyfilled && import('@formatjs/intl-relativetimeformat/locale-data/es'),
    ]),
  }
  locales.locales['pt-PT'] = {
    translationsUrl: '/translations/pt-PT.json',
    reactIntlPolyfills: () => Promise.all([
      Intl.PluralRules.polyfilled && import('@formatjs/intl-pluralrules/locale-data/pt'),
      Intl.NumberFormat.polyfilled && import('@formatjs/intl-numberformat/locale-data/pt'),
      Intl.DateTimeFormat.polyfilled && import('@formatjs/intl-datetimeformat/locale-data/pt'),
      Intl.RelativeTimeFormat.polyfilled && import('@formatjs/intl-relativetimeformat/locale-data/pt'),
    ]),
  }
}

export default locales
