import { I18nManager } from 'react-native'
import { css } from 'styled-components'

const { isRTL } = I18nManager.getConstants()

/**
 * In most cases I18nManager will flip text-align, so that text normally
 * aligned on the left is now aligned on the right. In some cases however
 * this does not work, in which case we should use this.
 */

export const textAlignRTL = css`
  text-align: ${isRTL ? 'right' : 'left'};
`

/**
 * This should be overline (at least for Arabic), but it seems like this
 * is not supported by React Native. Needs investigation.
 */
const overline = css`
  text-decoration-line: underline;
`

const underline = css`
  text-decoration-line: underline;
`

const italic = css`
  font-style: italic;
`

const bold = css`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-family: ${(props) => props.theme.fontFamilies.baseBold};
`

/**
 * Using overline for all emphasis works for Arabic, but when we
 * start supporting other RTL languages we'll need something smarter.
 */

export const textDecorationLineRTL = css`
  ${isRTL ? overline : underline};
`

export const weightBoldRTL = css`
  ${isRTL ? overline : bold}
`

export const italicRTL = css`
  ${isRTL ? overline : italic}
`

export const reverseArrIfRTL = (arr) => (!isRTL ? arr : arr.reverse())
