import { useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { useAuthStatus } from '@mwxltd/flix-api-client'

const PrivateRouteAllowSSR = ({ component: Component, ...rest }) => {
  const history = useHistory()
  const { hasSubscription, supportedRegion } = useAuthStatus()

  useEffect(() => {
    if (!supportedRegion || !hasSubscription) {
      const timeout = setTimeout(() => history.replace('/'), 1000)
      return () => clearTimeout(timeout)
    }
  }, [hasSubscription, supportedRegion, history])

  return <Route {...rest} component={Component} />
}

export default PrivateRouteAllowSSR
