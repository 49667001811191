import styled, { css } from 'styled-components'
import { Text } from '../Text/Text'

export const heading = css`
  font-family: ${(props) => props.theme.fontFamilies[props.light ? 'headline' : 'headlineBold']};
  font-weight: ${(props) => props.theme.fontWeights[props.light ? 'normal' : 'bold']};
  text-align: ${(props) => props.textAlign || (props.center ? 'center' : 'left')};
`

export const H2 = styled(Text).attrs({
  accessibilityRole: 'header',
  'aria-level': 2,
})`
  ${heading};
  font-size: ${(props) => props.theme.fontSizes.xlarge}px;
  margin-bottom: ${(props) => props.theme.spacing[props.mb || props.margin || 'small']}px;
`
