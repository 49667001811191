import styled from 'styled-components'
import PropTypes from 'prop-types'
import { media } from '@flix/common/style/media'
import { Text } from '../Text/Text'

export const H1 = styled(Text).attrs({
  accessibilityRole: 'header',
  'aria-level': 1,
})`
  margin-top: ${(props) => props.theme.spacing[props.mt || 'none']}px;
  margin-bottom: ${(props) =>
    props.theme.spacing[props.mb || props.marginBottom || props.margin || 'small']}px;
  font-weight: ${(props) => props.theme.fontWeights[props.light ? 'normal' : 'bold']};
  font-family: ${(props) => props.theme.fontFamilies.headlineBold};
  font-size: ${(props) => props.theme.fontSizes.xlarge}px;
  line-height: ${(props) => props.theme.fontSizes.xlarge * 1.15}px;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  ${media.sm`
    font-size: ${(props) => props.theme.fontSizes.xxlarge}px;
    line-height: ${(props) => props.theme.fontSizes.xxlarge * 1.15}px;
  `};
`

H1.propTypes = {
  center: PropTypes.bool,
  mt: PropTypes.string,
  mb: PropTypes.string,
}
