import { defineMessages } from 'react-intl'

export default defineMessages({
  // Subscription screen
  signUpFinalStep: {
    id: 'app.membership.signUpFinalStep',
    defaultMessage: "Final step and you're finished!",
  },
  youveChosenOurPlan: {
    id: 'app.membership.youveChosenOurPlan',
    defaultMessage: 'You have chosen our {plan}.',
  },
  youveChosenPlanWithTrial: {
    id: 'app.membership.youveChosenPlanWithTrial',
    defaultMessage: 'No payment will be taken now.',
  },
  youveChosenPlanWithPayment: {
    id: 'app.membership.youveChosenPlanWithPayment',
    defaultMessage: 'You will be charged {amount}.',
  },
  chooseDifferentPlan: {
    id: 'app.membership.chooseDifferentPlan',
    defaultMessage: 'Choose a different plan',
  },
  enterBillingDetails: {
    id: 'app.membership.enterBillingDetails',
    defaultMessage: 'Final step, add billing details',
  },
  createAccount: {
    id: 'app.membership.createAccount',
    defaultMessage: 'Create your account',
  },
  createAccountOrSignIn: {
    id: 'app.membership.createAccountOrSignIn',
    defaultMessage: 'Create your account or {signInLink}.',
  },
  noCommitCancel: {
    id: 'app.membership.noCommitCancel',
    defaultMessage: 'No Commitment, Cancel Anytime',
  },
  receiveUpdates: {
    id: 'app.membership.receiveUpdates',
    defaultMessage: 'I want to receive updates on Flix Premiere and special offers.',
  },
  acceptTermsAndPolicy: {
    id: 'app.membership.acceptTermsAndPolicy',
    defaultMessage: 'By proceeding you are agreeing to our {termsOfUse} and {privacyPolicy}.',
  },
  btnSignUpFree: {
    id: 'app.membership.btnSignUpFree',
    defaultMessage: 'Start your Free Trial',
  },
  btnSignUpPaid: {
    id: 'app.membership.btnSignUpPaid',
    defaultMessage: 'Sign up Now',
  },
  doNotRefresh: {
    id: 'app.membership.doNotRefresh',
    defaultMessage: 'Please do not refresh the page, the process may take a few seconds.',
  },
  orUse: {
    id: 'app.membership.orUse',
    defaultMessage: 'Or use',
  },
  payPalTitle: {
    id: 'app.membership.payPalTitle',
    defaultMessage: 'Your account has been created',
  },
  payPalInstructions: {
    id: 'app.membership.payPalInstructions',
    defaultMessage:
      'Please click the button below to proceed to the PayPal website and complete your subscription to our {plan}.',
  },

  // Subscription screen promos
  promoBenefits: {
    id: 'app.membership.promo.benefits',
    defaultMessage: 'Plan benefits',
  },
  promoFeaturedIn: {
    id: 'app.membership.promo.featuredIn',
    defaultMessage: 'Featured in',
  },
  promoInstantAccess: {
    id: 'app.membership.promo.instantAccess',
    defaultMessage: 'Get instant access to award-winning movies',
  },
  promoSayingAbout: {
    id: 'app.membership.promo.sayingAbout',
    defaultMessage: 'What they say about us',
  },

  // Verify email screen
  verifyEmailTitle: {
    id: 'app.membership.verifyEmailTitle',
    defaultMessage: 'Almost done…',
  },
  verifyEmailSentTo: {
    id: 'app.membership.verifyEmailSentTo',
    defaultMessage: 'An email verification link has been sent to {email}.',
  },
  verifyEmailToWatchMovies: {
    id: 'app.membership.verifyEmailToWatchMovies',
    defaultMessage:
      'Please verify your email to activate your account and begin watching our movies.',
  },
  verifyEmailCheckSpam: {
    id: 'app.membership.verifyEmailCheckSpam',
    defaultMessage: "Can't find the email? Make sure to {bold}, or request a new one below:",
  },
  verifyEmailCheckSpamBold: {
    id: 'app.membership.verifyEmailCheckSpamBold',
    defaultMessage: 'check your spam folder',
  },
  verifyEmailAnyIssuesContact: {
    id: 'app.membership.verifyEmailAnyIssuesContact',
    defaultMessage:
      'If you’re having any issues, please feel free to email us at {email}, or you can call us at {phone}.',
  },
  verifyEmailResendSuccess: {
    id: 'app.membership.verifyEmailResendSuccess',
    defaultMessage: 'Email verification has been resent',
  },
  verifyEmailDoneIt: {
    id: 'app.membership.verifyEmailDoneIt',
    defaultMessage: 'I have verified, continue',
  },
  verifyEmailDoneChecking: {
    id: 'app.membership.verifyEmailDoneChecking',
    defaultMessage: 'Checking…',
  },
  verifyEmailDoneItFailed: {
    id: 'app.membership.verifyEmailDoneItFailed',
    defaultMessage:
      "We couldn't confirm that your email has been verified; please click on the link we've sent you via email to activate your account and begin watching our movies.",
  },
  verifyEmailReenter: {
    id: 'app.membership.verifyEmailReenter',
    defaultMessage: 'Re-enter email address',
  },
  verifyEmailReenterInput: {
    id: 'app.membership.verifyEmailReenterInput',
    defaultMessage: 'Re-enter your correct email address',
  },

  // Change plan screen
  changePlanTitle: {
    id: 'app.membership.changePlanTitle',
    defaultMessage: "Choose the plan that's right for you",
  },
  chooseCurrentPlan: {
    id: 'app.membership.chooseCurrentPlan',
    defaultMessage: 'Current plan',
  },
  chooseThisPlan: {
    id: 'app.membership.chooseThisPlan',
    defaultMessage: 'Choose this plan',
  },
  planChanged: {
    id: 'app.membership.planChanged',
    defaultMessage: 'Your subscription plan was updated successfully',
  },

  // Cancellation
  confirmCancelPlan: {
    id: 'app.membership.confirmCancelPlan',
    defaultMessage:
      'Cancel your subscription? You will still have full access until your current plan expires, but you will not be charged again.',
  },
  planCancelled: {
    id: 'app.membership.planCancelled',
    defaultMessage: 'Your subscription has been cancelled',
  },

  cancelAccount: {
    id: 'app.profile.ProfilePage.cancelAccount',
    defaultMessage: 'Cancel',
  },
  closeYourAccount: {
    id: 'app.profile.ProfilePage.closeYourAccount',
    defaultMessage: 'Close your account',
  },
  cancelAccountDescr: {
    id: 'app.profile.ProfilePage.cancelAccountDescr',
    defaultMessage:
      'If you close your account there will be no way to retrieve it. Please be sure.',
  },
  cancelAccountSubscr: {
    id: 'app.profile.ProfilePage.cancelAccountSubscr',
    defaultMessage:
      'Please cancel your {provider} subscription yourself before cancelling your Flix Premiere account. We cannot cancel {provider} subscriptions on your behalf.',
  },
  cancelAccountBtn: {
    id: 'app.profile.ProfilePage.cancelAccountBtn',
    defaultMessage: 'Close Your Account',
  },
  accountCancelled: {
    id: 'app.membership.accountCancelled',
    defaultMessage: 'Your account has been cancelled',
  },

  // Success Modal
  enter: {
    id: 'app.membership.enter',
    defaultMessage: 'Enter Now Showing',
  },
})
