import { Platform } from 'react-native'
import { breakPoints } from '../../style/breakPoints'
import { canUseDOM } from '../../utils/canUseDOM'

const isNative = Platform.OS !== 'web'

// Mobile Chrome has a bug where the window.innerWidth/Height, which is used by RNW
// in the Dimensions API, is incorrect while using the video player. There is a Chrome
// issue which is marked as fixed, but this still occurs under on the video player in 2020.
// The SO answer suggests using the document.documentElement.clientWidth which remains correct.
// https://stackoverflow.com/a/51443045/1379290
const fixWindowSize =
  isNative || !canUseDOM
    ? (dimensionsWindow) => dimensionsWindow
    : (dimensionsWindow) => ({
        ...dimensionsWindow,
        width: Math.min(document.documentElement.clientWidth, dimensionsWindow.width),
        height: Math.min(document.documentElement.clientHeight, dimensionsWindow.height),
      })

export const formatDimensions = ({ window, screen }, insets, disableWindowSizeFix) => {
  window = disableWindowSizeFix ? window : fixWindowSize(window)
  const smallerDimension = Math.round(Math.min(window.width, window.height))
  const largerDimension = Math.round(Math.max(window.width, window.height))
  // Desktop windows with small height may look like phones, so the larger
  // dimension should also be checked to ensure it is reasonable for a phone
  const isPhone = isNative && smallerDimension < breakPoints.sm && largerDimension < breakPoints.lg
  // The iPad user agent masquerades as a desktop browser, so the screen size
  // is used for a further check for when this is needed
  const isIPadSize =
    (smallerDimension === 1024 && largerDimension === 1366) ||
    (smallerDimension === 768 && largerDimension === 1024)
  const portrait = window.height >= window.width
  return {
    window,
    screen,
    insets,
    largerDimension,
    smallerDimension,
    width: Math.round(window.width),
    height: Math.round(window.height),
    safeAreaWidth: window.width - insets.left - insets.right,
    safeAreaHeight: window.height - insets.top - insets.bottom,
    isIPadSize,
    // iPhone X+ has a width larger than the `sm` breakpoint in landscape orientation.
    // This flag can be used to differentiate phones/tablets when this should be considered.
    isPhone,
    // Tablets support landscape orientation and always use the measured width.
    // Phones are locked to portrait orientation, except when playing videos and image
    // carousels, and should continue using the portrait width for other content
    // so it does not resize awkwardly during the rotation.
    rotationSafeWidth: isPhone ? smallerDimension : window.width,
    portrait,
    landscape: !portrait,
  }
}
