import camelCase from 'lodash/camelCase'

// Find a list of utm tracking codes in the query string
export const utmTrackingKeys = (query) => [...query.keys()].filter((key) => key.startsWith('utm'))

// Returns an object with camel cased keys and values of all utm codes
export const utmTrackingData = (keys, query) =>
  keys.reduce((data, key) => {
    data[camelCase(key)] = query.get(key)
    return data
  }, {})
