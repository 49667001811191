"use strict";

import { useMemo } from 'react';
import { useAuthStatus } from './useAuthStatusQuery';
export var useFeatureFlag = function useFeatureFlag() {
  var _useAuthStatus = useAuthStatus(),
      appConfig = _useAuthStatus.appConfig;

  return useMemo(function () {
    return {
      getFlagValue: function getFlagValue(flagName) {
        return appConfig === null || appConfig === void 0 ? void 0 : appConfig[flagName];
      }
    };
  }, [appConfig]);
};