class EngagementTracker {
  trackers = []

  config({ bugsnag, trackers }) {
    this.bugsnag = bugsnag
    this.trackers = trackers.filter(Boolean)
  }

  addTracker(tracker) {
    tracker.push(tracker)
  }

  track(funcName) {
    return (...args) => {
      this.trackers.forEach((tracker) => {
        Promise.resolve(tracker[funcName](...args)).catch((error) => {
          error.name = 'Tracking Error'
          this.bugsnag.notify(error, (event) => {
            event.severity = 'warning'
            event.addMetadata('details', { tracker: tracker.name, func: funcName, args })
          })
        })
      })
    }
  }

  logPageView = this.track('logPageView')
  logVideoEvent = this.track('logVideoEvent')

  sessionEnded = this.track('sessionEnded')
  sessionResumed = this.track('sessionResumed')

  newLead = this.track('newLead')
  registered = this.track('registered')
  signedIn = this.track('signedIn')
  signedOut = this.track('signedOut')

  cardAdded = this.track('cardAdded')
  initiatedCheckout = this.track('initiatedCheckout')
  purchasedSubscription = this.track('purchasedSubscription')
  purchasedTVOD = this.track('purchasedTVOD')

  viewedContentList = this.track('viewedContentList')
  viewedTitleDetails = this.track('viewedTitleDetails')

  setReminder = this.track('setReminder')
  addedToWatchlist = this.track('addedToWatchlist')

  watchedTrailer = this.track('watchedTrailer')
  watchedFeature = this.track('watchedFeature')

  sentRecommendation = this.track('sentRecommendation')
  ratedTitle = this.track('ratedTitle')
  searched = this.track('searched')
}

export const engagementTracker = new EngagementTracker()
