import { useRef } from 'react'
import { View } from 'react-native'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useHover } from 'usehooks-ts'
import { Text } from '@flix/common/elements/typography'

const MItem = styled(View)`
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  line-height: 32px;
  padding: ${(props) => props.theme.spacing.smedium}px ${(props) => props.theme.spacing.larger}px;
  background-color: ${(props) => props.theme.colors[props.hovered ? 'grayDark' : 'transparent']};
`

const MText = styled(Text)`
  text-align: right;
  font-family: ${(props) => props.theme.fontFamilies.headline};
`

export const MenuItem = ({ label, to, onClick, color }) => {
  const history = useHistory()
  const ref = useRef()
  const hovered = useHover(ref)
  return (
    <MItem
      ref={ref}
      role="menuitem"
      hovered={hovered}
      onClick={(ev) => (to ? history.push(to) : onClick(ev))}
    >
      <MText color={color}>{label}</MText>
    </MItem>
  )
}
