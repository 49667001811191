import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import config from '../../config'

// This instance of Bugsnag is reused in the admin2 client because of other components imported across
// projects which also use it. The function allows that client to initialise with different options.
export function bugsnagStart(options) {
  Bugsnag.start({
    ...config.bugsnag,
    enabledReleaseStages: ['production', 'staging'],
    autoTrackSessions: config.bugsnag.releaseStage === 'production',
    plugins: [new BugsnagPluginReact()],
    metadata: {
      clientInfo: config.clientInfo,
    },
    ...options,
  })
  return Bugsnag
}

// Log to console in development rather than swallowing errors
if (process.env.NODE_ENV === 'development') {
  Bugsnag.notify = (error, onError) => {
    console.error(error) // eslint-disable-line
    if (onError) {
      onError({ addMetadata: (name, data) => console.log(name, data) }) // eslint-disable-line
    }
  }
}

export default Bugsnag
