import { FormattedMessage } from 'react-intl'
import { Text, TouchableOpacity } from 'react-native'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { media } from '../../../style/media'
import { cursorNotAllowed, cursorPointer } from '../../../style/webStyles'
import Icon from '../../images/Icon'

const ButtonBlock = styled(TouchableOpacity)`
  ${(props) => props.flex && `flex: ${props.flex}`};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${(props) => props.minWidth && `min-width: ${props.minWidth}px`};
  ${(props) => props.alignSelf && `align-self: ${props.alignSelf}`};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  padding: ${(props) => `${props.theme.spacing.xs * 1.5}px ${props.theme.spacing.sm * 1.5}px`};
  background-color: ${(props) =>
    props.link ? 'transparent' : props.theme.colors[props.variant] || props.theme.colors.gray};
  margin-bottom: ${(props) => props.theme.spacing[props.mb || 'smedium']}px;
  margin-right: ${(props) => props.theme.spacing[props.mr || 'none']}px;
  border-radius: ${(props) => props.theme.components.button.borderRadius}px;
  ${(props) => (props.disabled ? cursorNotAllowed : cursorPointer)};
`

const Label = styled(Text)`
  color: ${(props) => props.theme.colors[props.titleColor]};
  line-height: ${(props) => props.theme.fontSizes.larger}px;
  font-size: ${(props) => props.theme.fontSizes.small}px;
  font-weight: ${(props) => props.theme.fontWeights[props.link ? 'normal' : 'bold']};
  font-family: ${(props) => props.theme.fontFamilies.base};
  text-transform: ${(props) => (props.link ? 'none' : 'uppercase')};
  text-decoration: ${(props) => (props.link && props.underline ? 'underline' : 'none')}
    ${(props) => props.theme.colors.text};

  ${media.lg`
    font-size: ${(props) => props.theme.fontSizes.medium}px;
  `}
`

const ButtonIcon = styled(Icon)`
  margin-right: ${(props) => props.theme.spacing[props.addMargin ? 'xs' : 'none']}px;
`

export const Button = ({
  title,
  intlMessage,
  intlMsg = intlMessage,
  values,
  flex,
  fontStyle,
  link,
  underline = true,
  children,
  icon,
  iconColor = fontStyle?.color ?? 'white',
  titleColor = fontStyle?.color ?? 'white',
  ...props
}) => (
  <ButtonBlock {...props} link={link} flex={flex}>
    {children}

    {icon && <ButtonIcon scale={0.7} name={icon} color={iconColor} addMargin={title || intlMsg} />}

    {(title || intlMsg) && (
      <Label
        numberOfLines={1}
        style={fontStyle}
        link={link}
        underline={underline}
        titleColor={titleColor}
      >
        {title || <FormattedMessage {...intlMsg} values={values} />}
      </Label>
    )}
  </ButtonBlock>
)

Button.propTypes = {
  /** Provide a string title for the button text */
  title: PropTypes.string,
  /** Provide a string react-intl compatible message for the button text */
  intlMsg: PropTypes.object,
  /** Press event handler */
  onPress: PropTypes.func,
  /** Set the flex box `align-self` style, use for positioned buttons rather than full width */
  alignSelf: PropTypes.string,
  /** Minimum button width, useful to ensure a set of buttons have the same size */
  minWidth: PropTypes.number,
  /** Name of a color to apply to the background, e.g. `primary`, `grayDark`. */
  variant: PropTypes.string,
  /** Button displays as a link rather than a button */
  link: PropTypes.bool,
  /** Set to `false` with `link` to get a link button without underline, defaults to `true` */
  underline: PropTypes.bool,
  /** Applied to the button text (only applies to a `title`) */
  fontStyle: PropTypes.object,
  /** Optional SVG icon that will be displayed on the button */
  icon: PropTypes.string,
  /** Optional color of the icon. Theme color names can be used. Defaults to `fontStyle.color` or `'white'`. */
  iconColor: PropTypes.string,
  /** Alternative to title to provide button with icons etc. */
  children: PropTypes.any,
}

export default Button
