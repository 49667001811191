import { flixApiEvents } from '@mwxltd/flix-api-client'
import { canUseDOM } from '../../../utils/canUseDOM.js'

// On React Native devices or DOM environments with cookies enabled
const canUseCookies = () => !canUseDOM || navigator.cookieEnabled

const assumeOpenSessionWithin = 5 * 60 * 1000 // 5 minutes

const optionsOverride = {
  keepalive: true, // outlive the page; a replacement for Navigator.sendBeacon()
  priority: 'low',
}

class FlixPremiereTracker {
  name = 'flixPremiereTracker'
  queue = []

  config({ flixApiV2, uriOverride }) {
    this.flixApiV2 = flixApiV2
    this.uriOverride = uriOverride
    return this
  }

  /** @private */
  sendEvent = (eventType, itemId, itemDetails) => {
    return this.flixApiV2(
      'post',
      'tracking/user-session/event',
      { eventType, itemId, itemDetails },
      optionsOverride,
      this.uriOverride,
    )
  }

  /** @private */
  sendPageView = (pathname, search = '') => {
    return this.flixApiV2(
      'post',
      'tracking/user-session/page-view',
      { page: pathname, search, referrer: canUseDOM ? document.referrer : undefined },
      optionsOverride,
      this.uriOverride,
    )
  }

  /** @private */
  videoSessionId = undefined

  /** @private */
  sendVideoEvent = (parameters) => {
    return this.flixApiV2(
      'post',
      'tracking/video-session/event',
      {
        ...parameters,
        videoSessionId: this.videoSessionId,
      },
      optionsOverride,
      this.uriOverride,
    ).then(({ videoSessionId }) => {
      this.videoSessionId = videoSessionId
    })
  }

  /** @private */
  lastRequestTimestamp = 0

  /** @private */
  queueEvent(eventLogger, ...args) {
    if (canUseCookies()) {
      // 1. Add a new item to the queue
      this.queue.push([eventLogger, ...args])
      // 2. If this is the first item, send it; this will start a session if none exists
      if (
        this.queue.length === 1 ||
        Date.now() - this.lastRequestTimestamp < assumeOpenSessionWithin
      ) {
        this.lastRequestTimestamp = 0 // Invalidate while the first request is in flight
        eventLogger(...args)
          .then(() => (this.lastRequestTimestamp = Date.now()))
          .finally(() => {
            // 3. When the first item succeeds or fails, it is shifted from the queue
            this.queue.shift()
            // 4. If there are more queued items, they are all sent concurrently since there must be an open session
            this.queue.forEach(([eventLogger, ...args]) => {
              eventLogger(...args)
            })
            this.queue = []
          })
      }
    }
  }

  fptLogEvent(eventType, itemId, itemDetails) {
    this.queueEvent(this.sendEvent, eventType, itemId, itemDetails)
  }

  logPageView(pathname, search = '') {
    this.queueEvent(this.sendPageView, pathname, search)
  }

  logVideoEvent(parameters) {
    this.queueEvent(this.sendVideoEvent, parameters)
  }

  sessionEnded() {
    this.fptLogEvent('sessionEnded')
  }

  sessionResumed() {
    this.fptLogEvent('sessionResumed')
  }

  newLead() {
    // fp-tracking tracks new leads via the first page view
  }

  registered() {
    this.fptLogEvent(flixApiEvents.REGISTERED)
  }

  signedIn() {
    this.fptLogEvent(flixApiEvents.SIGNED_IN)
  }

  signedOut() {
    this.fptLogEvent(flixApiEvents.SIGNED_OUT)
  }

  cardAdded() {
    this.fptLogEvent(flixApiEvents.CARD_ADDED)
  }

  initiatedCheckout(planType, currency, price) {
    this.fptLogEvent(flixApiEvents.CHECKOUT_INITIATED, planType, { currency, price })
  }

  purchasedSubscription(planType, currency, price, trial) {
    this.fptLogEvent(flixApiEvents.SUBSCRIBED, planType, { currency, price, trial })
  }

  purchasedTVOD(tierSku, currency, price) {
    this.fptLogEvent(flixApiEvents.TVOD_PURCHASED, tierSku, { currency, price })
  }

  viewedContentList(list) {
    this.fptLogEvent(flixApiEvents.CONTENT_LIST_VIEWED, list)
  }

  viewedTitleDetails(titleSlug) {
    this.fptLogEvent(flixApiEvents.TITLE_DETAILS_VIEWED, titleSlug)
  }

  setReminder(titleSlug) {
    this.fptLogEvent(flixApiEvents.TITLE_REMINDER_SET, titleSlug)
  }

  addedToWatchlist(titleSlug) {
    this.fptLogEvent(flixApiEvents.TITLE_ADDED_TO_WATCHLIST, titleSlug)
  }

  watchedTrailer(titleSlug) {
    this.fptLogEvent(flixApiEvents.TRAILER_VIEWED, titleSlug)
  }

  watchedFeature(titleSlug) {
    this.fptLogEvent(flixApiEvents.FEATURE_VIEWED, titleSlug)
  }

  sentRecommendation(titleSlug) {
    this.fptLogEvent(flixApiEvents.RECOMMENDATION_SENT, titleSlug)
  }

  ratedTitle(titleSlug, rating) {
    this.fptLogEvent(flixApiEvents.TITLE_RATED, titleSlug, { rating })
  }

  searched(searchString) {}
}

export const flixPremiereTracker = new FlixPremiereTracker()
