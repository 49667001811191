import { useLayoutEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { canUseDOM } from '@flix/common/utils/canUseDOM'

const transparentHeadThreshold = 20

// To render correctly during SSR the routes with a transparent header must
// be preconfigured rather than enabled in the page component itself.
const includeExactPaths = [
  '/about',
  '/now-showing',
  '/recommended',
  '/recommended/film/:filmSlug',
  '/free-trial',
]

const includeNonExactPaths = ['/festival']
const excludeExactPaths = ['/festival']

const headerHiddenExactPaths = ['/']

export const useNavBarTransparency = () => {
  const exactMatch = useRouteMatch({ path: includeExactPaths, exact: true })
  const permittedInexactMatch = useRouteMatch({ path: includeNonExactPaths, exact: false })
  const excluded = useRouteMatch({ path: excludeExactPaths, exact: true })
  const isHidden = useRouteMatch({ path: headerHiddenExactPaths, exact: true })

  const enabled = (exactMatch || permittedInexactMatch) && !excluded

  const [isTransparent, setIsTransparent] = useState(
    enabled && canUseDOM && window.scrollY < transparentHeadThreshold,
  )

  useLayoutEffect(() => {
    if (enabled) {
      const handleScroll = () => {
        const shouldBeTransparent = window.scrollY < transparentHeadThreshold
        if (isTransparent !== shouldBeTransparent) setIsTransparent(shouldBeTransparent)
      }
      if (window.scrollY < transparentHeadThreshold && !isTransparent) {
        setIsTransparent(true)
      }
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    } else {
      if (isTransparent) {
        setIsTransparent(false)
      }
    }
  }, [enabled, isTransparent])

  return {
    isHidden,
    isTransparent,
    transparencyEnabled: enabled,
  }
}
