import { FormSpy } from 'react-final-form'
import { Text, View } from 'react-native'
import styled from 'styled-components'

const ErrorMessage = styled(Text)`
  align-self: center;
  text-align: center;
  color: ${(props) => props.theme.colors.error};
  font-size: ${(props) => props.theme.fontSizes.medium}px;
  margin-bottom: ${(props) => props.theme.spacing.md}px;
`

export const FormError = ({ style }) => (
  <FormSpy subscription={{ submitError: true, submitting: true }}>
    {({ submitting, submitError }) =>
      !submitting &&
      Boolean(submitError) && (
        <View>
          <ErrorMessage style={style}>{submitError}</ErrorMessage>
        </View>
      )
    }
  </FormSpy>
)
