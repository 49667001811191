import { forwardRef } from 'react'
import { Text as RNText } from 'react-native'
import styled from 'styled-components'

const TextBase = forwardRef(({ uppercase, light, center, marginBottom, ...props }, ref) => (
  <RNText {...props} ref={ref} />
))

export const Text = styled(TextBase)`
  font-weight: ${(props) => props.theme.fontWeights.normal};
  color: ${(props) => props.theme.colors[props.color || 'text']};
  font-size: ${(props) => props.theme.fontSizes.medium}px;
  font-family: ${(props) => props.theme.fontFamilies.base};
  text-align: ${(props) => props.textAlign || 'left'};
  margin-bottom: ${(props) => props.theme.spacing[props.marginBottom || props.mb || 'none']}px;
`
