import { defineMessages } from 'react-intl'

export default defineMessages({
  required: {
    id: 'app.form.validation.required',
    defaultMessage: 'Required field',
  },
  fullName: {
    id: 'app.form.validation.fullName',
    defaultMessage: 'Provide a first name and surname',
  },
  email: {
    id: 'app.form.validation.email',
    defaultMessage: 'Invalid email address',
  },
  minLength: {
    id: 'app.form.validation.minLength',
    defaultMessage: 'Must be at least {min} characters',
  },
  maxLength: {
    id: 'app.form.validation.maxLength',
    defaultMessage: 'Must be no more than {max} characters',
  },
  lessEqual: {
    id: 'app.form.validation.lessEqual',
    defaultMessage: 'Must be less than or equal to {max}',
  },
  greaterEqual: {
    id: 'app.form.validation.greaterEqual',
    defaultMessage: 'Must be greater than or equal to {min}',
  },
  integer: {
    id: 'app.form.validation.integer',
    defaultMessage: 'Must be an integer value',
  },
  equalsField: {
    id: 'app.form.validation.equalsField',
    defaultMessage: 'Must match {field}',
  },
  requiredIfSet: {
    id: 'app.form.validation.requiredIfSet',
    defaultMessage: 'Required if {field} is set',
  },
  requiredIfSetTo: {
    id: 'app.form.validation.requiredIfSetTo',
    defaultMessage: 'Required if {field} is set to {fieldValue}',
  },
  invalidCredentials: {
    id: 'app.form.validation.invalidCredentials',
    defaultMessage: 'Invalid username or password',
  },
})
