import { useMemo } from 'react'
import { css, useTheme } from 'styled-components'
import { breakPoints } from './breakPoints'

export const media = Object.entries(breakPoints).reduce((media, [key, width]) => {
  media[key] = (strings, ...interpolations) => css`
    ${({ theme }) => (theme.width >= width ? css(strings, ...interpolations) : '')}
  `
  return media
}, {})

export function useMediaIs(breakPoint) {
  const theme = useTheme()
  return theme.width >= breakPoints[breakPoint]
}

export function useMediaBreakpoints(xxsIncluded = false) {
  const { width } = useTheme()
  return useMemo(
    () => ({
      xs: !xxsIncluded || width >= breakPoints.xs,
      sm: width >= breakPoints.sm,
      md: width >= breakPoints.md,
      lg: width >= breakPoints.lg,
      xlg: width >= breakPoints.xlg,
      xxl: width >= breakPoints.xxl,
    }),
    [width, xxsIncluded],
  )
}

export function useMediaSelect({ xxs, xs, sm, md, lg, xlg, xxl, default: defaults } = {}) {
  const media = useMediaBreakpoints(true)
  if (media.xxl && xxl) {
    return xxl
  }
  if (media.xlg && xlg) {
    return xlg
  }
  if (media.lg && lg) {
    return lg
  }
  if (media.md && md) {
    return md
  }
  if (media.sm && sm) {
    return sm
  }
  if (media.xs && xs) {
    return xs
  }
  return xxs || xs || defaults
}
