import { Platform, TextInput as RNTextInput, View } from 'react-native'
import styled, { css } from 'styled-components'
import { textAlignRTL } from '../../../style/rtlStyles'

const paddingScale = Platform.OS === 'ios' ? 2.5 : 1.5

// Exported solely for reuse with the web card input
export const styledInputStyles = (theme) => ({
  backgroundColor: theme.colors.foreground,
  color: theme.colors.black,
  fontFamily: theme.fontFamilies.base,
  fontSize: `${theme.fontSizes.medium}px`,
  '::placeholder': {
    color: theme.colors.grayLighte,
    fontWeight: theme.fontWeights.normal,
  },
})

const inputBoxStyle = css`
  ${textAlignRTL};
  background-color: ${(props) => props.theme.colors[props.dark ? 'darkInput' : 'foreground']};
  border-width: 1px;
  border-color: ${(props) =>
    props.error
      ? props.theme.colors.error
      : props.theme.colors[props.dark ? 'grayLight' : 'grayLighter']};
  margin-bottom: ${(props) => (props.error ? props.theme.spacing.xs : 0)}px;
  padding: ${(props) => props.theme.spacing.xsmall * paddingScale}px;
`

const inputTextStyle = css`
  color: ${(props) => props.theme.colors[props.dark ? 'foreground' : 'black']};
  font-size: ${(props) => props.theme.fontSizes.medium}px;
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-family: ${(props) => props.theme.fontFamilies.base};
`

// The StyledInput permits use as a view for wrapping custom inputs in a container
// matching standard text inputs, in particular the card input
export const StyledInput = styled(RNTextInput)`
  ${inputBoxStyle};
  ${(props) => (props.as !== View ? inputTextStyle : null)};
`
