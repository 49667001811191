import { createContext, useContext, useState } from 'react'
import { useIntl } from 'react-intl'

const SnackbarContext = createContext({})

const useSnackbarState = () => {
  const intl = useIntl()
  const [snackbar, setState] = useState(null)

  const closeSnackbar = () => setState(null)

  const snackbarMsg = (title, body, options) => {
    if (!options) {
      options = body || {}
      body = ''
    }
    const { bsStyle = 'default', duration = 5000, ...values } = options
    setState({
      open: true,
      title: typeof title === 'string' ? title : intl.formatMessage(title, values),
      body,
      bsStyle,
      duration,
    })
  }

  const snackbarError = (title, body, options) => {
    if (!options) {
      options = body || {}
      body = ''
    }
    snackbarMsg(
      title.message || title, // Works for error.message, allowing for simply onError={snackbarError}
      body,
      {
        ...options,
        bsStyle: 'danger',
      },
    )
  }

  return { snackbar, closeSnackbar, snackbarMsg, snackbarError }
}

export const SnackbarProvider = ({ children }) => {
  const state = useSnackbarState()
  return <SnackbarContext.Provider value={state}>{children}</SnackbarContext.Provider>
}

export const SnackbarConsumer = SnackbarContext.Consumer

export const useSnackbar = () => useContext(SnackbarContext)
