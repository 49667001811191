/* eslint-disable no-unused-expressions */
import { useRef } from 'react'
import { Field, Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import PropTypes from 'prop-types'
import { Button, ButtonRow } from '@flix/common/elements/controls'
import { FormError } from '@flix/common/elements/forms'
import { required, requiredEmail } from '@flix/common/elements/forms/validators'
import { TextInput } from '@flix/common/elements/input'
import messages from '../../components/forms/messages'
import authMsgs from './messages'

export const LoginForm = ({ children, onCancel, onSubmit }) => {
  const pwdInput = useRef(null)
  const pwdField = useRef(null)

  const handleSubmit = (values) =>
    onSubmit(values).catch((error) => {
      pwdInput.current?.onChange('')
      pwdField.current?.focus()
      return { [FORM_ERROR]: error.message }
    })

  return (
    <Form onSubmit={handleSubmit}>
      {({ handleSubmit, submitting }) => (
        <>
          <Field
            name="email"
            type="email"
            placeholder={messages.email}
            validate={requiredEmail}
            render={(fieldProps) => (
              <TextInput
                {...fieldProps}
                marginBottom="sm"
                placeholder={messages.email}
                keyboardType="email-address"
                autoCapitalize="none"
                onSubmitEditing={() => pwdField.current?.focus()}
                blurOnSubmit={false}
                returnKeyType="next"
              />
            )}
          />
          <Field
            name="password"
            type="password"
            validate={required}
            render={(fieldProps) => {
              pwdInput.current = fieldProps.input
              return (
                <TextInput
                  {...fieldProps}
                  secureTextEntry
                  ref={pwdField}
                  marginBottom="md"
                  placeholder={messages.password}
                  textContentType="password"
                  autoCapitalize="none"
                  onSubmitEditing={handleSubmit}
                  returnKeyType="go"
                />
              )
            }}
          />

          <FormError />

          <ButtonRow>
            {children}

            {!submitting && (
              <Button
                onPress={onCancel}
                intlMsg={messages.cancel}
                link
                underline={false}
                mb="none"
              />
            )}
            <Button
              onPress={handleSubmit}
              disabled={submitting}
              intlMsg={submitting ? authMsgs.signingIn : authMsgs.signIn}
              mb="none"
            />
          </ButtonRow>
        </>
      )}
    </Form>
  )
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}
