import { View } from 'react-native'
import styled from 'styled-components'

export const Menu = styled(View).attrs({
  role: 'menu',
})`
  text-align: right;
  user-select: none;
  width: 100%;
`
