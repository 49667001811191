import { Field } from 'react-final-form'
import { useIntl } from 'react-intl'
import { Text } from 'react-native'
import styled from 'styled-components'
import formatError from '../../forms/formatError'
import { errorFromMeta, errorSubscription } from '../utils'

const ErrorMessage = styled(Text)`
  color: ${(props) => props.theme.colors.error};
  font-size: ${(props) => props.theme.fontSizes.small}px;
  margin: 0 0 0 ${(props) => props.theme.spacing.small}px;
`

export const InputError = ({ name, multiLine, style }) => {
  const intl = useIntl()
  return (
    <Field
      name={name}
      subscription={errorSubscription}
      render={({ meta }) => {
        const error = errorFromMeta(meta)
        return !error ? null : (
          <ErrorMessage
            style={style}
            numberOfLines={multiLine ? null : 1}
            ellipsizeMode={multiLine ? null : 'tail'}
          >
            {formatError(intl)(error)}
          </ErrorMessage>
        )
      }}
    />
  )
}

export default InputError
