import cs from 'classnames'
import PropTypes from 'prop-types'
import styles from './Popover.scss'

const Popover = ({ right, padded, style, children }) => (
  <div
    role="presentation"
    className={cs(styles.popover, right && styles.right, padded && styles.padded)}
    style={style}
  >
    {children}
  </div>
)

Popover.propTypes = {
  right: PropTypes.bool,
  padded: PropTypes.bool,
}

export default Popover
