import { Pressable, View } from 'react-native'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useAuthStatus } from '@mwxltd/flix-api-client'
import Image from '@flix/common/elements/images/Image/Image'
import { media, useMediaIs } from '@flix/common/style/media'
import partnersMsgs from '../../pages/static/FilmPartnersPage/messages'
import { useAppState } from '../app/AppState/AppState'
import DesktopNav from './DesktopNav/DesktopNav'
import MobileNav from './MobileNav/MobileNav'
import MobileNavToggle from './MobileNavToggle/MobileNavToggle'
import NavLink from './NavLink/NavLink'
import { useMobileNavOpenState } from './navbarHooks/useMobileNavOpenState'
import { useNavBarTransparency } from './navbarHooks/useNavBarTransparency'

const NavBar = styled(View)`
  position: fixed;
  width: 100%;
  background-color: rgba(13, 13, 13, 1);
  z-index: 1030;

  border: 1px solid ${(props) => props.theme.colors.gray};
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  transition: background-color 0.25s linear, border-bottom-color 0.25s linear;
  ${(props) =>
    props.isTransparent &&
    `
    background-color: rgba(13, 13, 13, 0);
    border-bottom-color: rgba(85, 85, 85, 0);
    `}
`

const Container = styled(View)`
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 52px;

  padding: 0 10px;
  width: 100%;

  ${media.lg`
    padding-left: 44px;
    height: 75px;
  `}
`

const MobileContainer = styled(View)`
  z-index: 1029;
  position: fixed;
  top: 52px;
  left: 0;
`

const InlineSpacer = styled(View)`
  height: 52px;
  ${media.lg`
  height: 75px;
`}
`

const Logo = styled(Image)`
  width: ${(33 / 87) * 128}px;
  height: 33px;
  margin: 0 ${(props) => props.theme.spacing.medium}px 0 6px;
  ${media.lg`
    margin-left: 0;
    width: ${(44 / 87) * 128}px;
    height: 44px;
  `};
`

const TopNavBar = () => {
  const history = useHistory()
  const { showFullHeaderFooter } = useAppState()
  const { transparencyEnabled, isTransparent, isHidden } = useNavBarTransparency()
  const { mobileNavOpen, setMobileNav, toggleMobileNav } = useMobileNavOpenState()

  const authStatus = useAuthStatus()
  const { hasSubscription, supportedRegion } = authStatus

  const isLg = useMediaIs('lg')

  return isHidden ? null : (
    <>
      <NavBar isTransparent={isTransparent}>
        <Container>
          <Pressable
            disabled={!supportedRegion}
            href={hasSubscription ? '/now-showing' : '/'}
            onPress={(ev) => {
              ev.preventDefault()
              history.push(hasSubscription ? '/now-showing' : '/')
            }}
          >
            <Logo publicId="web/flix-logo-white-192" alt="Flix Premiere" width={128} height={87} />
          </Pressable>

          {showFullHeaderFooter &&
            (!isLg ? (
              !hasSubscription ? null : supportedRegion ? (
                <MobileNavToggle
                  open={mobileNavOpen}
                  onClick={() => !mobileNavOpen && toggleMobileNav()}
                />
              ) : (
                <NavLink to="/film-partners" labelMsg={partnersMsgs.partnersNav} />
              )
            ) : (
              <DesktopNav authStatus={authStatus} />
            ))}
        </Container>
      </NavBar>

      {!transparencyEnabled && <InlineSpacer />}

      {!isLg && supportedRegion && hasSubscription && (
        <MobileContainer>
          <MobileNav
            open={mobileNavOpen}
            hasSubscription={hasSubscription}
            onRequestClose={() => setMobileNav(false)}
          />
        </MobileContainer>
      )}
    </>
  )
}

export default TopNavBar
