const syncStore = (store) => (key) => {
  let _value = store.get(key)

  return {
    set(value) {
      if (value !== _value) {
        store.set(key, value)
        _value = value
      }
    },

    get() {
      // After initialisation this local value remains up to date
      return _value
    },

    clear() {
      store.remove(key)
      _value = undefined
    },
  }
}

export default syncStore
