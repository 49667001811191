import { production } from './stages/production'
import { staging } from './stages/staging'

let releaseStageVars = (
  typeof window !== 'undefined'
    ? window.location.hostname.includes('staging')
    : process.env.RELEASE_STAGE === 'staging'
)
  ? staging
  : production

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  releaseStageVars = { ...staging, ...process.env }
}

const config = {
  appVersion: process.env.REACT_APP_VERSION,

  flixApi: {
    apiRoot: process.env.SERVER
      ? releaseStageVars.FLIX_API_ROOT_SERVER
      : releaseStageVars.FLIX_API_ROOT,
    authTokenKey: 'flix_auth',
    nftAuthTokenKey: 'flix_nft_auth',
    webSocketDomain: releaseStageVars.WEB_SOCKET_DOMAIN,
    webSocketUnsecured: releaseStageVars.WEB_SOCKET_UNSECURED || false,
    trackingRoot: releaseStageVars.FLIX_TRACKING_ROOT_OVERRIDE,
  },

  assetCDNRoot: releaseStageVars.ASSET_CDN_ROOT || '',
  cookieDomain: releaseStageVars.REACT_APP_COOKIE_DOMAIN,
  deepLinkPrefix: releaseStageVars.DEEPLINK_URI_PREFIX,

  bugsnag: {
    apiKey: 'b13fb0fb5645365cf07c7e24e64d5b35',
    appVersion: process.env.REACT_APP_VERSION,
    releaseStage: releaseStageVars.REACT_APP_RELEASE_STAGE,
  },

  clientInfo: {
    platform: 'web',
  },

  analytics: {
    enabled: releaseStageVars.ANALYTICS_ENABLED,
    fpTracker: { enabled: releaseStageVars.ANALYTICS_FP_TRACKING_ENABLED },
    googleAnalyticsV4: { trackingId: 'G-FS78K64NMC' },
  },

  paymentProvider: 'stripe',

  baseUrl: releaseStageVars.HOST_URL,

  nftMarketplace: {
    enabled: releaseStageVars.REACT_APP_NFT_MARKETPLACE_ENABLED || false,
    serverUrl: releaseStageVars.REACT_APP_MORALIS_SERVER_URL || '',
    appId: releaseStageVars.REACT_APP_MORALIS_APP_ID || '',
    etherscanUrl: releaseStageVars.REACT_APP_ETHERSCAN_URL || '',
  },

  reactPortalAnchorId: 'react-portal-anchor',

  EXTENDED_LANGUAGES_ENABLED: releaseStageVars.EXTENDED_LANGUAGES_ENABLED || false,
}

export default config
