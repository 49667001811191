import { Text } from 'react-native'
import styled from 'styled-components'
import { italicRTL, textDecorationLineRTL, weightBoldRTL } from '../../../style/rtlStyles'

export const U = styled(Text)`
  ${textDecorationLineRTL};
`

export const Strong = styled(Text)`
  ${weightBoldRTL}
`

export const B = Strong

export const Em = styled(Text)`
  ${italicRTL}
`

export const I = Em
