import {
  createCookieStorage,
  createLocalStore,
  createMemoryStore,
  createStore,
} from '../storages/index.js'
import syncStore from '../syncStore'

// Most devices should have localStorage; if not this falls back
// to cookie storage and then memory storage as a catch all
const store = createStore([createLocalStore(), createCookieStorage(), createMemoryStore()])

export default syncStore(store)
