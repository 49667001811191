import { cloudinaryUrl } from '@flix/common/services/cloudinary'
import config from '../config'

const { baseUrl } = config

export function canonicalUrl(location, excludeSearch = true) {
  if (typeof location === 'string') {
    return baseUrl + location
  }
  return baseUrl + location.pathname + (excludeSearch ? '' : location.search)
}

function imageUrl(imageOptions, secure, ext = 'png') {
  const { cloudinaryId, ...options } = imageOptions
  options.fetchFormat = null
  const secureUrl = `${cloudinaryUrl(cloudinaryId, options)}.${ext}`

  return secure ? secureUrl : secureUrl.replace('https', 'http')
}

export function imageOgTags(image) {
  return !image
    ? null
    : [
        <meta
          key="ti"
          name="twitter:image"
          content={imageUrl({
            ...image,
            background: 'white',
            crop: 'lpad',
            height: 263,
            width: 263,
          })}
        />,

        <meta key="i" property="og:image" content={imageUrl(image, false)} />,
        <meta key="s" property="og:image:secure_url" content={imageUrl(image, true)} />,
        <meta key="t" property="og:image:type" content="image/png" />,
        <meta key="h" property="og:image:height" content={image.height} />,
        <meta key="w" property="og:image:width" content={image.width} />,
      ]
}

export function videoOgTags(video) {
  return !video
    ? []
    : [
        <meta key="v" property="og:video" content={video.url} />,
        <meta key="m" property="og:video:type" content={video.mime} />,
        <meta key="h" property="og:video:height" content={video.height} />,
        <meta key="w" property="og:video:width" content={video.width} />,
      ]
}
