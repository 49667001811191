import { Redirect, Route } from 'react-router-dom'
import { useAuthStatus } from '@mwxltd/flix-api-client'

const GuestRoute = ({ component: Component, ...rest }) => {
  const { hasSubscription } = useAuthStatus()
  return (
    <Route
      {...rest}
      render={(props) =>
        hasSubscription ? <Redirect to="now-showing" /> : <Component {...props} />
      }
    />
  )
}

export default GuestRoute
