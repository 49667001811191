import { defineMessages } from 'react-intl'

export default defineMessages({
  name: {
    id: 'app.forms.name',
    defaultMessage: 'Name',
  },
  fullName: {
    id: 'app.forms.fullName',
    defaultMessage: 'Full Name',
  },
  firstName: {
    id: 'app.forms.firstName',
    defaultMessage: 'First Name',
  },
  surname: {
    id: 'app.forms.surname',
    defaultMessage: 'Surname',
  },
  email: {
    id: 'app.forms.email',
    defaultMessage: 'Email',
  },
  emailAddress: {
    id: 'app.forms.emailAddress',
    defaultMessage: 'Email Address',
  },
  password: {
    id: 'app.forms.password',
    defaultMessage: 'Password',
  },
  choosePwd: {
    id: 'app.forms.choosePwd',
    defaultMessage: 'Choose a Password',
  },

  cancel: {
    id: 'app.forms.cancel',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'app.forms.confirm',
    defaultMessage: 'Confirm',
  },
  continue: {
    id: 'app.forms.continue',
    defaultMessage: 'Continue',
  },
  save: {
    id: 'app.forms.save',
    defaultMessage: 'Save',
  },
  submit: {
    id: 'app.forms.submit',
    defaultMessage: 'Submit',
  },

  submitting: {
    id: 'app.forms.submitting',
    defaultMessage: 'Submitting…',
  },
  saving: {
    id: 'app.forms.saving',
    defaultMessage: 'Saving…',
  },

  phoneNo: {
    id: 'app.forms.phoneNo',
    defaultMessage: 'Phone No',
  },
  company: {
    id: 'app.forms.company',
    defaultMessage: 'Company',
  },
  website: {
    id: 'app.forms.website',
    defaultMessage: 'Website',
  },
  country: {
    id: 'app.forms.country',
    defaultMessage: 'Country',
  },
})
