import ApolloClient from '@mwxltd/apollo-client'
import {
  eventEmitter,
  flixApiConfiguration,
  flixApiFetch,
  flixApiV2Fetch,
} from '@mwxltd/flix-api-client'
import { onApiError } from './onApiError'
import { platformHeaders } from './platformHeaders'

export function flixApiClientConfig({
  bugsnag,
  apiRoot,
  webSocketDomain,
  webSocketUnsecured,
  webSocketPollingDisabled,
  headers,
  tokenStore,
  nftTokenStore,
  onLocaleChange,
  initialRegionAndLocale,
  clientInfo,
  appVersion,
  paymentProvider,
  ...moreOptions
}) {
  const events = eventEmitter()

  const onError = onApiError(bugsnag, tokenStore, events)

  const { regionAndLocale, ...apolloClientConfig } = flixApiConfiguration({
    ...moreOptions,
    connectToDevTools: process.env.NODE_ENV === 'development',
    baseUrl: apiRoot,
    initialRegionAndLocale,
    tokenStore,
    nftTokenStore,
    onError,
    headers: {
      ...platformHeaders(clientInfo, appVersion),
      ...headers,
    },
  })

  const apolloClient = new ApolloClient(apolloClientConfig)

  const flixApi = flixApiFetch({
    ...apolloClientConfig.restLinkConfig,
    tokenStore,
    onError,
  })

  const flixApiV2 = flixApiV2Fetch({
    ...apolloClientConfig.restLinkConfig,
    getLocaleHeaders: regionAndLocale.getLocaleHeaders,
    tokenStore,
    nftTokenStore,
    onError,
  })

  return {
    events,
    flixApi,
    flixApiV2,
    apolloClient,
    webSocketDomain,
    webSocketUnsecured,
    webSocketPollingDisabled,
    paymentProvider,
    nftTokenStore,
    authTokenStore: tokenStore,
    onLocaleChange: regionAndLocale.onLocaleChange,
  }
}
