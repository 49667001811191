import { defineMessages } from 'react-intl'

export default defineMessages({
  watchFree: {
    id: 'app.films.watchFree',
    defaultMessage: 'Watch Free',
  },
  watchNow: {
    id: 'app.films.watchNow',
    defaultMessage: 'Watch Now',
  },
  watchLive: {
    id: 'app.films.watchLive',
    defaultMessage: 'Watch Live',
  },
  playMovie: {
    id: 'app.films.playMovie',
    defaultMessage: 'Play Movie',
  },
  playEpisode: {
    id: 'app.films.playEpisode',
    defaultMessage: 'Play Episode',
  },
  playNext: {
    id: 'app.films.playNext',
    defaultMessage: 'Play Next',
  },
  playTrailer: {
    id: 'app.films.playTrailer',
    defaultMessage: 'Play Trailer',
  },
  viewDetails: {
    id: 'app.films.viewDetails',
    defaultMessage: 'View Details',
  },
  recommendMovie: {
    id: 'app.films.recommendMovie',
    defaultMessage: 'Recommend',
  },
  nowShowing: {
    id: 'app.films.nowShowing',
    defaultMessage: 'Now Showing',
  },
  groupWatch: {
    id: 'app.films.groupWatch',
    defaultMessage: 'Screening Party',
  },
  watchList: {
    id: 'app.films.watchList',
    defaultMessage: 'Watch List',
  },
  browseNowShowing: {
    id: 'app.films.browseNowShowing',
    defaultMessage: 'Browse Now Showing',
  },
  durationShort: {
    id: 'app.film.durationShort',
    defaultMessage:
      '{hrs, number} {hrs, plural, one {hr} other {hrs}} {mins, number} {mins, plural, one {min} other {mins}}',
  },
  durationMins: {
    id: 'app.film.durationMins',
    defaultMessage: '{mins, number} {mins, plural, one {min} other {mins}}',
  },
  seasonsCount: {
    id: 'app.film.seasonsCount',
    defaultMessage: '{count, number} Seasons',
  },

  // ChannelDetails
  channelScheduleNoMorePrograms: {
    id: 'app.channel.schedule.noMorePrograms',
    defaultMessage: 'No more scheduled programs',
  },
  channelScheduleLoadNextDay: {
    id: 'app.channel.schedule.loadNextDay',
    defaultMessage: 'Load Next Day',
  },
  channelScheduleScheduleHeader: {
    id: 'app.channel.schedule.scheduleHeader',
    defaultMessage: 'Schedule',
  },
  channelScheduleProgramLive: {
    id: 'app.channel.schedule.program.live',
    defaultMessage: 'LIVE',
  },
  channelScheduleProgramOnNext: {
    id: 'app.channel.schedule.program.onNext',
    defaultMessage: 'ON NEXT',
  },
  channelScheduleToday: {
    id: 'app.channel.schedule.today',
    defaultMessage: 'Today',
  },
  channelScheduleTomorrow: {
    id: 'app.channel.schedule.tomorrow',
    defaultMessage: 'Tomorrow',
  },
  channelScheduleEmpty: {
    id: 'app.channel.schedule.empty',
    defaultMessage: 'There are no programs scheduled for this channel.',
  },

  // Empty MyMovies placeholder
  myMovies: {
    id: 'app.films.myMovies',
    defaultMessage: 'My Movies',
  },
  myMoviesEmpty: {
    id: 'app.films.myMoviesEmpty',
    defaultMessage: 'My Movies is empty',
  },
  myMoviesEmptyInstruction: {
    id: 'app.films.myMoviesEmptyInstruction',
    defaultMessage:
      'Head over to Now Showing and start watching great independent films now, or save them to your watchlist for later.',
  },
  myMoviesEmptyReminders: {
    id: 'app.films.myMoviesEmptyReminders',
    defaultMessage: 'You can save reminders here too!',
  },
  myMoviesEmptyFutureReleases: {
    id: 'app.films.myMoviesEmptyFutureReleases',
    defaultMessage: 'Check out future releases in Premieres and Coming Soon.',
  },
  myMoviesEmptySetReminders: {
    id: 'app.films.myMoviesEmptySetReminders',
    defaultMessage: "Set reminders and we'll let you know when they're available.",
  },

  // Reminder Button
  remindMe: {
    id: 'app.films.remindMe',
    defaultMessage: 'Remind Me',
  },
  reminderSet: {
    id: 'app.films.reminderSet',
    defaultMessage: 'Reminder Set',
  },
  removeReminder: {
    id: 'app.films.removeReminder',
    defaultMessage: 'Cancel',
  },

  // Reminder Alerts
  receiveReminder: {
    id: 'app.films.receiveReminder',
    defaultMessage: 'Receive a Reminder?',
  },
  reminderMessage: {
    id: 'app.films.reminderMessage',
    defaultMessage: "We'll send you an email shortly before {title} is released.",
  },
  cancelReminder: {
    id: 'app.films.cancelReminder',
    defaultMessage: 'Cancel your Reminder?',
  },
  cancelReminderMessage: {
    id: 'app.films.cancelReminderMessage',
    defaultMessage: "You won't receive an email when {title} is due to be released.",
  },
  cancel: {
    id: 'app.films.cancel',
    defaultMessage: 'Cancel',
  },

  // Snackbar messages
  reminderSuccess: {
    id: 'app.films.snackbar.reminderSet',
    defaultMessage:
      "A reminder for {title} has been set. We'll send you an email shortly before the premiere",
  },
  reminderCancelled: {
    id: 'app.films.snackbar.reminderCancelled',
    defaultMessage: 'This reminder has been cancelled',
  },
  viewNowShowing: {
    id: 'app.films.viewNowShowing',
    defaultMessage: 'View Now Showing',
  },
  watchListAddSuccess: {
    id: 'app.films.snackbar.watchListAddSuccess',
    defaultMessage: '{title} has been added to your watch list',
  },
  watchListRemoveSuccess: {
    id: 'app.films.snackbar.watchListRemoveSuccess',
    defaultMessage: 'This title has been removed from your watch list',
  },
})
