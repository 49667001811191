import { defineMessages } from 'react-intl'

export default defineMessages({
  homeTitle: {
    id: 'meta.title.HomePage',
    defaultMessage: 'Flix Premiere',
  },
  home: {
    id: 'meta.description.HomePage',
    defaultMessage:
      "Flix Premiere is the world's first online cinema, combining the movie theater experience with on-demand availability.",
  },
  closeUp: {
    id: 'meta.description.closeUp',
    defaultMessage: "Flix Premiere's online magazine, Close-Up",
  },
  pressRoom: {
    id: 'meta.description.pressRoom',
    defaultMessage: "Flix Premiere's Press Room",
  },

  contentDetails: {
    id: 'meta.title.filmDetails',
    defaultMessage: '{title} {releaseYear} | Flix Premiere',
  },
  seriesDetails: {
    id: 'meta.title.seriesDetails',
    defaultMessage: '{title} | Flix Premiere',
  },
  nowShowingTitle: {
    id: 'meta.title.NowShowing',
    defaultMessage: 'Now Showing - Independent Films Online',
  },
  nowShowing: {
    id: 'meta.description.NowShowing',
    defaultMessage:
      "View our online cinema, a collection of quality new films we've carefully selected for you - only at Flix Premiere.",
  },
  redCarpetTitle: {
    id: 'meta.title.RedCarpet',
    defaultMessage: 'Premieres - Exclusive Independent Films Online',
  },
  redCarpet: {
    id: 'meta.description.RedCarpet',
    defaultMessage:
      'Welcome to the Flix Premiere Red Carpet, featuring premiere films never before seen. Sign-up online or join for free today!',
  },
  comingSoonTitle: {
    id: 'meta.title.ComingSoon',
    defaultMessage: 'Coming Soon - Independent Films Online',
  },
  comingSoon: {
    id: 'meta.description.ComingSoon',
    defaultMessage:
      "Checkout what's coming soon to Flix Premiere's highly anticipated online cinema of independent films. Sign-up online or join for free today!",
  },
  searchTitle: {
    id: 'meta.title.Search',
    defaultMessage: 'Search Flix Premiere',
  },
  search: {
    id: 'meta.description.Search',
    defaultMessage: 'Search for films on Flix Premiere by title, genre or actor.',
  },
  recommendFilmTitle: {
    id: 'meta.title.RecommendFilm',
    defaultMessage: 'RecommendFilm a Movie | Flix Premiere',
  },

  actorTitle: {
    id: 'meta.title.Actor',
    defaultMessage: '{name} | Actor | Filmography | Flix Premiere',
  },
  actorsTitle: {
    id: 'meta.title.Actors',
    defaultMessage: 'Featured Actors & Actresses - Flix Premiere Online Cinema',
  },
  actors: {
    id: 'meta.description.Actors',
    defaultMessage:
      "See the extensive list of renowned actors featured in the ever-growing online cinema at Flix Premiere. Mo'Nique, Sara Canning, Danny Glover, & many more.",
  },

  newsTitle: {
    id: 'meta.title.News',
    defaultMessage: 'Flix Premiere News',
  },
  news: {
    id: 'meta.description.News',
    defaultMessage:
      "Read the latest news about Flix Premiere online today. The world's first online cinema, that can fit in your pocket.",
  },
  aboutTitle: {
    id: 'meta.title.About',
    defaultMessage: 'About Flix Premiere | Watch Independent Films Online',
  },
  about: {
    id: 'meta.description.About',
    defaultMessage:
      "Ever waste time looking for a great movie to watch? Flix Premiere has the latest movies you won't find on any other platform. Sign-up for free today.",
  },
  pricingTitle: {
    id: 'meta.title.Pricing',
    defaultMessage: 'Pricing Plans at Flix Premiere',
  },
  pricing: {
    id: 'meta.description.Pricing',
    defaultMessage:
      'Flix Premiere offers unlimited access to award-winning movies and more, at an inexpensive rate. Start with your first month free today.',
  },

  signUpTitle: {
    id: 'meta.title.SignUp',
    defaultMessage: "World's First Online Cinema - Sign Up at Flix Premiere",
  },
  signUp: {
    id: 'meta.description.SignUp',
    defaultMessage:
      'Sign up for unlimited access to many of our award-winning movies in the Flix Premiere online cinema today. No commitment and cancel anytime.',
  },
  signInTitle: {
    id: 'meta.title.SignIn',
    defaultMessage: 'Sign In | Flix Premiere',
  },
  signIn: {
    id: 'meta.description.SignIn',
    defaultMessage:
      "Sign in to Flix Premiere to access the world's first online cinema, featuring an ever-growing library of independent films.",
  },

  changePlan: {
    id: 'meta.title.changePlan',
    defaultMessage: 'Change Plan | Flix Premiere',
  },
  verifyEmail: {
    id: 'meta.title.verifyEmail',
    defaultMessage: 'Verify Email | Flix Premiere',
  },
  pwdResetReq: {
    id: 'meta.title.pwdResetReq',
    defaultMessage: 'Request Password Reset | Flix Premiere',
  },
  pwdReset: {
    id: 'meta.title.pwdReset',
    defaultMessage: 'Password Reset | Flix Premiere',
  },

  contactTitle: {
    id: 'meta.title.Contact',
    defaultMessage: 'Contact Flix Premiere',
  },
  contact: {
    id: 'meta.description.Contact',
    defaultMessage:
      'In need of assistance? Contact us today by email or phone for any technical, billing, feedback and any other questions on Flix Premiere.',
  },
  filmPartnerTitle: {
    id: 'meta.title.FilmPartner',
    defaultMessage: 'Become A Film Partner of Flix Premiere',
  },
  filmPartner: {
    id: 'meta.description.FilmPartner',
    defaultMessage:
      "Join the next revolution in cinema, with 'theatre-on-demand' delivery, a world's first - exclusively available at Flix Premiere.",
  },
  careersTitle: {
    id: 'meta.title.Careers',
    defaultMessage: 'Careers at Flix Premiere',
  },
  careers: {
    id: 'meta.description.Careers',
    defaultMessage: "Grow you career at Flix Premiere - the world's first online cinema!",
  },

  helpTitle: {
    id: 'meta.title.Help',
    defaultMessage: 'FAQ - Frequently Asked Questions | Flix Premiere',
  },
  help: {
    id: 'meta.description.Help',
    defaultMessage:
      'Find out how to watch movies, what devices are supported, and many more of the frequently asked questions on Flix Premiere.',
  },
  nftHelpTitle: {
    id: 'meta.title.nftHelp',
    defaultMessage: 'NFT marketplace FAQ',
  },
  nftHelp: {
    id: 'meta.description.nftHelp',
    defaultMessage: 'Our quick guide on NFTs and how the Flix Premiere Marketplace works.',
  },
  privacyTitle: {
    id: 'meta.title.Privacy',
    defaultMessage: 'Privacy Policy | Flix Premiere',
  },
  privacy: {
    id: 'meta.description.Privacy',
    defaultMessage:
      'Flix Premiere is committed to protecting the privacy of all our users. Contact us today or read our privacy policy to learn more.',
  },
  termsTitle: {
    id: 'meta.title.Terms',
    defaultMessage: 'Terms of Use | Flix Premiere',
  },
  terms: {
    id: 'meta.description.Terms',
    defaultMessage: 'Flix Premiere terms of use.',
  },
})
