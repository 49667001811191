import { engagementTracker } from '@flix/common/services/engagement-tracking/engagementTracker'
import { flixPremiereTracker } from '@flix/common/services/engagement-tracking/trackers/flixPremiereTracker'
import { googleAnalyticsTracker } from '@flix/common/services/engagement-tracking/trackers/googleAnalyticsTracker'
import config from '../../../config'
import bugsnag from '../../../services/bugsnag'

const { enabled, fpTracker, googleAnalyticsV4 } = config.analytics

export function initializeEngagementTracker(flixApiV2) {
  engagementTracker.config({
    bugsnag,
    trackers: [
      enabled && googleAnalyticsTracker.config(googleAnalyticsV4),
      fpTracker.enabled &&
        flixPremiereTracker.config({ flixApiV2, uriOverride: config.flixApi.trackingRoot }),
      // enabled && facebookPixelTracker,
    ],
  })

  document.addEventListener('visibilitychange', () => {
    document.visibilityState === 'hidden'
      ? engagementTracker.sessionEnded()
      : engagementTracker.sessionResumed()
  })
}
