import styled from 'styled-components'
import { Paragraph } from '@flix/common/elements/typography'

export const InputHint = styled(Paragraph)`
  margin: ${(props) => props.theme.spacing.xxsmall}px 0 0 ${(props) => props.theme.spacing.small}px;
  font-size: ${(props) => props.theme.fontSizes.small}px;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-family: ${(props) => props.theme.fontFamilies.baseBold};
  color: ${(props) => props.theme.colors.grayLighte};
`
