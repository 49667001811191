import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Text } from '@flix/common/elements/typography'
import { media } from '@flix/common/style/media'

const LinkText = styled(Text)`
  margin: 9px 9px;
  padding: 8px 0;
  font-family: ${(props) => props.theme.fontFamilies.headline};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  font-size: ${(props) => props.theme.fontSizes.medium}px;
  color: ${(props) => props.theme.colors.white};
  line-height: 20px;

  ${media.lg`
    border: 1px solid;
    border-bottom-color: transparent;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
  `}
`

const ActiveLinkText = styled(LinkText)`
  border-bottom-color: ${(props) => props.theme.colors.white};
`

const NavLink = ({ labelMsg, to, onClick, children }) => {
  const location = useLocation()

  const festivalPath = to === '/festival/about' ? '/' + to.split('/')[1] : null
  const linnearPaths =
    location.pathname.split('/')[1] === 'live' || location.pathname.split('/')[1] === 'channel'

  const isActive = to
    ? typeof to === 'string'
      ? festivalPath
        ? location.pathname === festivalPath
        : location.pathname === to
      : location.pathname === to.pathname
    : false

  const isLinnearActive = linnearPaths && to === '/live'

  const TextComponent = isLinnearActive || isActive ? ActiveLinkText : LinkText

  return (
    <Link to={to} onClick={onClick}>
      <TextComponent>{children || <FormattedMessage {...labelMsg} />}</TextComponent>
    </Link>
  )
}

export default NavLink
