import { createContext, useContext, useMemo } from 'react'
import { kebabCase } from 'lodash/fp'

export const ButtonHandlerContext = createContext({ handlers: {}, authStatus: {} })

export const useButtonHandlers = () => useContext(ButtonHandlerContext)

/**
 * Takes an object with camel case key handlers and adds the same
 * handlers with kebab case keys. This adds support for kebab case
 * actions specified in API responses while using camel case in code.
 */
export const kebabCaseHandlers = (handlers) =>
  Object.entries(handlers).reduce((handlers, [key, fn]) => {
    handlers[kebabCase(key)] = fn
    return handlers
  }, handlers)

/**
 * Provide a set of button press handlers to override or add to the main handlers
 * when actions on certain pages require special treatment.
 */
export const OverrideButtonHandlers = ({ handlers, children }) => {
  const context = useButtonHandlers()

  const filmListsHandlers = useMemo(
    () => ({
      ...context,
      handlers: {
        ...context.handlers,
        ...kebabCaseHandlers(handlers),
      },
    }),
    [context, handlers],
  )

  return (
    <ButtonHandlerContext.Provider value={filmListsHandlers}>
      {children}
    </ButtonHandlerContext.Provider>
  )
}
