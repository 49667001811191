"use strict";

var _excluded = ["slug", "items"];

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { useQuery } from '@apollo/client';
var USER_FILM_LISTS = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "UserFilmListsQuery"
    },
    "variableDefinitions": [],
    "directives": [],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "userFilmLists"
        },
        "arguments": [],
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "rest"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "type"
            },
            "value": {
              "kind": "StringValue",
              "value": "FilmGroup",
              "block": false
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "endpoint"
            },
            "value": {
              "kind": "StringValue",
              "value": "v2",
              "block": false
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "path"
            },
            "value": {
              "kind": "StringValue",
              "value": "member/film-lists",
              "block": false
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "slug"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "arguments": [],
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "type"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "name"
                },
                "value": {
                  "kind": "StringValue",
                  "value": "FilmGroupList",
                  "block": false
                }
              }]
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "slug"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "title"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "listType"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "arguments": [],
                "directives": []
              }]
            }
          }]
        }
      }]
    }
  }],
  "loc": {
    "start": 0,
    "end": 248,
    "source": {
      "body": "\n  query UserFilmListsQuery {\n    userFilmLists @rest(type: \"FilmGroup\", endpoint: \"v2\", path: \"member/film-lists\") {\n      slug\n      items @type(name: \"FilmGroupList\") {\n        slug\n        title\n        listType\n        items\n      }\n    }\n  }\n",
      "name": "GraphQL request",
      "locationOffset": {
        "line": 1,
        "column": 1
      }
    }
  }
};
export var useUserFilmListsQuery = function useUserFilmListsQuery() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
  return useQuery(USER_FILM_LISTS, options);
};

var patchUserFilmListsQuery = function patchUserFilmListsQuery(client, listSlug, getUserFilmListsPatch) {
  try {
    var _client$readQuery;

    var userFilmLists = (_client$readQuery = client.readQuery({
      query: USER_FILM_LISTS
    })) === null || _client$readQuery === void 0 ? void 0 : _client$readQuery.userFilmLists;
    client.writeQuery({
      query: USER_FILM_LISTS,
      data: {
        userFilmLists: _objectSpread(_objectSpread({}, userFilmLists), {}, {
          items: userFilmLists.items.map(function (_ref) {
            var slug = _ref.slug,
                items = _ref.items,
                rest = _objectWithoutProperties(_ref, _excluded);

            return _objectSpread(_objectSpread({}, rest), {}, {
              slug: slug,
              items: slug === listSlug ? getUserFilmListsPatch(items) : items
            });
          })
        })
      }
    });
  } catch (_unused) {// It throws when the user content are not in the cache, in which case there is nothing to do
  }
};

export var addToUserFilmListsQuery = function addToUserFilmListsQuery(client, listSlug, film) {
  patchUserFilmListsQuery(client, listSlug, function (items) {
    items = items.filter(function (f) {
      return f.slug !== film.slug;
    });
    items.unshift(film);
    return items;
  });
};
export var removeFromUserFilmListsQuery = function removeFromUserFilmListsQuery(client, listSlug, filmSlug) {
  patchUserFilmListsQuery(client, listSlug, function (items) {
    return items.filter(function (f) {
      return f.slug !== filmSlug;
    });
  });
};