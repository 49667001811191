import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { engagementTracker } from '@flix/common/services/engagement-tracking/engagementTracker'

export const LocationChangeTracker = () => {
  const location = useLocation()

  useEffect(() => {
    if (location && location.pathname) {
      engagementTracker.logPageView(location.pathname, location.search)
    }
  }, [location.pathname, location.search])

  return null
}
