import { memo } from 'react'
import { View } from 'react-native'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { MediaWidthOverride } from '@flix/common/style/MediaWidthOverride'
import { breakPoints } from '../../../style/breakPoints'
import { media } from '../../../style/media'

const Container = styled(View)`
  min-height: ${(props) => props.minHeight || '600px'};
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : 'none')};
  align-items: ${(props) => props.alignItems};

  ${media.sm`
    margin: 0 ${(props) => (props.centered ? 'auto' : 0)};
  `}
`

export const MaxWidthView = memo(
  ({
    maxWidth = breakPoints.lg,
    minHeight,
    alignItems = 'stretch',
    overrideMediaWidth,
    centered = true,
    style,
    children,
    ...props
  }) => (
    <Container
      maxWidth={maxWidth}
      minHeight={minHeight}
      alignItems={alignItems}
      centered={centered}
      style={style}
      {...props}
    >
      {!overrideMediaWidth ? (
        children
      ) : (
        <MediaWidthOverride maxWidth={maxWidth}>{children}</MediaWidthOverride>
      )}
    </Container>
  ),
)

MaxWidthView.propTypes = {
  maxWidth: PropTypes.number,
}
