import { FORM_ERROR } from 'final-form'
import camelCase from 'lodash/camelCase'
import mapKeys from 'lodash/mapKeys'
import pick from 'lodash/pick'

export const AUTH_ERROR = 'authError'

const userErrors = [400, 412, 422, 401, 403]
const isUserError = (error) => userErrors.includes(error.statusCode)

const normalizeErrors = (errors) => mapKeys(errors, (_, key) => camelCase(key))

export const onApiError =
  (bugsnag, tokenStore, events) =>
  ({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors && Array.isArray(graphQLErrors)) {
      // We don't expect GraphQL errors - log and return the default error
      const errors = graphQLErrors.map((error) => pick(error, ['message', 'locations', 'path']))
      bugsnag.notify(
        {
          name: 'Flix GraphQL error',
          message: errors[0].message,
        },
        (event) => {
          event.addMetadata('errors', errors)
        },
      )
    }

    if (networkError) {
      if (networkError.statusCode === 401) {
        tokenStore && tokenStore.clear()
        events?.emit(AUTH_ERROR)
      }

      // More details are probably provided in the parsed body, a typical error response is:
      // {success: false, message: 'Descriptive message', errors?: [array of field validation errors]}
      const { result = {} } = networkError
      networkError.message = (result && result.message) || networkError.message

      if (isUserError(networkError)) {
        // Override the error in a way that suits react-final-form (Maybe treat 401/403 differently?)
        networkError.errors =
          result && result.errors
            ? normalizeErrors(result.errors)
            : { [FORM_ERROR]: networkError.message }
        return // No need to log user errors
      }

      bugsnag.notify(
        {
          name: 'Flix API call error',
          message: networkError.message,
        },
        (event) => {
          event.addMetadata('operation', operation)
        },
      )
    }
  }
