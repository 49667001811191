import { flixApiEvents } from '@mwxltd/flix-api-client'
import { engagementTracker as track } from '../engagement-tracking/engagementTracker.js'

export const trackApiEvents = (events, bugsnag, { checkoutEvents = true } = {}) => {
  events.on(flixApiEvents.LEAD, track.newLead) // () => {}
  events.on(flixApiEvents.REGISTERED, track.registered) // () => {}
  events.on(flixApiEvents.SIGNED_IN, track.signedIn) // () => {}
  events.on(flixApiEvents.SIGNED_OUT, track.signedOut) // () => {}
  events.on(flixApiEvents.CARD_ADDED, track.cardAdded) // () => {}
  events.on(flixApiEvents.CONTENT_LIST_VIEWED, track.viewedContentList) // (listSlug) => {}
  events.on(flixApiEvents.TITLE_DETAILS_VIEWED, track.viewedTitleDetails) // (filmSlug) => {}
  events.on(flixApiEvents.TITLE_REMINDER_SET, track.setReminder) // (filmSlug) => {}
  events.on(flixApiEvents.TITLE_ADDED_TO_WATCHLIST, track.addedToWatchlist) // (filmSlug) => {}
  events.on(flixApiEvents.TRAILER_VIEWED, track.watchedTrailer) // (filmSlug) => {}
  events.on(flixApiEvents.FEATURE_VIEWED, track.watchedFeature) // (filmSlug) => {}
  events.on(flixApiEvents.RECOMMENDATION_SENT, track.sentRecommendation) // (filmSlug) => {}
  events.on(flixApiEvents.TVOD_PURCHASED, track.purchasedTVOD) // (sku, currency, price) => {}

  if (checkoutEvents) {
    // Tracking checkout engagement on Query component mount may not be accurate enough
    // and may be tracked in the application code directly instead of via events
    events.on(flixApiEvents.CHECKOUT_INITIATED, track.initiatedCheckout) // (sku, currency, price) => {}
    events.on(flixApiEvents.SUBSCRIBED, track.purchasedSubscription) // (sku, currency, price) => {}
  }

  const setCurrentUser = (authStatus) =>
    bugsnag.setUser(authStatus.id, authStatus.email, authStatus.displayName)

  events.on(flixApiEvents.REGISTERED, setCurrentUser) // (authStatus) => {}
  events.on(flixApiEvents.SIGNED_IN, setCurrentUser) // (authStatus) => {}
  events.on(flixApiEvents.SIGN_IN_RESTORED, setCurrentUser) // (authStatus) => {}
  events.on(flixApiEvents.SIGNED_OUT, bugsnag.setUser) // () => {}
}
