import { View } from 'react-native'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useOnClickOutside } from '@flix/common/hooks/useOnClickOutside/useOnClickOutside'
import AnimateSlideLeft from '../../../components/layout/AnimateSlideLeft/AnimateSlideLeft'

const ListContainer = styled(View)`
  width: 275px;
  height: 100vh;
  padding: 8px;
  background: #000;
  position: absolute;
  left: 0;
`

export const SideDrawer = ({ open, onRequestClose, children, style }) => {
  const ref = useOnClickOutside(onRequestClose)

  return (
    <AnimateSlideLeft>
      {open && (
        <ListContainer ref={ref} style={style}>
          {children}
        </ListContainer>
      )}
    </AnimateSlideLeft>
  )
}

SideDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  user: PropTypes.object,
}
