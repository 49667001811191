import { ScrollView, View } from 'react-native'
import styled from 'styled-components'
import { media } from '../../../style/media'
import { cursorPointer } from '../../../style/webStyles'
import { Icon } from '../../images/Icon'

export const Backdrop = styled(View)`
  padding: ${(props) => props.theme.spacing[props.padding || 'medium']}px;
  background-color: ${(props) => (props.dark ? '#00000088' : '#ffffff55')};
  justify-content: center;
  flex: 1 0 100%;
  width: 100%;
`

export const Background = styled(View)`
  padding: ${(props) => (!props.padded ? 0 : props.theme.spacing.medium)}px;
  background-color: ${(props) => props.theme.colors.grayDarker};
  max-height: 92%;
  max-width: 100%;
  width: ${(props) => props.smWidth || 400}px;
  margin: 0 auto;
  ${media.lg`
    width: ${(props) => props.width || props.smWidth || '450'}px;
  `};
`

export const ModalContent = styled(ScrollView).attrs((props) => ({
  contentContainerStyle: {
    alignItems: props.alignItems || 'flex-start',
    padding: props.theme.spacing[props.padding || 'smedium'],
  },
}))`
  flex: 1 1 auto;
`

export const Content = styled(ScrollView).attrs((props) => ({
  contentContainerStyle: {
    alignItems: 'center',
    ...(props.notPadded
      ? {}
      : {
          padding: props.theme.spacing.smedium,
          paddingTop: props.theme.spacing.large * 1.1,
          paddingBottom: props.theme.spacing.large * 1.1,
        }),
  },
}))`
  flex: 1 1 auto;
`

export const CloseButton = styled(View)`
  position: absolute;
  top: ${(props) => props.theme.spacing.smedium}px;
  right: ${(props) => props.theme.spacing.small}px;
  flex: 0 0 auto;
  background-color: transparent;
  width: ${(props) => Icon.calcSize(props.theme, props.scale)}px;
  height: ${(props) => Icon.calcSize(props.theme, props.scale)}px;
  border-radius: ${(props) => Icon.calcSize(props.theme, props.scale) / 2}px;
  justify-content: center;
  align-items: center;
  ${cursorPointer};
`
